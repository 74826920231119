<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <!--<v-menu open-on-hover offset-x right origin="center center" transition="scale-transition">-->
    <!--<template v-slot:activator="{ props  }">-->
    <!--<v-avatar v-bind:="props " size="35" class="text-left">-->
    <!--<v-img :src="exibirImagem(item, '50')" alt="avatar"></v-img>-->
    <!--</v-avatar>-->
    <!--</template>-->
    <!--<v-card style="width: 300px">-->
    <!--<v-card-title>-->
    <!--<v-img :src="exibirImagem(item, '400')" alt="avatar" class="imgZoom" cover></v-img>-->
    <!--</v-card-title>-->
    <!--</v-card>-->
    <!--</v-menu>-->
    
    <v-menu open-on-hover offset-x right origin="center center" transition="scale-transition" :key="'urls_imagem'+index">
      <template v-slot:activator="{ on }">
        <v-avatar v-on="on" size="25" color="grey lighten-4" class="text-left">
          <!--<img :src="selecionaImagem(props.item.urls_imagem, '50')" alt="avatar">-->
          <v-img :src="exibirImagem(item, '50')" alt="avatar" class="imgZoom" cover></v-img>
        </v-avatar>
      </template>
      <v-card>
        <v-card-title>
          <!--<img :src="selecionaImagem(item.urls_imagem, '400')" alt="avatar" class="imgZoom">-->
          <v-img :src="exibirImagem(item, '400')" alt="avatar" class="imgZoom" cover></v-img>
        </v-card-title>
      </v-card>
    </v-menu>
  
  
  </div>
</template>

<script>
  /* eslint-disable */
  import miscImageCropper from '../helpers/img-cropper'
  
  import empty from '../assets/img/empty.webp'
  
  export default {
    name: "MenuImagem",
    mixins: [miscImageCropper],
    props: ['item'],
    methods: {
      validarCampo(campo) {
        if (campo !== undefined && campo !== null && campo !== '') {
          return true
        } else {
          return false
        }
      },
      exibirImagem(item, tam) {
        var src = empty
        if (this.validarCampo(item.urls_imagem) && item.urls_imagem.length > 0) {
          src = this.selecionaImagem(item.urls_imagem, tam)
        } else {
          if (item.imagens !== undefined && item.imagens.length > 0) {
            src = item.imagens[0].src
          }
        }
        return src
      },
    }
  }
</script>
