/* eslint-disable */

import {Filtro} from '../../../models/filtro/filtro'
import {ContaPagarSCH, ContaReceberSCH, DuplicataSCH,} from '../../../search/DuplicataSCH'
import {pegaDados, processar, processaReport} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'

const url = '/duplicata';
const state = {
  all: [],
  allList: [],
  titulo_aberto: [],
  ultimos_itens_comprados: [],

  filtroAberto: new Filtro(),
  filtroComprado: new Filtro(),
  filtro: new Filtro(),
};

const getters = {
  listaUltimosItens: (state) => {
    return state.ultimos_itens_comprados
  },
  listaTitulosAbertos: (state) => {
    return state.titulo_aberto
  },
  pegaFiltroAberto: (state) => {
    return state.filtroAberto
  },
  pegaFiltroComprado: (state) => {
    return state.filtroComprado
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaDuplicatas: (state) => {
    return state.all
  },
  listaAllDuplicatas: (state) => {
    return state.allList
  },
};
const actions = {

  searchReceber({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['num_ser_mod', 'nome', 'vl_a_pagar'], '%' + search + '%', 'ilike')
  },

  async setUltimosItensComprados({commit}) {
    try {
      const list = (await pegaDados(url + '/get_duplicatas', state.filtroComprado)).data;
      commit('SET_ULTIMOS_ITENS_COMPRADOS', list)
    } catch (error) {
      console.log(error);
    }
  },

  async setTitulosEmAberto({commit}) {
    try {
      const list = (await pegaDados(url + '/get_duplicatas', state.filtroAberto)).data;
      commit('SET_TITULOS_ABERTOS', list)
    } catch (error) {
      console.log(error);
    }
  },
  setFiltroReceber({commit}, {perPage, empresa}) {
    commit('SET_FILTRO', ContaReceberSCH(perPage, empresa))
  },

  setFiltroComprado({commit}, {perPage, empresa}) {
    let filtro = DuplicataSCH(perPage, empresa);
    filtro.addOrder('dt_emissao', 'desc');
    commit('SET_FILTRO_COMPRADO', filtro)
  },

  setFiltroAberto({commit}, {perPage, empresa}) {
    let filtro = DuplicataSCH(perPage, empresa);
    filtro.filters.addFilter('ispago', true, '<>');
    filtro.addOrder('dt_vencimento', 'asc');
    commit('SET_FILTRO_ABERTO', filtro)
  },

  setFiltroPagar({commit}, {perPage, empresa}) {
    commit('SET_FILTRO', ContaPagarSCH(perPage, empresa))
  },

  async setAllDuplicata({commit}) {
    try {
      const list = (await pegaDados(url + '/all', state.filtro)).data;
      commit('SET_ALL', list);
      EventBus.$emit('LISTA_DUPLICATA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_DUPLICATA_PROCESSADA', false)
    }
  },
  async emitirBaixa({commit},{nota, onSucesso, onErro}) {
    try {
      let retorno = await processaReport(url + '/emitir_baixa', {nota_duplicata_id:nota.nota_duplicata_id})
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarBaixa({commit}, {item, acao, path, onSucesso, onErro}) {
    try {
      let res = await processar(url + path, item, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('duplicata/setAllDuplicata');
      EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },

  async processarNota({commit}, nota, acao) {
    try {

      let res = await processar(url, state.nota, acao);

      let tudoCerto = true;

      if (!res.data.success) {
        // aqui quando for dfe e o success for false, significa q deu problema na validação
        tudoCerto = false;
        this.dispatch('geral/setObjMsgError', res.data);
        EventBus.$emit('LISTA_PROCESSADA', false)
      } else {
        this.dispatch('geral/setMsgSuccess', res.data.message);
        EventBus.$emit('LISTA_PROCESSADA', true)
      }
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
};
const mutations = {
  SET_FILTRO_ABERTO(state, valor) {
    state.filtroAberto = valor
  },
  SET_FILTRO_COMPRADO(state, valor) {
    state.filtroComprado = valor
  },
  SET_FILTRO(state, valor) {
    state.filtro = valor
  },
  SET_ALL(state, lista) {
    state.all = lista
  },
  SET_ALL_LIST(state, lista) {
    state.allList = lista
  },
  SET_TITULOS_ABERTOS(state, historico) {
    state.titulo_aberto = historico
  },
  SET_ULTIMOS_ITENS_COMPRADOS(state, ultimos_itens_comprados) {
    state.ultimos_itens_comprados = ultimos_itens_comprados
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
