/* eslint-disable */
import {processaReport} from '../../../../services/GenericAPI'
import {Filtro} from "../../../../models/filtro/filtro";
import {BaseSCH} from "../../../../search/BaseSCH";

const url = '/registro_inventario';

const state = {
  all: [],
  filtro: new Filtro(),
  pdf: ''
};

const getters = {
  pegaPdf: (state) => {
    return state.pdf
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BaseSCH(perPage))
  },
  async gerarRelatorioPdf({commit}, {filtro, onSucesso, onErro}) {
    try {
      const retorno = (await processaReport(url + '/pdf', filtro));
      commit('SET_PDF', retorno);
      onSucesso(retorno)
    } catch (error) {
      console.log('ERRO: ', error);
      onErro()
    }
  },
};

const mutations = {
  SET_PDF(state, pdf) {
    state.pdf = pdf
  },
  SET_FILTRO(state, pdf) {
    state.filtro = pdf
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
