/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
import {EmpresaContador} from "./empresa_contador";
import {EmpresasConfigNfce} from "./empresas_config_nfce";
import {EmpresasConfigNfe} from "./empresas_config_nfe";

export class Empresa {
  constructor() {
    this.empresa_id = uuidV4();
    this.cnpj = '';
    this.razao = '';
    this.nome = '';
    this.ie = '';
    this.logrado = '';
    this.numero = '';
    this.bairro = '';
    this.municipio_id = '';
    this.cep = '';
    this.fone1 = '';
    this.email = '';
    this.im = '';
    this.serie_pedido = '';
    this.aliq_pis = 0;
    this.aliq_cofins = 0;
    this.cst_piscofins_entrada = '';
    this.cst_piscofins_saida = '';
    this.serie_nfe = '';
    this.perc_desc_maximo_aprazo = '';
    this.perc_desc_maximo_avista = '';

    this.dias_validade_orcamento = '';
    this.fone2 = '';
    this.complemento = '';
    this.empresas_contador = new EmpresaContador(this.empresa_id);
    this.empresas_config_nfce = new EmpresasConfigNfce(this.empresa_id);
    this.empresas_config_nfe = new EmpresasConfigNfe(this.empresa_id);
    this.path_importacao_xml_nfe = '';
    this.msg_nfe = '';
    this.msg_pedido = '';
    this.urls_imagem = [];
    this.responsavel = '';
    this.regime_trib = '';
    this.senha_certificado = '';
    this.forma_pagamento_id_dinheiro = '';
    this.forma_pagamento_id_crediario = '';
    this.certificado = null
  }

}
