/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const MovEventoCaixaSCH = (perPage)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['mov_eventos_caixa.descricao', 'eventos_caixa.tp_evento', 'mov_eventos_caixa.dt_evento', 'mov_eventos_caixa.vl_evento', 'funcionarios.nome', 'eventos_caixa.descricao as evento'];
  filtro.join.addLeft('funcionarios','funcionarios.funcionario_id','mov_eventos_caixa.funcionario_id');
  filtro.join.addLeft('eventos_caixa','eventos_caixa.evento_caixa_id','mov_eventos_caixa.evento_caixa_id');
  filtro.addOrder('mov_eventos_caixa.descricao', 'asc');
  return filtro
};
