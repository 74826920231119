/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventoCaixa} from "../../../models/cadastro/evento_caixa";
import {EventoCaixaSCH} from "../../../search/EventoCaixaSCH"
import {Filtro} from "../../../models/filtro/filtro";

const url = '/evento_caixa';

const state = {
  evento_caixa: new EventoCaixa(),
  all: [],
  allList: [],
  filtro: new Filtro()
};

const getters = {
  pegaEventoCaixa: (state) => {
    return state.evento_caixa
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaEventoCaixa: (state) => {
    return state.allList
  },
  listaEventoCaixaSemFiltro: (state) => {
    return state.all
  },
};

const actions = {
  async setAllListEventoCaixa({commit}) {
    const list = (await lista(url + '/all')).data;
    commit('SET_ALL_LISTA', list)
  },
  searchHistoricoPadrao({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao' ],
      '%'+search+'%',
      'ilike'
    )
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EventoCaixaSCH(perPage))
  },

  setEventoCaixa({commit}, evento_caixa) {
    commit('SET_EVENTO_CAIXA', evento_caixa)
  },

  async setAllEventoCaixa ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EVENTOS_CAIXA', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarEventoCaixa ({ commit }, {acao, onSucesso,onErro }) {
    try {
      let res = await processar(url, state.evento_caixa, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_ALL_LISTA (state, eventos_caixa) {
    state.allList = eventos_caixa
  },
  SET_EVENTO_CAIXA (state, eventos_caixa) {
    state.evento_caixa = eventos_caixa
  },
  SET_EVENTOS_CAIXA (state, eventos_caixa) {
    state.all = eventos_caixa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
