/* eslint-disable */
import {autentica, setarToken} from '../../services/GenericAPI'
import {EventBus} from '../../helpers/event-bus'

const url = '/funcionario';

const state = {
  all: []
};

const getters = {
};

const actions = {
  async autenticarUsuario({commit}, {usuario, onSucesso,onErro}) {
    try {
      let res = await autentica(url + '/auth', usuario);

      setarToken(res.data.token, res.data[0].funcionario_id);

      this.dispatch('geral/setMsgSuccess', res.data.message);

      sessionStorage.setItem('token', res.data.token);
      sessionStorage.setItem('token_expiration', res.data.token_expiration * 60 * 1000);
      sessionStorage.setItem('grp_acesso_id', res.data[0].grp_acesso_id);
      sessionStorage.setItem("funcionario_id", res.data[0].funcionario_id);
      sessionStorage.setItem("funcionario_nome", res.data[0].funcionario_nome);
      sessionStorage.setItem("is_admin", res.data[0].is_admin);

      if (res.data[0].original[0] === null) {
        // EventBus.$emit('USUARIO_AUTENTICADO', true, res.data[0], false)
        onSucesso(res.data[0], false)
      } else {
        // EventBus.$emit('USUARIO_AUTENTICADO', true, res.data[0], true)
        onSucesso( res.data[0], true)
      }
      this.dispatch('token/cancelarToken');
      this.dispatch('token/start', null) // Iniciando contador de expiração da sessão
    } catch (error) {
      console.log(error.response);
      if (error.response === undefined) {
        let respostaError = {
          message: "Falha na comunicação com o servidor.",
          success: false,
        };
        this.dispatch('geral/setObjMsgError', respostaError)
      } else {
        this.dispatch('geral/setObjMsgError', error.response.data)
      }
      // EventBus.$emit('USUARIO_AUTENTICADO', false, [])
      onErro(false, [])
    }
  },
};

const mutations = {
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
