/* eslint-disable */
import {VMoney} from 'v-money'
import {mask} from 'vue-the-mask'
import router from "../router";
import misc_uf from "./misc_uf";
import uf from "../assets/js/uf";
import {mapState} from "vuex";
import _ from 'lodash'

export const dadosEmpresa = (empresa, municipios = []) => {

  sessionStorage.setItem('perc_desc_maximo_avista', empresa.perc_desc_maximo_avista);
  sessionStorage.setItem('perc_desc_maximo_aprazo', empresa.perc_desc_maximo_aprazo);
  if (empresa.uf !== undefined && empresa.uf !== null && empresa.uf !== '') {
    sessionStorage.setItem('codigo_empresa_uf', this.buscarUfCodigo(empresa.uf).codigo)
  } else {
    let muni = municipios.find(list => list.municipio_id === empresa.municipio_id);
    sessionStorage.setItem('codigo_empresa_uf', uf.buscarUfCodigo(muni.uf).codigo)
  }
  sessionStorage.setItem('empresa_municipio_id', empresa.municipio_id);
  sessionStorage.setItem('regime_trib', empresa.regime_trib);
  sessionStorage.setItem('empresa', empresa.nome);
  sessionStorage.setItem('serie_pedido', empresa.serie_pedido);
  sessionStorage.setItem('serie_nfe', empresa.serie_nfe);
  sessionStorage.setItem('cnpj_empresa', empresa.cnpj);
  sessionStorage.setItem('forma_pagamento_id_dinheiro', empresa.forma_pagamento_id_dinheiro);
  sessionStorage.setItem('forma_pagamento_id_crediario', empresa.forma_pagamento_id_crediario);
  sessionStorage.setItem('token_csc', empresa.empresas_config_nfce.token_csc)
};

export default {
  mixins: [misc_uf],
  data() {
    return {
      varios_produtos: false,
      copiado: false,
      show_progresso: false,
      dialogPdf: false,
      listaItens: [],
      listaItens2: [],
      value: null,
      dialog: {
        show: false,
        title: '',
        message: ''
      },
      precisao_2: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
        allowBlank: false,
        min: Number.MIN_SAFE_INTEGER,
        max: Number.MAX_SAFE_INTEGER
      },
      formHasErrors: false,
      info_alerta: '',
      cor_alerta: 'primary',
      show_alerta: false,
    }
  },
  directives: {money: VMoney, mask},
  computed: {
    ...mapState('geral', {isDarkTheme: 'isDarkTheme'}),
  },
  methods: {
    dadosSessao(empresa) {
      sessionStorage.setItem('perc_desc_maximo_avista', this.toDecimal(empresa.perc_desc_maximo_avista));
      sessionStorage.setItem('perc_desc_maximo_aprazo', this.toDecimal(empresa.perc_desc_maximo_aprazo));
      sessionStorage.setItem('codigo_empresa_uf', this.buscarUfCodigo(empresa.uf).codigo);
      sessionStorage.setItem('empresa_municipio_id', empresa.municipio_id);
      sessionStorage.setItem('cnpj_empresa', empresa.cnpj);
      sessionStorage.setItem('regime_trib', empresa.regime_trib);
      sessionStorage.setItem('empresa', empresa.nome);
      sessionStorage.setItem('empresa_id', empresa.empresa_id);
      sessionStorage.setItem('serie_pedido', empresa.serie_pedido);
      sessionStorage.setItem('serie_nfe', empresa.serie_nfe);
      sessionStorage.setItem('forma_pagamento_id_dinheiro', empresa.forma_pagamento_id_dinheiro);
      sessionStorage.setItem('forma_pagamento_id_crediario', empresa.forma_pagamento_id_crediario);
      sessionStorage.setItem('token_csc', empresa.token_csc);
      sessionStorage.setItem('serie_nfce', this.retornoValido(empresa.serie_nfce, ''))
    },
    runEmitir(nome,valor){
      this.$emit(nome, valor)
    },

    getCorTroco(cor) {
      if (this.isDarkTheme) {
        return this.validarCampo(cor) ? (this.isDarkTheme ? 'white' : cor) : 'white'
      } else {
        return this.validarCampo(cor) ? cor : 'black'
      }
    },
    intFloatGenerator(tipo) {
      return tipo === 'int' ? Math.floor(Math.random() * 100) : parseFloat(Math.random() * 1000).toFixed(2)
    },
    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    itens(item) {
      this.listaItens = item
    },
    itens2(item) {
      this.listaItens2 = item
    },
    closeDialog(dialog) {
      dialog.close()
    },
    onCancel() {
      this.value = 'Não'
    },
    mobileModal(size) {
      let width = window.screen.width;
      if (width <= 600) {
        size = '90%'
      } else if (width >= 601 && width <= 900) {
        size = '80%'
      } else if (width >= 901 && width <= 1300) {
        size = '70%'
      }
      return size
    },
    retornoValido(campo, retorno) {
      return this.validarCampo(campo) ? campo : retorno
    },
    retorno(item, termo, retorno1, retorno2) {
      return item === termo ? retorno1 : retorno2
    },
    validarCampo(campo) {
      return (campo !== undefined && campo !== null && campo !== '')
      // if (campo !== undefined && campo !== null && campo !== '') {
      //   return true
      // } else {
      //   return false
      // }
    },
    removerMascara(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9]+/g, '') : ''
    },
    removerMasK(str) {
      return this.validarCampo(str) ? str.replace(/[^0-9a-zA-Z]+/g, '') : ''
    },
    removeMascaraMoney(x) {
      if (this.validarCampo(x)) {
        let aux;
        x = "" + x;
        if ((x.replace(",", ".") !== x)) {
          if (x.replace(".", "") !== x) {
            aux = x;
            x = x.replace(".", "")
          } else {
            aux = x
          }
          if (x.replace(",", ".") !== x) {
            x = x.replace(",", ".")
          } else {
            x = aux
          }
        }
        if (isNaN(parseFloat(x))) {
          x = 0
        } else {
          x = parseFloat(x)
        }
        return x
      } else {
        return ''
      }
    },
    removerAcentos(text){
      text = text.toLowerCase();
      text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
      text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
      text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
      text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
      text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
      text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
      return text
    },
    toDecimal(v, tam = 2, valida_nan = false) {
      try {
        if (valida_nan) {
          if (isNaN(parseFloat(v))) {
            return 0
          } else {
            if (this.validarCampo(v)) {
              return parseFloat(parseFloat(v).toFixed(tam))
            } else {
              return 0
            }
          }
        } else {
          let ret = 0;
          if (this.validarCampo(v)) {
            if (!this.validarCampo(tam)) tam = 2;
            ret = parseFloat(parseFloat(v).toFixed(tam))
          }
          return ret
        }
      } catch (e) {
        console.log(e)
      }
    },
    cortarString(str, tam) {
      return this.validarCampo(str) ? (str.slice(0, tam) + (tam > str.length ? '' : '...')) : str
    },
    decimalNo(theform) {
      if (this.validarCampo(theform)) {
        let valor = theform.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        return parseFloat(valor)
      }
    },
    toDecimalDig(v, precisao = 3) {
      try {
        let ret = 0;
        if (this.validarCampo(v)) ret = parseFloat(parseFloat(v).toFixed(precisao));
        return ret
      } catch (e) {
        console.log(e)
      }
    },
    valorParcela(vez, vezes, vl_total) {
      let parcela = this.toDecimal(vl_total / vezes);
      if (vez === vezes) {
        let aux = parcela * (vezes - 1);
        parcela = vl_total - aux
      }
      return parcela
    },

    reverterData(data) {
      let newData = data.split('-');
      return newData.reverse().join('')
    },
    reverterData2(data) {
      if (this.validarCampo(data)) {
        return data
      } else {
        let dt = new Date().toLocaleDateString();
        let newData = dt.split('/');
        return newData.reverse().join('-') // 0000-00-00
      }
    },
    upperCase(text) {
      if (this.validarCampo(text)) {
        let semAcento = text.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        return semAcento.toUpperCase()
      }
    },
    // MUDA COR DA LINHA
    mudarCorLinha(id, cor1, novaCor, ClassName) {
      let tag = document.getElementById(id);
      let classe = document.getElementsByClassName(ClassName);
      for (let i = 0; i < classe.length; i++) {
        classe[i].style.backgroundColor = cor1
      }
      if (tag !== null) {
        tag.style.backgroundColor = novaCor
      }
    },
    maskHora(hora) {
      return this.validarCampo(hora) ? (hora.replace(/(\d{2})(\d{2})/gim, '\$1\:\$2')) : '-- : --'
    },
    maskHora2(hora) {
      return this.validarCampo(hora) ? (hora.replace(/(\d{2})(\d{2})/gim, '\$1\:\$2')) : ''
    },
    maskData2(data) {
      return this.validarCampo(data) ? this.maskData(this.reverterData(data)) : ''
    },
    maskData(data) {
      return data.replace(/(\d{2})(\d{2})(\d{4})/g, '\$1/\$2/\$3')
    },
    maskCep(cpf) {
      return cpf.replace(/(\d{2})(\d{3})(\d{3})/g, '\$1.\$2\-\$3')
    },
    maskTel(cpf) {
      if (this.validarCampo(cpf)) {
        return cpf.replace(/(\d{2})(\d{4,5})(\d{4})/g, '(\$1)\$2\-\$3')
      }
    },
    maskCpfCnpj(srt) {
      if (this.validarCampo(srt)) {
        if (srt.length <= 11) {
          return srt.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4')
        } else {
          return srt.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')
        }
      }
    },
    focusCurrencyInput(id) {
      let loadTime = 10;
      window.onload = function () {
        // noinspection JSDeprecatedSymbols
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      };
      let interval = setInterval(function () {
        let muniImpt = document.getElementById(id).getElementsByTagName('input').item(0);
        if (muniImpt !== null) {
          muniImpt.focus()
        }
        clearInterval(interval)
      }, loadTime)
    },
    focusInput(id) {
      let loadTime = 10;
      window.onload = function () {
        loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
      };
      let interval = setInterval(function () {
        let muniImpt = document.getElementById(id);
        if (muniImpt !== null) {
          muniImpt.focus()
        }
        clearInterval(interval)
      }, loadTime)
    },
    getValueByKey(item, key) {
      return item[key] === undefined ? item : item[key]
    },
// Configuração  de cores
    nova_configuracao(tema) {
      let item = document.getElementById("sidebar");
      let toobarDash = document.getElementById("toobarDash");
      let system_bar = document.getElementById("system_bar");
      let tinycolor = require("tinycolor2");
      if (this.validarCampo(toobarDash)) {
        // let c = tinycolor(this.setValues('cor', '#000000', tema));
        // let d =tinycolor(c).brighten(-20).toString();
        // tema.cor = this.isDarkTheme ? d : tema.cor
        toobarDash.style.backgroundColor = this.setValues('cor', '#000000', tema);
        toobarDash.style.opacity = this.setValues('opacidade', 0.75, tema)
      }
      if (this.validarCampo(system_bar)) {
        system_bar.style.backgroundColor = this.setValues('cor', '#000000', tema);
        system_bar.style.opacity = this.setValues('opacidade', 0.75, tema)
      }
      if (this.validarCampo(item)) {
        item.style.backgroundColor = this.setValues('cor', '#000000', tema);
        item.style.opacity = this.setValues('opacidade', 0.75, tema)
      }
      let color = tinycolor(this.setValues('cor', '#000000', tema));
      let color2 = '';
      let color3 = '';
      if (color.isDark()) {
        color2 = tinycolor(color).lighten(80).toString();
        color3 = tinycolor(color).lighten(90).toString()
      } else {
        color2 = tinycolor(color).darken(80).toString();
        color3 = tinycolor(color).darken(90).toString()
      }
      this.mudarCorListaSidebar(color2, color3)
    },
    percorrerElemento(classe, cor, nivel) {
      let elemento = document.getElementsByClassName(classe);
      if (nivel === 1) {
        for (let i = 0; i < elemento.length; i++) {
          elemento.item(i).style.color = cor
        }
      } else {
        for (let k = 0; k < elemento.length; k++) {
          elemento.item(k).style.color = cor;
          elemento.item(k).childNodes.item(0).style.color = cor
        }
      }
    },
    mudarCorListaSidebar(cor, cor1) {
      this.percorrerElemento("lista_label", cor, 1);
      this.percorrerElemento("lista_sublabel", cor1, 1);
      this.percorrerElemento("v-list-item__title", cor, 1);
      this.percorrerElemento("listHoverIcon", cor, 1);
      this.percorrerElemento("v-list-item__icon v-list-group__header__prepend-icon", cor, 2);
      this.percorrerElemento("v-list-item__icon v-list-group__header__append-icon", cor, 2)
    },
    setValues(key, valor, tema) {
      let retorno = '';
      retorno = this.validarCampo(tema) ? tema[key] : valor;
      return retorno
    },
    zeroEsquerda(value, totalWidth, paddingChar) {
      let length = totalWidth - value.toString().length + 1;
      return Array(length).join(paddingChar || '0') + value
    },
    verConsultavel(editavel, consultavel, adicionavel) {
      consultavel = !consultavel;
      editavel = !editavel;
      adicionavel = !adicionavel;
      let resultado = false;
      if (adicionavel && editavel && consultavel) {
        resultado = true
      } else if (adicionavel && editavel && !consultavel) {
        resultado = true
      } else if (adicionavel && !editavel && consultavel) {
        resultado = false
      } else if (adicionavel && !editavel && !consultavel) {
        resultado = false
      } else if (!adicionavel && editavel && consultavel) {
        resultado = true
      } else if (!adicionavel && editavel && !consultavel) {
        resultado = true
      } else if (!adicionavel && !editavel && consultavel) {
        resultado = false
      } else if (!adicionavel && !editavel && !consultavel) {
        resultado = false
      }
      return resultado
    },
    getStorageBoolean(nome) {
      return JSON.parse(sessionStorage.getItem(nome))
    },

    inverterData(newData) {
      newData = this.maskData(newData);
      let xvals = newData.split('/');
      let dia = ("00" + parseInt(xvals[0])).slice(-2);
      let mes = ("00" + parseInt(xvals[1])).slice(-2);
      let ano = parseInt(xvals[2]);
      return ano + '-' + mes + '-' + dia
    },
    inverterData2(newData) {
      let xvals = '';
      if (this.validarCampo(newData)) {
        xvals = newData.split('-');
        return xvals[2] + '/' + xvals[1] + '/' + xvals[0]
      } else {
        return xvals
      }
    },
    toDate(data) {
      if (this.validarCampo(data)) {
        let d = new Date(data);
        return this.inverterData(d.toLocaleDateString())
      } else {
        return ''
      }
    },
    getDataAtual() {
      let d = this.zeroEsquerda(new Date().getDate(), 2);
      let m = this.zeroEsquerda(new Date().getMonth() + 1, 2);
      let a = new Date().getFullYear();
      return a + '-' + m + '-' + d
    },

    getHora() {
      let h = this.zeroEsquerda(new Date().getHours(), 2);
      let m = this.zeroEsquerda(new Date().getMinutes(), 2);
      return h + ':' + m
    },
    gerarData(tipo = true, dia, mes, ano) {
      let data = new Date();
      let d = this.validarCampo(dia) ? dia : data.getDate();
      let m = this.validarCampo(mes) ? mes : (data.getMonth() + 1);
      let a = this.validarCampo(ano) ? ano : data.getFullYear();

      if (tipo) {
        return a + '-' + this.zeroEsquerda(m, 2) + '-' + this.zeroEsquerda(d, 2)
      } else {
        return a + '-' + m + '-' + d
      }
    },
    getUltimoDia(data) {
      let d = new Date(data);
      let ultimoDia = new Date(d.getFullYear(), (d.getMonth() + 1), 0);
      return this.setDataAtual(ultimoDia)
    },
    extrairData2(data,index=0) {
      let dt = ''
      if (this.validarCampo(data)) {
        let d = data.split(' ');
        dt = d[index]
      }
      return dt
    },
    geTHoraBayData(dt){
      var h = this.extrairData2(dt,1)
      h = this.validarCampo(h) ? h : '00:00:00'
      return h
    },
    setDataAtual(data) {
      let d = this.zeroEsquerda(data.getDate(), 2);
      let m = this.zeroEsquerda(data.getMonth() + 1, 2);
      let a = data.getFullYear();
      return a + '-' + m + '-' + d
    },
    dataCrediario(vezes) {
      let dias = 30 * vezes;
      let date = new Date(this.getDataAtual());
      date.setDate(date.getDate() + dias);
      return this.setDataAtual(date)
    },
    compararData(dt_inicial, dt_final) {
      let g1 = new Date(dt_inicial);
      let g2 = new Date(dt_final);
      return g1.getTime() > g2.getTime()
    },
    /**
     * @return {string}
     */
    RetornaHoraAtual() {
      let dNow = new Date();
      let h = this.zeroEsquerda(dNow.getHours(), 2);
      let m = this.zeroEsquerda(dNow.getMinutes(), 2);
      let s = this.zeroEsquerda(dNow.getSeconds(), 2);
      return h + ':' + m + ':' + s;
    },

    setIframeModal(path, item) {
      let iframe = document.createElement('iframe');
      iframe.src = path;
      iframe.id = 'frame_edit';
      iframe.frameBorder = '0';
      iframe.style.width = '100%';
      iframe.style.minHeight = '500px';
      sessionStorage.setItem('participante', JSON.stringify(item));
      let inter = setInterval(function () {
        let edit_partic = document.getElementById('edit_partic');
        edit_partic.appendChild(iframe);
        clearInterval(inter)
      }, 100)
    },
    get_pdf(response) {
      let file = new Blob([response.data], {type: 'application/pdf'});
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank')
    },
    executaCancelBtn(path) {
      router.push({path: path}).catch()
    },
    mascaraValor(valor, decimais, comPrefixo) {
      const numDecimais = (decimais !== null && decimais !== undefined) ? decimais : 2;
      if (valor !== undefined && valor !== null) {
        if (comPrefixo !== null && comPrefixo !== undefined && comPrefixo) {
          return Number(valor).toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: numDecimais
          })
        } else {
          return Number(valor).toLocaleString('pt-br', {minimumFractionDigits: numDecimais})
        }
      }
      return ''
    },
    maiorQueZero(campo) {
      if (campo === '0' || parseInt(campo) === 0) {
        return 1
      } else {
        return campo <= -1 ? campo * -1 : campo
      }
    },
    getValorTotalItem(item) {
      let vl_item = this.toDecimalDig(item.quantidade, 3) * this.toDecimal(item.vl_item);
      let imposto = this.toDecimal(item.vl_st) + this.toDecimal(item.vl_ipi);
      return this.toDecimal(((vl_item + imposto) - item.vl_desconto))
    },
    getValorTotalItem2(item) {
      let vl_item = item.quantidade * item.vl_item;
      let imposto = item.vl_st + item.vl_ipi;
      return ((vl_item + imposto) - item.vl_desconto)
    },
    // ALERTA
    open_alerta(text, cor, tempo = 6) {
      this.info_alerta = text;
      this.cor_alerta = cor;
      this.show_alerta = true;
      let _this = this;
      let intervalo = setInterval(function () {
        _this.show_alerta = false;
        clearInterval(intervalo)
      }, tempo * 1000)
    },
    fechar_alerta() {
      this.show_alerta = false
    },

    getByKey(item, key, campo) {
      return this.retornoValido(item[key][campo], 0)
    },

    getChip(item, key, retorno1, retorno2) {
      let array = {
        label: '',
        cor: ''
      };
      if (item === key) {
        array.label = retorno1;
        array.cor = 'green'
      } else {
        array.label = retorno2;
        array.cor = 'red'
      }
      return array
    },
    arredondarParaBaixo(d, casas = 2) {
      let aux = Math.pow(10, casas);
      return Math.floor(d * aux) / aux
    },

  },
}
