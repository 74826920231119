<template>
  <div class="app-login">
    <!--<v-card flat>-->
      <v-toolbar class="app-login topback" dark extended flat></v-toolbar>
      <v-row pb-2 align="center" justify="center">
        <v-col cols="12" sm="8" offset-sm2 align-self="center">
          <v-card class="card--flex-toolbar" style="background: #EEEEEE !important;">
            <v-card-text>
              <v-layout row wrap>
                <v-col cols="12" sm="12" md="6">
                  <div>
                    <div class="logo">
                      <v-img :src="casasantos" alt="" contain></v-img>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <div class="formLogin">
                    <v-form v-model="valid">
                      <v-container>
                        <v-layout row wrap justify-center>
                          <h1 class="login">Entrar</h1>
                          <v-col cols="12">
                            <v-text-field name="usuario" dense outlined class="inp" v-model="user.username"
                                          label="Usuário" id="usuario" prepend-inner-icon="mdi-account"
                                          v-on:keyup="PressEnter(user.senha, user.username,2)" required></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field name="senha" dense outlined class="inp" v-model="user.senha" label="Senha"
                                          prepend-inner-icon="mdi-lock" type="password" id="senha" required
                                          v-on:keyup="PressEnter(user.senha, user.username,2)" ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <div style="text-align: right;">
                              <v-btn @click="efetuaLogin(user,2)" color="primary" :loading="spinner2">Entrar</v-btn>
                            </div>
                          </v-col>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </div>
                </v-col>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    <!--</v-card>-->

    <pro-modal-cadastro :modal="varias_empresas" width="40%" titulo="Selecione a empresa">
      <pro-container-form form_id="varias_empresas">
        <v-col cols="12">
          <v-combobox id="select11" v-model="empresa" dense :items="listaAllEmpresaFunc" item-text="nome"
                      item-value="empresa_id"
                      label="Empresas" outlined class="padrao"></v-combobox>
        </v-col>
      </pro-container-form>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="fechaModal()" color="gray"> Cancelar</v-btn>
        <v-btn text @click="confirmar()" color="gray"> Confirmar</v-btn>
      </v-card-actions>
    </pro-modal-cadastro>

    <v-dialog v-model="dialogError" max-width="500">
      <v-card v-if="objMsgError !== null">
        <v-card-title class="headline">{{ objMsgError.message }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="objMsgError.details !== null" style="text-align: left">
            <ul :key="index" v-for="(message, index) in objMsgError.details">
              <li> {{ message.mensagem }}</li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="toggleDialogError()"> Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer :fixed="fixed" app elevation="0">
      <v-col py-3 text-xs-center cols="12">
        <span style="font-size: 14px; color: white">Copyrigth &copy; {{ new Date().getFullYear() }}  <a
          href="http://www.prodatanet.com.br/" style="color: orange; text-decoration: none">Prodata Informática</a>. Todos os direitos reservados.</span>
      </v-col>
    </v-footer>

  </div>
</template>

<script async>
  /* eslint-disable */
  import {EventBus} from '../../helpers/event-bus'
  import miscMixin from "../../helpers/misc";
  import casasantos from "../../assets/img/logo/casa_santos.svg";
  import miscLogin from "./misc_login";
  import {mapActions, mapGetters, mapState} from "vuex";
  import router from '../../router'
  import {Tema} from "../../models/tema";

  export default {
    mixins: [miscMixin, miscLogin],
    data: () => ({
      casasantos,
      empresa: [],

    }),

    methods: {
      ...mapActions('empresa', ['setEmpresaByFuncionario', 'getEmpresa']),
      ...mapActions('usuario', ['autenticarUsuario']),
      ...mapActions('geral', ['setModal', 'toggleDialogError', 'toggleSnackSuccess']),
      ...mapActions('configuracao', ['setTema']),
      fechaModal() {
        this.varias_empresas = !this.varias_empresas
      },

      confirmar() {
        if (sessionStorage.getItem('empresa_id')) {
          let interv = setInterval(function () {
            router.push({name: 'Home'}).catch();
            clearInterval(interv)
          }, 1000)
        }
      }
    },
    computed: {
      ...mapGetters('empresa', ['listaAllEmpresaFunc']),
      ...mapState('geral', {
        dialogError: 'dialogError',
        objMsgError: 'objMsgError',
        snackSuccess: 'snackSuccess',
        msgSuccess: 'msgSuccess'
      }),
    },
    mounted() {
    },
    watch: {
      empresa: function () {
        this.dadosSessao(this.empresa)
      }
    }
  }
</script>
<style scoped>
  .topback {
    background: rgba(0, 0, 0, 0.7) !important;
    height: 300px !important;
  }

  .card--flex-toolbar {
    margin-top: -100px;
  }

  .formLogin {
    width: 90%;
    margin: 0 auto;
    padding: 2%;
  }

  .logo {
    text-align: center;
    padding-top: 0%;
  }

  .logo img {
    width: 70%;
  }

  @media screen and (min-width: 200px) and (max-width: 450px) {
    .card--flex-toolbar {
      margin-top: -200px !important;
    }

    .login {
      font-size: 1.5rem;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 599px) {
    .card--flex-toolbar {
      margin-top: -190px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 959px) {
    .card--flex-toolbar {
      margin-top: -110px !important;
    }
  }

  @media screen and (min-width: 960px) and (max-width: 1600px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1601px) and (max-width: 1800px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -110px !important;
    }

    .logo {
      text-align: center;
      /*padding-top: 5%;*/
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1801px) and (max-width: 1919px) {
    .topback {
      height: 300px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 10%;
    }

    .logo img {
      width: 90%;
    }
  }

  @media screen and (min-width: 1920px) {
    .topback {
      height: 350px
    }

    .card--flex-toolbar {
      margin-top: -150px !important;
    }

    .logo {
      text-align: center;
      padding-top: 15%;
    }

    .logo img {
      width: 90%;
    }
  }


</style>
