/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Configuracao {
  constructor () {
    this.configuracao_id = uuidV4();
    this.dias_bloqueio_por_inatividade = 0;
    this.dias_bloqueio_por_inadimplencia = 0;
    this.dias_bloqueio_titulos_vencidos = 0
  }
}
