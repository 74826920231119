/* eslint-disable */
import {lista, pegaDados, pegaTema, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/configuracao';

const state = {
  all: [],
  tema: null
};

const getters = {
  pegarTema: state => {
    return state.tema
  },
};

const actions = {
  async getTema({commit}, {onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('tema.funcionario_id', sessionStorage.getItem('funcionario'), '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      onSucesso(list.data[0]);
      commit('SET_TEMA', list.data[0])
    } catch (error) {
      console.log(error);
      onErro()
    }

  },
  async setAllTema ({ commit }) {
    const list = await pegaTema('funcionario/get_tema', {funcionario_id: state.tema.funcionario_id});
    commit('SET_TEMA', list.data[0].original[0])
  },
  async setAllTemaByUser ({ commit }, {id}) {
    const list = await pegaTema('funcionario/get_tema', {funcionario_id: id});
    commit('SET_TEMA', list.data[0].original[0]);
    EventBus.$emit('TEMA_CONCLUIDO', true)
  },
  async getImagensAleatorias ({ commit}, {onSucesso}) {
    const list = (await pegaDados(url+'/imagens_aleatorias')).data;
    onSucesso(list[0])
  },
  async setAllConfiguracao ({ commit }) {
    const list = (await pegaDados(url+'/get')).data;
    commit('SET_CONFIGURACOES', list)
  },
  async processarConfiguracao ({ commit }, {tema, acao, onSucesso, onErro}) {
    try {
      let res = await processar('funcionario/tema', tema, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('configuracao/setAllTema');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  setConfiguracao ({ commit }, configuracao) {
    commit('SET_CONFIGURACAO', configuracao)
  },
  setTema({ commit }, valor) {
    sessionStorage.setItem("funcionario_id", valor.funcionario_id);
    commit('SET_TEMA', valor)
  },
  setTemaImagens({ commit }, valor) {
    commit('SET_TEMA_IMAGENS', valor)
  },
  setTemaUrlImagens({ commit }, valor) {
    commit('SET_TEMA_URLS_IMAGENS', valor)
  },
};

const mutations = {
  SET_CONFIGURACOES (state, configuracao) {
    state.all = configuracao
  },
  SET_CONFIGURACAO (state, configuracao) {
    state.configuracao = configuracao
  },
  SET_TEMA (state, payload) {
    state.tema = payload
  },
  SET_TEMA_IMAGENS (state, payload) {
    state.tema.imagens = payload
  },
  SET_TEMA_URLS_IMAGENS (state, payload) {
    state.tema.urls_imagem = payload
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
