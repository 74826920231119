/* eslint-disable */
import {pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {MovEventoAdministrativo} from "../../../models/movimentacao/mov_evento_administrativo";
import {MovEventoAdministrativoSCH} from "../../../search/MovEventoAdministrativoSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/mov_evento_administrativo';

const state = {
  all: [],
  filtro: new Filtro(),
  mov_evento_administrativo: new MovEventoAdministrativo()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaMovEventoAdministrativo: (state) => {
    return state.all
  },
  pegaMovEventoAdministrativo: (state) => {
    return state.mov_evento_administrativo
  },
};

const actions = {
  searchMovEventoAdministrativo({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
      'eventos_administrativo.descricao',
        'mov_eventos_administrativo.dt_evento',
        'mov_eventos_administrativo.vl_evento',
        'eventos_administrativo.descricao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getMovEventoAdministrativo({commit}, {id, onSucesso}) {
    let filtro = getBaseSCH('mov_eventos_adm_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_MOV_EVENTO_ADMINISTRATIVO', list.data[0]);
    onSucesso(list.data[0])
  },

  setMovEventoAdministrativo({commit}, evento) {
    commit('SET_MOV_EVENTO_ADMINISTRATIVO',evento)
  },
  setFiltro2({commit}, perPage) {
      commit('SET_FILTRO', MovEventoAdministrativoSCH(perPage))
  },
  setFiltro({commit}, perPage) {
    if (sessionStorage.getItem('filtro_lanc') === 'vazio' || sessionStorage.getItem('filtro_lanc') === null ) {
      commit('SET_FILTRO', MovEventoAdministrativoSCH(perPage))
    } else {
      let filtro = MovEventoAdministrativoSCH(perPage);
      let aux = JSON.parse(sessionStorage.getItem('filtro_lanc'));
      filtro.filters.filter = aux.filters.filter;
      filtro.filters.search= aux.filters.search;
      commit('SET_FILTRO', filtro)
    }
  },
  async setAllMovEventoAdministrativo ({ commit }) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      commit('SET_MOV_EVENTO_ADMINISTRATIVOS', list);
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async processarMovEventoAdministrativo ({ commit }, {acao, onSucesso,onErro}) {
    try {
      let res = await processar(url, state.mov_evento_administrativo, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('mov_evento_administrativo/setAllMovEventoAdministrativo');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_MOV_EVENTO_ADMINISTRATIVOS (state, mov_evento_administrativo) {
    state.all = mov_evento_administrativo
  },
  SET_MOV_EVENTO_ADMINISTRATIVO (state, mov_evento_administrativo) {
    state.mov_evento_administrativo = mov_evento_administrativo
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
