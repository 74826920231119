/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {dadosEmpresa} from '../../../helpers/misc'
import {Empresa} from "../../../models/cadastro/empresa/empresa";
import {Filtro} from "../../../models/filtro/filtro";
import {EmpresaSCH, getEmpresaSCH} from "../../../search/EmpresaSCH"
import {getBaseSCH} from "../../../search/BaseSCH";
import {getParticipanteFornecedorSCH} from "../../../search/ParticpanteSCH";


const url = '/empresa';

const state = {
  all: [],
  allList: [],
  allListFunc: [],
  empresa: new Empresa(),
  filtro: new Filtro()
};

const getters = {
  listaEmpresaSemFiltro: (state) => {
    return state.all
  },
  listaAllEmpresa: (state) => {
    return state.allList !== null || state.allList !== undefined ? state.allList : []
  },
  listaAllEmpresaFunc: (state) => {
    return state.allListFunc
  },
  pegaEmpresaById: (state) => (id) => {
    let item= state.allList.find(empresa => empresa.empresa_id === id);
    return item!== null ? item: new Empresa()
  },
  pegaEmpresa: (state) =>{
    return state.empresa
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  async setEmpresaByFuncionario ({ commit }, { funcionario, onSucesso }) {
    const list = (await pegaDados(url + '/empresa', {funcionario_id: funcionario})).data;
    commit('SET_ALLEMPRESAS_FUNC', list);
    onSucesso()
  },

  async setEmpresaListaCompleta ({ commit }) {
    const list = (await lista(url + '/lista_completa')).data;
    commit('SET_ALLEMPRESAS', list);
    EventBus.$emit('EMPRESA_PROCESSADA', true)
  },
  searchEmpresa({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['empresas.nome','aux_municipios.nome', 'empresas.fone1'],'%'+search+'%','ilike')
  },
  async getEmpresa2({commit}, id) {
    let filtro = getEmpresaSCH('empresa_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_EMPRESA', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },
  // async getEmpresa({commit}, id) {
  //   let filtro = getBaseSCH('empresa_id', id, '=');
  //   const list = (await pegaDados(url+'/get', filtro)).data;
  //   commit('SET_EMPRESA', list.data[0]);
  //   EventBus.$emit('GET_CONCLUIDO', true)
  // },

  async getEmpresa({commit}, {id,onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('empresa_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_EMPRESA', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  setEmpresa ({ commit }, empresa) {
    commit('SET_EMPRESA', empresa)
  },
  async setAllEmpresas ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_EMPRESAS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      onErro()
      // EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async processarEmpresa ({ commit, rootState }, {acao, onSucesso,onErro}) {
    try {
      let res = await processar(url, state.empresa, acao);
      if (acao === 'gravar') {
        if (state.empresa.empresa_id === sessionStorage.getItem('empresa_id')) {
          dadosEmpresa(state.empresa,rootState.municipio.all)
        }
      }
      this.dispatch('geral/setMsgSuccess', res.data.message);
      // this.dispatch('empresa/setAllEmpresas');
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  setaImagens({commit}, imagens) {
    commit('SETA_IMAGENS', imagens)
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', EmpresaSCH(perPage))
  },

};

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_ALLEMPRESAS_FUNC (state, empresa) {
    state.allListFunc = empresa
  },
  SET_ALLEMPRESAS (state, empresa) {
    state.allList = empresa
  },
  SET_EMPRESAS (state, empresa) {
    state.all = empresa
  },
  SET_EMPRESA (state, empresa) {
    state.empresa = empresa
  },
  // IMAGENS
  SETA_IMAGENS(state, imagens) {
    state.empresa.imagens = imagens
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
