/* eslint-disable */
import {lista, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Municipio} from "../../../models/cadastro/municipio"

const url = 'auxiliares/municipio';

const state = {
  all: []
};

const getters = {
  listaMunicipiosSemFiltro: (state) => {
    return state.all
  },
  pegaMunicipioId: (state) => (id) => {
    let item = state.all.find(municipio => municipio.municipio_id === id);
    return item !== null && item !== undefined ? item.nome : '** NÃO ENCONTRADO'
  },
  pegaMunicipio: (state) => (id) => {
    let item = state.all.find(municipio => municipio.municipio_id === id);
    return item !== null ? item : new Municipio()
  },
};

const actions = {
  async buscaMuncipio({commit}, {id, onSucesso, onErro}) {
    const list = (await state.all.find(municipio => municipio.municipio_id === id));
    try {
      onSucesso(list)
    } catch (e) {
      console.log(e);
      onErro()
    }
  },
  async setAllMunicipios({commit}, {onSucesso}) {
    try {
      const list = (await lista(url)).data;
      commit('SET_MUNICIPIOS', list);
      onSucesso()
    } catch (e) {
      console.log(e)
    }
  },
};

const mutations = {
  SET_MUNICIPIOS(state, municipios) {
    state.all = municipios
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
