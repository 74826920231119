/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const RomaneioSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['dt_romaneio', 'dt_conclusao', 'romaneios.veiculo_id', 'romaneios.funcionario_id', 'funcionarios.nome', 'veiculos.placa','veiculos.descricao','notas_entregas.dt_baixa'];
  filtro.join.addLeft('funcionarios', 'funcionarios.funcionario_id', 'romaneios.funcionario_id');
  filtro.join.addLeft('veiculos', 'veiculos.veiculo_id', 'romaneios.veiculo_id');
  filtro.join.addLeft('romaneio_itens', 'romaneio_itens.romaneio_id', 'romaneios.romaneio_id');
  filtro.join.addLeft('notas_entregas_itens', 'notas_entregas_itens.nota_entrega_item_id', 'romaneio_itens.nota_entrega_item_id');
  filtro.join.addLeft('notas_entregas_registro', 'notas_entregas_registro.nota_entrega_registro_id', 'notas_entregas_itens.nota_entrega_registro_id');
  filtro.join.addLeft('notas_entregas', 'notas_entregas.nota_entrega_id', 'notas_entregas_registro.nota_entrega_id');
  filtro.addOrder('notas_entregas.dt_baixa', 'desc');
  filtro.addOrder('dt_romaneio', 'desc');

  return filtro
};
