/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class OperadoraCartao {
  constructor() {
    this.forma_pagamento_id = uuidV4();
    this.nome = '';
    this.tp_integracao = '';
    this.tp_desdobramento = '';
    this.bandeira = '';
    this.cnpj_credenciadora = '';
    this.forma_pagamento_config_operadora_cartao = []
  }
}
