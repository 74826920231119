/* eslint-disable */
export class Filtro {
  constructor() {
    this.filters = new Filters();
    this.join = new Join();
    this.orders = [];
    this.distinct = '';
    this.fields = [];
    this.reports = new Reports();
    this.groupBy = [];
    this.perPage = 15;
    this.page = 1
  }

  addOrder(orderby, direction) {
    this.orders.push(new Order(orderby, direction))
  }
  addGroupBy(values) {
    this.groupBy.push(new GroupBy(values))
  }

}

export class Reports {
  constructor() {
    this.params = []
  }
  addReportParams(field, value, condition) {
    this.params.push(new Filter(field, value, condition))
  }
}

export class Filters {
  constructor() {
    this.filter = [];
    this.search = []
  }

  addFilter(field, value, condition) {
    this.filter.push(new Filter(field, value, condition))
  }

  addSearch(field = [], value, condition) {
    let _this = this;
    field.forEach(function (f) {
      _this.search.push(new Filter(f, value, condition))
    })
  }


  addFilterRaw(expression, values) {
    this.filterRaw = new FilterRaw(expression, values)
  }


}

export class Filter {
  constructor(field, value, condition) {
    this.field = field;
    this.value = value;
    this.condition = condition
  }
}

export class Join {
  constructor() {
    this.left = []
  }
  addLeft(table, primaryKey, foreignKey){
    this.left.push(new Left(table, primaryKey, foreignKey))
  }
}

export class Left {
  constructor(table, primaryKey, foreignKey) {
    this.table = table;
    this.primaryKey = primaryKey;
    this.foreignKey = foreignKey
  }
}

export class Order {
  constructor(orderby, direction) {
    this.orderby = orderby;
    this.direction = direction
  }
}

export class FilterRaw {
  constructor(expression, values) {
    this.expression = expression;
    this.values = values
  }
}

export class GroupBy {
  constructor(values) {
    this.values = values
  }
}
