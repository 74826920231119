<template>
  <v-card flat :dark="dark">
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script async>
  /* eslint-disable */

  export default {
    name: "ProCard",
    props:{
      dark:{
        default: false
      }
    },

  }
</script>
