/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ParticipantePf {
  constructor (participanteId) {
    this.participante_pf_id = uuidV4();
    this.participante_id = participanteId;
    this.rg = '';
    this.dt_nascimento = null;
    this.nome_mae = '';
    this.local_trabalho = '';
    this.fone_trabalho = ''
  }
}
