/* eslint-disable */
import {pegaDados} from '../../../services/GenericAPI'
import {Filtro} from "../../../models/filtro/filtro";
import {SintegraSCH} from "../../../search/SintegraSCH";

const url = '/sintegra';

const state = {
  allSintegra: [],
  filtro: new Filtro()
};

const getters = {
  listaAll: (state) => {
    return state.allSintegra
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};
const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', SintegraSCH(perPage))
  },
  async setAllSintegraList({commit}, {sintegra, onSucesso,onError}) {
    try {
    const list = (await pegaDados(url + '/get',sintegra)).data;
    commit('SET_AllSintegra', list);
    this.dispatch('geral/setMsgSuccess', list.message);
    onSucesso(list)
    } catch (error) {
      console.log(error);
      onError();
      this.dispatch('geral/setObjMsgError', error)

    }
  }
};

const mutations = {
  SET_AllSintegra(state, allSintegra) {
    state.allSintegra = allSintegra
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
