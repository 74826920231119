/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import '../src/assets/css/main.scss'
import 'font-awesome/css/font-awesome.css'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import VuejsDialog from 'vuejs-dialog'

import ContainerForm from '../src/components/ContainerForm.vue'
import ModalCadastro from '../src/components/ModalCadastro.vue'
import PForm from './components/PForm.vue'
import store from './store/index'
import {sync} from 'vuex-router-sync'
import ProAlerta from './components/Alerta.vue'
import TokenExpirationTimer from './components/TokenExpirationTimer.vue'
import ProCard from './components/ProCard.vue'
import RenovarLogin from './views/Login/RenovarLogin.vue'
import 'cropperjs/dist/cropper.css'
import VueCryptojs from 'vue-cryptojs'

import currency from 'v-currency-field'

import pText from './components/pComponents/pText'
import pLabel from './components/pComponents/pLabel'
import HighchartsVue from 'highcharts-vue'
import MenuImagem from "./components/MenuImagem";

Vue.use(HighchartsVue);
Vue.use(currency);
Vue.use(VueCryptojs);
Vue.component('pro-alerta', ProAlerta);
Vue.component('p-form', PForm);
Vue.component('pro-modal-cadastro', ModalCadastro);
Vue.component('pro-container-form', ContainerForm);
Vue.component('pro-card', ProCard);
Vue.component('p-text', pText);
Vue.component('p-label', pLabel);
Vue.component('renovar-login', RenovarLogin);
Vue.component('token-expiration-timer', TokenExpirationTimer);
Vue.component('menu-imagem', MenuImagem)
Vue.use(Vuetify, {
  theme: {
    secondary: '#0a593b',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
});

Vue.config.productionTip = false;

sync(store, router);

const vuetifyOptions = {
  icons: {
    iconfont: 'mdi'
  }
};

Vue.use(VuejsDialog, {
  html: true,
  loader: true,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  animation: 'fadeIn',
  type: 'alert'
});

new Vue({
  router,
  store,
  render: h => h(App),
  vuetify: new Vuetify(vuetifyOptions)
}).$mount('#app');
