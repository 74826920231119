/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Marca} from "../../../models/cadastro/marca"
import {MarcaSCH} from "../../../search/MarcasSCH"
import {Filtro} from "../../../models/filtro/filtro";


const url = '/marca';

const state = {
  all: [],
  marca: new Marca(),
  filtro: new Filtro()
};

const getters = {
  listaMarcasSemFiltro: (state) => {
    return state.all
  },
  pegaMarca: (state) => (id) => {
    let mar = state.all.find(marca => marca.marca_id === id);
    return mar !== null ? mar : new Marca()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {

  searchMarca({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao'],'%'+search+'%','ilike')
  },

  async setMarcaListaCompleta ({ commit }) {
    const list = (await lista(url + '/lista_completa')).data;
    commit('SET_MARCAS', list);
    EventBus.$emit('MARCA_PROCESSO_CONCLUIDO', true)
  },

  async setAllMarcas ({ commit },{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_MARCAS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarMarca ({ commit }, { marca, acao, onSucesso,onErro }) {
    try {
      let res = await processar(url, marca, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data)

    }
  },
  setFiltro({commit}, {perPage}) {
    commit('SET_FILTRO', MarcaSCH(perPage))
  },
  setMarca ({ commit }, marca) {
    commit('SET_MARCA', marca)
  },
};

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  SET_MARCAS (state, marcas) {
    state.all = marcas
  },
  SET_MARCA (state, marca) {
    state.marca = marca
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
