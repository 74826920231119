/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const  getParticipanteFornecedorSCH = ()=>{
  let filtro = new Filtro();
  filtro.perPage = '*';
  filtro.fields = ['nome','cnpj_cpf'];
  filtro.filters.addFilter('fornecedor','true','=');
  filtro.addOrder('nome', 'asc');
  return filtro
};

export const  getParticipanteTransportadorSCH = ()=>{
  let filtro = new Filtro();
  filtro.perPage = '*';
  filtro.fields = ['nome'];
  filtro.filters.addFilter('transportador','true','=');
  filtro.addOrder('nome', 'asc');
  return filtro
};


export const getParticipanteAvulsoSCH = (cnpj_cpf)=> {
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.fields = [
    'participantes.nome',
    'participantes.cnpj_cpf',
    'participantes.fone1',
    'participantes_enderecos.principal',
    'participantes_enderecos.cep',
    'participantes_enderecos.numero',
    'participantes_enderecos.logrado',
    'participantes_enderecos.complemento',
    'participantes_enderecos.bairro',
    'participantes_enderecos.referencia',
    'participantes_enderecos.municipio_id',
    'aux_municipios.nome as mun_nome'
  ];
  filtro.addOrder('participantes.nome', 'asc');
  filtro.filters.addFilter('participantes_enderecos.principal','true','=');
  filtro.filters.addFilter('participantes.cnpj_cpf',cnpj_cpf,'=');
  filtro.join.addLeft('participantes_enderecos','participantes.participante_id','participantes_enderecos.participante_id');
  filtro.join.addLeft('aux_municipios','aux_municipios.municipio_id','participantes_enderecos.municipio_id');
  return filtro
};
