/* eslint-disable */
import {pegaDados, processar} from "../../services/GenericAPI";
import {getRascunhoSCH} from "../../search/RascunhoSCH";

const url = '/rascunho';
const state = {
  rascunho: ''
};
const getters = {
  pegaRascunho: (state) => {
    return state.rascunho
  },
};
const actions = {
  setRascunho({commit}, rascunho) {
    commit('SET_RASCUNHO', rascunho)
  },

  async getRascunho({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getRascunhoSCH('rotina_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_RASCUNHO', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarRascunho2({commit}, {rascunho,acao}) {
    try {
      let res = await processar(url+  (acao === 'gravar' ? '/commit' : '/delete'), rascunho, acao)
    } catch (error) {
      console.log('processarRascunho Erro:',error)
    }
  }
};
const mutations = {
  SET_RASCUNHO(state, rascunho) {
    state.rascunho = rascunho
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
