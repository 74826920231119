/* eslint-disable */
import {API, setToken} from '../api'
import {acaoApi} from '../helpers/enums'
import {EventBus} from '../helpers/event-bus'

export const lista = (url) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'));
  let res = API.get(url);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const processar = (url, objeto, acao) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'));
  var res;
  if (acao === acaoApi.REMOVER) {
     res = API.delete(url, {data: objeto})
  } else {
     res = API.post(url, objeto)
  }
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const processarPost = async (store, dados, url, onSucesso, onErro, msgSucesso = true) => {
  try {
    let res = await processar(url, dados);

    if (msgSucesso) {
      store.dispatch('geral/setMsgSuccess', res.data.message)
    }
    onSucesso(res.data)
  } catch (error) {
    console.log(error);
    store.dispatch('geral/setObjMsgError', error.response.data);
    onErro()
  }
};

export const pegaLista = (url, data) => {
  let res = API.get(url, {params: {data: data}});
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};
export const pegaDados = (url, data) => {
  let res = API.post(url, data);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};
export const pegaTema = (url, usuario) => {
  let res = API.get(url, {params: usuario});
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const pegaItem = (url, elemento) => {
  let res = API.get(url, {params: elemento});
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const pega = (url, id) => {
  let res = API.get(url + '/' + id);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const autentica = (url, objeto) => {
  let res = API.post(url, objeto);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const auxiliares = (url, objeto) => {
  let res = API.post(url, objeto);
  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

export const processaReport = (url, objeto) => {
  let res = API.post(url, {
    bodyData: objeto
  }, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/pdf'
    },
    responseType: 'blob'
  });

  res.catch(
    (erro) => isTokenExpired(erro.response)
  );
  return res
};

const isTokenExpired = (response) => {
  if(response.status === 401){
    let renovar = (response.data.message === "Token has expired");
    EventBus.$emit('RENOVAR_LOGIN', renovar)
  }
};

export const setarToken = (token, userID) => {
  setToken(token, userID)
};


export const geraUrlRelatorio = async (url, data) => {
  let retorno = await API.post(url, data);
  return process.env.VUE_APP_API_URL + 'viewPdfReport/' + retorno.data
};

export const getCep = (cep) => {
  return API.get('https://viacep.com.br/ws/' + cep + '/json',{
    mode: "no-cors",
    headers: {
      'content-type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
    }
  })
};


export const getCep2 = (cep)=> {
  return new Observable((x)=>{
    let request = new XMLHttpRequest();
    request.open('get', 'https://viacep.com.br/ws/' + cep + '/json');
    request.send();
    request.onload = function () {
      let data = JSON.parse(this.response);
      x.next(data)
    }
  })
};
