/* eslint-disable */
import {pegaDados} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {DFeSCH} from "../../../search/DFeSCH"
import {Filtro} from "../../../models/filtro/filtro";

const url = '/dfe';

const state = {
  all: [],
  filtro: new Filtro()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaDFe: (state) => {
    return state.all
  }
};

const actions = {
  searchDFe({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(
      ['notas.serie', 'notas.numero', 'notas_dfe.chave', 'notas_valores.vl_nota', 'notas_dfe.status', 'notas.dt_emissao', 'notas.nota_id', 'notas_dfe.created_at'],
      '%'+search+'%',
      'ilike'
    );
    state.filtro.addOrder("notas_dfe.created_at", "desc")
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', DFeSCH(perPage))
  },
  async setAllDFe ({ commit }) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;

      commit('SET_DFES', list);
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  }
};

const mutations = {
  SET_DFES (state, dfe) {
    state.all = dfe
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
