/* eslint-disable */
import {FuncionarioGrupoAcesso} from './funcionario_grupo_acesso'
import {v4 as uuidV4} from 'uuid';

export class Funcionario {
  constructor () {
    this.funcionario_id = uuidV4();
    this.nome = '';
    this.fone1 = '';
    this.fone2 = '';
    this.cep = '';
    this.logrado = '';
    this.numero = '';
    this.complemento = '';
    this.bairro = '';
    this.municipio_id = '';
    this.entregador = false;
    this.bloqueado = false;
    this.limite_desconto = 0;
    this.funcionarios_empresas = [];
    this.funcionarios_grupo_acesso = new FuncionarioGrupoAcesso(this.funcionario_id);
    this.funcionarios_comissao = [];
    this.funcionarios_acessibilidade = []

  }
}
