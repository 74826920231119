/* eslint-disable */
import {pegaDados, pegaLista, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Produto} from "../../../models/cadastro/produto/produto"

import {
  getProduto2SCH,
  ProdutoSCH,
  ProdutoVendaNormalSCH,
  ProdutoVendaSCH,
  ProdutoVendaSimplesSCH
} from "../../../search/ProdutoSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {ProdutoEmpresa} from "../../../models/cadastro/produto/produto_empresa";
import {getBaseSCH,BaseSCH} from "../../../search/BaseSCH";

const url = '/produto';

const state = {
  show_prod_empresa: true,
  all: [],
  allProduto: [],
  alllistProduto: [],
  allProdutoParticipantes: [],
  produto_empresa: new ProdutoEmpresa(),
  produto: new Produto(),
  filtro: new Filtro()
};

const getters = {
  pega_show_prod_empresa: (state) => {
    return state.show_prod_empresa
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaProdutosSemFiltro: (state) => {
    return state.all
  },
  listaAllProdutos: (state) => {
    return state.allProduto
  },
  listaFullProdutos: (state) => {
    return state.alllistProduto
  },
  listaAllProdutosParticipantes: (state) => {
    return state.allProdutoParticipantes
  },
  listaProdutos: (state) => {
    return state.allProduto
  },
  pegaProdutoById: (state) => (id) => {
    let busca = state.allProduto.find(lista => lista.produto_id === id);
    return busca !== null && busca !== undefined ? busca : new Produto()
  },
  pegaProduto: (state) => {
    return state.produto !== null && state.produto !== undefined ? state.produto : new Produto()
  },
  pegaProdutoEmpresa: (state) => {
    return state.produto_empresa !== null && state.produto_empresa !== undefined ? state.produto_empresa : new ProdutoEmpresa()
  }
};

const actions = {
  searchProduto({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
      'produtos.codigo',
      'produtos.descricao',
      'marcas.descricao'], '%' + search + '%', 'ilike')
  },
  searchProdutoVenda({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['produtos.codigo', 'produtos.descricao', 'produtos.bloqueado'], '%' + search + '%', 'ilike')
  },
  async searchProdutoVendaSimples({commit}, search) {
    let filtro = getProduto2SCH('produtos.codigo', search, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_PRODUTO', list.data[0]);
    if (list.data[0] !== null && list.data[0] !== undefined) {
      EventBus.$emit('GET_CONCLUIDO2', true)
    } else {
      EventBus.$emit('GET_CONCLUIDO2', false)
    }
  },
  async getProduto({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('produto_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_PRODUTO', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async pegarProduto({commit}, {id,onSucesso}) {
    let filtro = getBaseSCH('produtos.produto_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    onSucesso(list.data[0])
  },

  async pegarProdutoEmpresa({commit}, {id,onSucesso}) {
    let filtro = getBaseSCH('produto_id', id, '=');
    const list = (await pegaDados(url + '/get_by_empresa', filtro)).data;
    onSucesso(list)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', ProdutoSCH(perPage))
  },

  setFiltroVenda({commit}, perPage) {
    commit('SET_FILTRO', ProdutoVendaSCH(perPage))
  },

  setFiltroVendaSimples({commit}, perPage) {
    commit('SET_FILTRO', ProdutoVendaSimplesSCH(perPage))
  },

  setFiltroVendaNormal({commit}, perPage) {
    commit('SET_FILTRO', ProdutoVendaNormalSCH(perPage))
  },

  set_show_prod_empresa({commit}, show) {
    commit('SET_SHOW_PROD', show)
  },

  async setListProdutosAll({commit}) {
    const list = (await pegaDados(url + '/lista_completa', {empresa_id: ''})).data;
    commit('SET_ALL_PRODUTOS', list);
    EventBus.$emit('PRODUTO_PROCESSO_CONCLUIDO', true)
  },

  async setListProdutos({commit}) {
    try {
    const list = (await pegaDados(url + '/lista_completa', {empresa_id: sessionStorage.getItem('empresa_id')})).data;
    commit('SET_ALL_PRODUTOS', list);
    EventBus.$emit('PRODUTO_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      console.log(error);
      EventBus.$emit('PRODUTO_PROCESSO_CONCLUIDO', false)
    }
  },
  async setAllListProdutos({commit}, {empresa_id,onSucesso, onErro}) {
    try {
    const list = (await pegaDados(url + '/lista_completa', {empresa_id: empresa_id})).data;
    commit('SET_FULL_PRODUTOS', list);
      onSucesso()

    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setAllProdutoParticipantes({commit}) {
    try {
      let filtro = BaseSCH('*');
      const list = (await pegaDados(url + '/get_produto_participante', filtro)).data;
      commit('SET_PRODUTOS_PARTICIPANTES', list.data)
    } catch (error) {
      console.log(error)
    }
  },
  async setAllProdutos({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_PRODUTOS', list);
      onSucesso()

    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async processarProduto({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.produto, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data);
      if (sessionStorage.getItem('is_prod_part') === '0') {
        EventBus.$emit('PROCESSO_CONCLUIDO', false, true, 'ProdutosCadastrar')
      }
    }
  },
  async processarProdutoParticipante({commit}, {acao, item, onSucesso, onErro}) {
    try {
      let res = await processar(url + '/produto_participante', item, acao);
      this.dispatch('produto/setAllProdutoParticipantes');
      onSucesso(res.data)
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  setProdutoEmpresa({commit}, produto) {
    commit('SET_PRODUTO_EMPRESA', produto)
  },
  setProduto({commit}, produto) {
    commit('SET_PRODUTO', produto)
  },
  setaImagens({commit}, imagens) {
    commit('SETA_IMAGENS', imagens)
  },

  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'gravar') {
      commit('ADICIONA_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERA_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVE_' + tipo, item)
    }
  },

  removerProdutoEmpresa({commit}, produtoEmpresa) {
    commit('REMOVE_PROD_EMPRESA', produtoEmpresa)
  },
  adicionarProdutoEmpresa({commit}, produtoEmpresa) {
    if (state.produto.produtos_empresas.filter(value => value.empresa_id === produtoEmpresa.empresa_id).length > 0) {
      this.dispatch('geral/setObjMsgError',
        {"success": false, "message": "Falha na inclusão", "details": [{"campo": "descricao", "mensagem": "A empresa que você está tentando incluir já existe na lista."}]}
      )
    } else {
      commit('ADICIONA_PROD_EMPRESA', produtoEmpresa)
    }
  },
};

const mutations = {
  SET_SHOW_PROD(state, show) {
    state.show_prod_empresa = show
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_PRODUTOS(state, produtos) {
    state.all = produtos
  },
  SET_ALL_PRODUTOS(state, produtos) {
    state.allProduto = produtos
  },

  SET_FULL_PRODUTOS(state, produtos) {
    state.alllistProduto = produtos
  },
  SET_PRODUTOS_PARTICIPANTES(state, produtos) {
    state.allProdutoParticipantes = produtos
  },
  SET_PRODUTO(state, produto) {
    state.produto = produto
  },
  SET_PRODUTO_EMPRESA(state, produto_empresa) {
    state.produto_empresa = produto_empresa
  },

  // PRODUTOS_EMPRESA
  // REMOVE_PRODUTOS_EMPRESA(state, empresa) {
  //   let pos = state.produto.produtos_empresas.indexOf(empresa)
  //   state.produto.produtos_empresas.splice(pos, 1)
  // },
  // ALTERA_PRODUTOS_EMPRESA(state, empresa) {
  //   let c = state.produto.produtos_empresas.find(lista => lista.produto_empresa_id === empresa.produto_empresa_id)
  //   let pos = state.produto.produtos_empresas.indexOf(c)
  //   state.produto.produtos_empresas.splice(pos, 1, empresa)
  // },
  // ADICIONA_PRODUTOS_EMPRESA(state, empresa) {
  //   state.produto.produtos_empresas.splice(state.produto.produtos_empresas.length, 1, empresa)
  // },

  // COMPOSICAO
  REMOVE_COMPOSICAO(state, composicao) {
    let pos = state.produto.produtos_composicoes.indexOf(composicao);
    state.produto.produtos_composicoes.splice(pos, 1)
  },
  ALTERA_COMPOSICAO(state, composicao) {
    let c = state.produto.produtos_composicoes.find(lista => lista.produto_composicao_id === composicao.produto_composicao_id);
    let pos = state.produto.produtos_composicoes.indexOf(c);
    state.produto.produtos_composicoes.splice(pos, 1, composicao)
  },
  ADICIONA_COMPOSICAO(state, composicao) {
    state.produto.produtos_composicoes.splice(state.produto.produtos_composicoes.length, 1, composicao)
  },

  // PROMOCAO
  ADICIONA_PROMOCAO(state, promocao) {
    state.produto.produtos_promocoes.splice(state.produto.produtos_promocoes.length, 1, promocao)
  },

  REMOVE_PROMOCAO(state, promocao) {
    let pos = state.produto.produtos_promocoes.indexOf(promocao);
    promocao.produtos_promocoes_condicoes.forEach(function (cond) {
      let pos2 = promocao.produtos_promocoes_condicoes.indexOf(cond);
      promocao.produtos_promocoes_condicoes.splice(pos2, promocao.produtos_promocoes_condicoes.length)
    });

    state.produto.produtos_promocoes.splice(pos, 1)
  },

  ALTERA_PROMOCAO(state, promocao) {
    let pos = state.produto.produtos_promocoes.indexOf(promocao);
    state.produto.produtos_promocoes.splice(pos, 1, promocao)
  },

  // VARIACAO
  ADICIONA_VARIACAO(state, promocao) {
    state.produto.produtos_variacoes.splice(state.produto.produtos_variacoes.length, 1, promocao)
  },

  REMOVE_VARIACAO(state, promocao) {
    let pos = state.produto.produtos_variacoes.indexOf(promocao);
    state.produto.produtos_variacoes.splice(pos, 1)
  },

  ALTERA_VARIACAO(state, promocao) {
    let pos = state.produto.produtos_variacoes.indexOf(promocao);
    state.produto.produtos_variacoes.splice(pos, 1, promocao)
  },

  // CONDICAO
  ADICIONA_PROMOCAO_CONDICAO(state, condicao) {
    let c = state.produto.produtos_promocoes.find(lista => lista.produto_promocao_id === condicao.produto_promocao_id);
    let pos = state.produto.produtos_promocoes.indexOf(c);
    state.produto.produtos_promocoes[pos].produtos_promocoes_condicoes.push(condicao)
  },

  REMOVE_PROMOCAO_CONDICAO(state, condicao) {
    let c = state.produto.produtos_promocoes.find(lista => lista.produto_promocao_id === condicao.produto_promocao_id);
    let pos = state.produto.produtos_promocoes.indexOf(c);
    let d = c.produtos_promocoes_condicoes.find(lista => lista.produto_promocao_condicao_id === condicao.produto_promocao_condicao_id);
    let pos2 = c.produtos_promocoes_condicoes.indexOf(d);
    state.produto.produtos_promocoes[pos].produtos_promocoes_condicoes.splice(pos2, 1)
  },

  ALTERA_PROMOCAO_CONDICAO(state, condicao) {
    let c = state.produto.produtos_promocoes.find(lista => lista.produto_promocao_id === condicao.produto_promocao_id);
    let pos = state.produto.produtos_promocoes.indexOf(c);
    let d = c.produtos_promocoes_condicoes.find(lista => lista.produto_promocao_condicao_id === condicao.produto_promocao_condicao_id);
    let pos2 = c.produtos_promocoes_condicoes.indexOf(d);
    state.produto.produtos_promocoes[pos].produtos_promocoes_condicoes.splice(pos2, 1, condicao)
  },

  // IMAGENS
  SETA_IMAGENS(state, imagens) {
    state.produto.imagens = imagens
  },

  // PRODUTOS_EMPRESA
  REMOVE_PROD_EMPRESA(state, Empresa) {
    let pos = state.produto.produtos_empresas.indexOf(Empresa);
    state.produto.produtos_empresas.splice(pos, 1)
  },
  ALTERA_PROD_EMPRESA(state, Empresa) {
    let array = state.produto.produtos_empresas.filter(lista => lista.produto_empresa_id === Empresa.produto_empresa_id);
    let pos = state.produto.produtos_empresas.indexOf(array[0]);
    state.produto.produtos_empresas.splice(pos, 1, Empresa)
  },
  ADICIONA_PROD_EMPRESA(state, Empresa) {
    state.produto.produtos_empresas.splice(state.produto.produtos_empresas.length, 1, Empresa)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
