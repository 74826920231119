<template>
  <v-app id="inspireInicio">
    <router-view/>
    
    <!--<v-row justify="center">-->
      <!--<v-overlay :z-index="10" :value="overlay" opacity="0.95">-->
        <!--<h3>Esta aplicação esta aberta em outra aba.</h3>-->
        <!--<h3>Feche a outra aba e clique em Continuar para usar a aplicação nesta janela</h3>-->
        <!--<br>-->
        <!--<v-btn class="white&#45;&#45;text" color="gray" @click="sair()" outlined style="margin-right: 10px">-->
          <!--Sair-->
        <!--</v-btn>-->
        <!--<v-btn class="white&#45;&#45;text" color="green" @click="sair()" outlined>-->
          <!--Continuar-->
        <!--</v-btn>-->
      <!--</v-overlay>-->
    <!--</v-row>-->
  
  </v-app>
</template>

<script async>
  /* eslint-disable */
  import {mapActions, mapGetters, mapState} from "vuex";
  
  export default {
    data: () => ({
      // overlay: false,
      zIndex: 0,
    }),
    methods: {
      ...mapActions('home', ['setIsTreinameto']),
      ...mapActions('token', ['cancelarToken']),
      sair() {
        this.overlay = false
        // this.cancelarToken();
        // sessionStorage.clear();
        window.location = '/'
      },
    },
    created() {
      this.setIsTreinameto()
      
    },
    computed: {
      ...mapGetters('home', {treinamento: 'getIsTreinameto'}),
      
    },
    mounted() {
      var $ = require('jquery')
      $(document).on('focus', ':input', function () {
        $(this).attr('autocomplete', 'off');
      });
  
      const urlsAbertas = [];
  
      // console.log(chrome);
      // console.log( chrome.windows);
      // Obter o objeto `chrome.windows` (necessário a extensão Chrome)
      // chrome.windows.getAll({populate: true}, function(windows) {
      //   // Percorrer todas as janelas
      //   for (const window of windows) {
      //     // Percorrer todas as abas na janela
      //     for (const aba of window.tabs) {
      //       // Adicionar a URL da aba ao array
      //       urlsAbertas.push(aba.url);
      //     }
      //   }
      //
      //   // Retornar o array de URLs
      //   return urlsAbertas;
      // });
      
      
      
      
      
      
      
      
  
      // // BLOQUEIA O USO DE DUAS ABAS NO MESMO NAVEGADOR
      // var _this= this
      // const APP_INSTANCE_KEY = 0;
      // function checkExistingInstance() {
      //   const existingInstance = JSON.parse(sessionStorage.getItem(APP_INSTANCE_KEY));
      //   if (existingInstance > 0) {
      //     // Outra aba está aberta
      //     return true;
      //   } else {
      //     // Marca a instância atual
      //     sessionStorage.setItem(APP_INSTANCE_KEY, JSON.parse(sessionStorage.getItem(APP_INSTANCE_KEY)) +1);
      //     return false;
      //   }
      // }
      // // Função para lidar com a mudança de visibilidade da aba
      // function handleVisibilityChange() {
      //   if (document.visibilityState === 'visible') {
      //     if (checkExistingInstance()) {
      //       _this.overlay = true
      //     }
      //   }
      // }
      // document.addEventListener('visibilitychange', handleVisibilityChange);
      //
    }
    
    
  }
</script>

<style lang="scss">
  #inspireInicio {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  
  #nav {
    padding: 30px;
    
    a {
      font-weight: bold;
      color: #2c3e50;
      
      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
