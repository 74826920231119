<template>
  <div>
    <v-snackbar v-model="Mostrar" :timeout="3500" top="top" :color="cor"
    :vertical="vertical">
        <div class="d-flex justify-space-between align-center">
            <!--{{ texto }}-->
          <div v-html="texto"></div>
          <slot></slot>
            <v-btn text  @click="fechar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </div>
    </v-snackbar>
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: 'Alerta',
    props: {
      texto: {
        type: String,
        default: 'Texto'
      },
      cor: {
        type: String,
        default: 'red'
      },
      timeout: {
        type: Number,
        default: 3500
      },
      show: {
        type: Boolean,
        default: false
      },
      vertical: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        snackbar: false
      }
    },
    methods: {
      fechar() {
        this.$emit('abriAlerta', false)
      },
    },
    computed: {
      Mostrar: {
        get() {
          return this.show
        },
        set() {
          return this.Mostrar
        }
      }
    }
  }
</script>
