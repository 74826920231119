/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const  getRascunhoSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.filters.addFilter('funcionario_id', sessionStorage.getItem('funcionario_id'),'=');
  filtro.filters.addFilter('empresa_id', sessionStorage.getItem('empresa_id'),'=');
  filtro.fields = ['*'];
  return filtro
};
