/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EventoCaixa {
  constructor () {
    this.evento_caixa_id = uuidV4();
    this.descricao = '';
    this.plano_conta_id = '';
    this.tp_evento = ''
  }
}
