/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import Dash from './views/Dash.vue'
import Login from './views/Login/Login.vue'

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login
    },
    {
      path: '/home',
      name: 'home',
      component: Dash,
      meta: 'Painel',
      children: [
        // CADASTRO
        {
          path: '/home',
          name: 'Home',
          meta: 'Home',
          component: () => import(/* webpackChunkName: "home" */ './views/Home/Home.vue')
        },
        // CADASTRO
        {
          path: '/produtos',
          name: 'Produtos',
          meta: 'Produtos ',
          component: () => import(/* webpackChunkName: "produtos" */ './views/Cadastros/produto/Produtos.vue')
        },
        {
          path: '/produtos/cadastrar',
          name: 'ProdutosCadastrar',
          meta: 'Produtos ',
          component: () => import(/* webpackChunkName: "cadastrar" */ './views/Cadastros/produto/CadastrarProdutos.vue')
        },
        {
          path: '/grupos',
          name: 'Grupos',
          meta: 'Grupos',
          component: () => import(/* webpackChunkName: "grupos" */ './views/Cadastros/grupos/Grupos.vue')
        },
        {
          path: '/marcas',
          name: 'Marcas',
          meta: 'Marcas',
          component: () => import(/* webpackChunkName: "marcas" */ './views/Cadastros/marcas/Marcas.vue')
        },
        {
          path: '/participantes',
          name: 'Participantes',
          meta: 'Participantes',
          component: () => import(/* webpackChunkName: "participantes" */ './views/Cadastros/participante/Participante.vue')
        },
        {
          path: '/participante/cadastrar',
          name: 'ParticipanteCadastrar',
          meta: 'Participante',
          component: () => import(/* webpackChunkName: "participante_cadastrar" */ './views/Cadastros/participante/CadastrarParticipante.vue')
        },
        {
          path: '/operadora-cartao',
          name: 'FormaPagamento',
          meta: 'Forma de Pagto.',
          component: () => import(/* webpackChunkName: "operadora_cartao" */ './views/Cadastros/forma_pagamento/FormaPagamento.vue')
        },
        {
          path: '/operadora-cartao/cadastrar',
          name: 'FormaPagamentoCadastrar',
          meta: 'Forma de Pagamento',
          component: () => import(/* webpackChunkName: "operadora_cartao_cadastra" */ './views/Cadastros/forma_pagamento/CadastrarFormaPagamento.vue')
        },
        {
          path: '/empresa',
          name: 'Empresa',
          meta: 'Empresas',
          component: () => import(/* webpackChunkName: "empresa" */ './views/Cadastros/empresas/Empresa.vue')
        }, {
          path: '/empresa/cadastrar',
          name: 'EmpresaCadastrar',
          meta: 'Empresa',
          component: () => import(/* webpackChunkName: "empresa_cadastrar" */ './views/Cadastros/empresas/EmpresaCadastro.vue')
        },
        {
          path: '/funcionario',
          name: 'Funcionario',
          meta: 'Funcionarios',
          component: () => import(/* webpackChunkName: "funcionario" */ './views/Cadastros/funcionario/Funcionario.vue')
        },
        {
          path: '/funcionario/cadastrar',
          name: 'FuncionarioCadastrar',
          meta: 'Funcionario',
          component: () => import(/* webpackChunkName: "funcionario_cadastrar" */ './views/Cadastros/funcionario/CadastrarFuncionario.vue')
        },
        {
          path: '/veiculo',
          name: 'Veiculo',
          meta: 'Veículos',
          component: () => import(/* webpackChunkName: "veiculo" */ './views/Cadastros/veiculo/Veiculo.vue')
        },
        {
          path: '/cfops',
          name: 'CFOPs',
          meta: 'CFOPs',
          component: () => import(/* webpackChunkName: "cfops" */ './views/Cadastros/cfop/cfop.vue')
        },
        {
          path: '/cst-csosn',
          name: 'CSTCSOSN',
          meta: 'CST/CSOSN',
          component: () => import(/* webpackChunkName: "cst-csosn" */ './views/Cadastros/cst/cst.vue')
        },
        {
          path: '/evento-caixa',
          name: 'EventoCaixa',
          meta: 'Eventos de Caixa',
          component: () => import(/* webpackChunkName: "evento-caixa" */ './views/Cadastros/eventos_caixa/EventoCaixa.vue')
        },
        {
          path: '/evento-administrativo',
          name: 'EventoAdministativo',
          meta: 'Eventos Administrativos',
          component: () => import(/* webpackChunkName: "evento-caixa" */ './views/Cadastros/eventos_administrativo/EventoAdministrativo.vue')
        },
        {
          path: '/plano-contas',
          name: 'PlanoContas',
          meta: 'Plano de Contas',
          component: () => import(/* webpackChunkName: "plano-contas" */ './views/Cadastros/plano_contas/PlanoContas.vue')
        },
        // CONSULTAS
        {
          path: '/historico-cliente',
          name: 'HistoricoCliente',
          meta: 'Histórico Cliente',
          component: () => import(/* webpackChunkName: "historico-cliente" */ './views/Consultas/historico_cliente/HistoricoCliente.vue')
        },
        {
          path: '/historico-produto',
          name: 'HistoricoProduto',
          meta: 'Histórico Produto',
          component: () => import(/* webpackChunkName: "historico-produto" */ './views/Consultas/historico_produto/HistoricoProduto.vue')
        },
        // MOVIMENTAÇÕES
        {
          path: '/dfe',
          name: 'Dfe',
          meta: 'DFE',
          component: () => import(/* webpackChunkName: "dfe" */ './views/Movimentacao/dfe/DFe.vue')
        },
        {
          path: '/notas',
          name: 'NotaFiscalPropria',
          meta: 'NotaFiscalPropria',
          component: () => import(/* webpackChunkName: "NotaFiscalPropria" */ './views/Movimentacao/nota_fiscal_propria/NotaFiscalPropria.vue')
        },
        {
          path: '/notas/cadastrar',
          name: 'NotaFiscalPropriaCadastrar',
          meta: 'Vendas',
          component: () => import(/* webpackChunkName: "NotaFiscalPropriaCadastrar" */ './views/Movimentacao/nota_fiscal_propria/NotaFiscalPropriaCadastrar.vue')
        },
        {
          path: '/notas-entrada',
          name: 'Notasentrada',
          meta: 'Notas Fiscais',
          component: () => import(/* webpackChunkName: "notas-entrada" */ './views/Movimentacao/notas_entrada/NotasEntrada.vue')
        },
        {
          path: '/notas-entrada/cadastrar',
          name: 'NotasEntradaCadastrar',
          meta: 'Outras Notas Fiscais',
          component: () => import(/* webpackChunkName: "notas_entrada_cadastrar" */ './views/Movimentacao/notas_entrada/NotasEntradaCadastrar.vue')
        },
        {
          path: '/frete',
          name: 'Frete',
          meta: 'Frete',
          component: () => import(/* webpackChunkName: "frete" */ './views/Movimentacao/frete/Frete.vue')
        },
        {
          path: '/frete_cadastrar',
          name: 'FreteCadastrar',
          meta: 'Cadastrar Frete',
          component: () => import(/* webpackChunkName: "frete_cadastrar" */ './views/Movimentacao/frete/FreteCadastrar.vue')
        },
        {
          path: '/lancamento-caixa',
          name: 'LancamentoCaixa',
          meta: 'Lanç. de Caixa',
          component: () => import(/* webpackChunkName: "lancamento-caixa" */ './views/Movimentacao/lancamento_caixa/LancamentoCaixa.vue')
        },
        {
          path: '/lancamento-caixa/cadastrar',
          name: 'LancamentoCaixaCadastrar',
          meta: 'Lanç. de Caixa',
          component: () => import(/* webpackChunkName: "lancamentocaixacadastrar" */ './views/Movimentacao/lancamento_caixa/LancamentoCaixaCadastrar.vue')
        },

        {
          path: '/mov-evento-admin',
          name: 'MovEventoAdministativo',
          meta: 'Mov. Eventos Administrativos',
          component: () => import(/* webpackChunkName: "evento-caixa" */ './views/Movimentacao/mov_administrativo/MovEventoAdmin.vue')
        },
        {
          path: '/mov-evento-admin/cadastrar',
          name: 'MovEventoAdministativoCadastrar',
          meta: 'Mov. Eventos Admin. Cadastrar',
          component: () => import(/* webpackChunkName: "evento-caixa" */ './views/Movimentacao/mov_administrativo/MovEventoAdminCadastrar.vue')
        },
        {
          path: '/requisicao',
          name: 'Requisicao',
          meta: 'Requisicao',
          component: () => import(/* webpackChunkName: "requisicao" */ './views/Movimentacao/requisicao/Requisicao.vue')
        },
        {
          path: '/requisicao/cadastro',
          name: 'RequisicaoCadastro',
          meta: 'RequisicaoCadastro',
          component: () => import(/* webpackChunkName: "requisicao_cadastro" */ './views/Movimentacao/requisicao/RequisicaoCadastro.vue')
        },
        // CONTROLES
        {
          path: '/conta-receber',
          name: 'ContaReceber',
          meta: 'Contas a Receber',
          component: () => import(/* webpackChunkName: "contareceber" */ './views/Controle/conta_receber/ContaReceber.vue')
        },
        {
          path: '/conta-pagar',
          name: 'ContaPagar',
          meta: 'Contas a Pagar',
          component: () => import(/* webpackChunkName: "contapagar" */ './views/Controle/conta_pagar/ContaPagar.vue')
        },
        {
          path: '/controle-entrega',
          name: 'ControleEntrega',
          meta: 'Controle de Entrega - Pedidos',
          component: () => import(/* webpackChunkName: "controle_entrega" */ './views/Controle/controle_entrega/ControleEntrega.vue')
        },
        {
          path: '/controle-entrega/itens',
          name: 'ControleEntregaItens',
          meta: 'Controle de Entrega - Itens',
          component: () => import(/* webpackChunkName: "controle_entrega_itens" */ './views/Controle/controle_entrega/ControleEntregaItens.vue')
        },
        // TODO Implementar controle de devolucao

        {
          path: '/romaneio',
          name: 'Romaneio',
          meta: 'Romaneio',
          component: () => import(/* webpackChunkName: "romaneio" */ './views/Controle/romaneio/Romaneio.vue')
        },
        {
          path: '/romaneio/cadastrar',
          name: 'RomaneioCadastrar',
          meta: 'Romaneio',
          component: () => import(/* webpackChunkName: "romaneio_cadastrar" */ './views/Controle/romaneio/RomaneioCadastrar.vue')
        },
        // CONFIGURAÇÕES
        {
          path: '/parametro',
          name: 'Parmetro',
          meta: 'Parâmetro',
          component: () => import(/* webpackChunkName: "parametro" */ './views/Configuracoes/parametros/Parametros.vue')
        },
        {
          path: '/auditoria',
          name: 'Auditoria',
          meta: 'Auditoria',
          component: () => import(/* webpackChunkName: "auditoria" */ './views/Outros/auditoria/Auditoria.vue')
        },
        {
          path: '/sobre',
          name: 'Sobre',
          meta: 'Sobre',
          component: () => import(/* webpackChunkName: "sobre" */ './views/Outros/Sobre.vue')
        },
        {
          path: '/sintegra',
          name: 'Sintegra',
          meta: 'Sintegra',
          component: () => import(/* webpackChunkName: "sintegra" */ './views/Outros/Sintegra/Sintegra.vue')
        },
        {
          path: '/grupo-acesso',
          name: 'GrupoAcesso',
          meta: 'Grupo de Acesso',
          component: () => import(/* webpackChunkName: "grupo_acesso" */ './views/Configuracoes/grupo_acesso/GrupoAcesso.vue')
        },
        {
          path: '/tema',
          name: 'Tema',
          meta: 'Tema',
          component: () => import(/* webpackChunkName: "tema" */ './views/Configuracoes/Tema/Tema.vue')
        },
        //RELATORIOS
        {
          path: '/resumo-gerencial',
          name: 'ResumoGerencial',
          meta: 'Resumo Gerencial',
          component: () => import(/* webpackChunkName: "resumo_gerencial" */ './views/Relatorios/ResumoGerencial.vue')
        },
        {
          path: '/registro-inventario',
          name: 'RegistroInventario',
          meta: 'Registro Inventário',
          component: () => import(/* webpackChunkName: "registro_inventario" */ './views/Relatorios/RegistroInventario.vue')
        }
      ]
    },
    //IFRAME
    {
      path: '/participante_cadastrar',
      name: 'Participante_Cadastrar',
      meta: 'Participante',
      component: () => import(/* webpackChunkName: "participante_cadastrar" */ './views/Cadastros/participante/CadastrarParticipante.vue')
    },
    //404
    {
      path: '*',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */ './views/404.vue')
    },
    {
      path: '/vendas',
      name: 'VendaSimples',
      meta: 'Vendas Simples',
      component: () => import(/* webpackChunkName: "venda_simples" */ './views/Movimentacao/venda_simples/VendaSimples.vue')
    },
  ]
})
