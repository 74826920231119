/* eslint-disable */
import {auxiliares, lista} from '../../../services/GenericAPI'

const url = 'auxiliares';

const state = {
  all: [],
  cest: [],
  ncm: [],
  bancos: [],
  tp_item: [],
  cst_entrada: [],
  cst_saida: [],
  cst_icms: [],
  tp_fretes: [],
  tp_presenca: [],
  modelos: [],
};

const getters = {
  listaModelosDocFiscalVenda: (state) => {
    return state.modelos.filter(m => m.is_docfiscal && !m.is_docfrete)
  },
  listaAuxModelos: (state) => {
    return state.modelos
  },
  listaAuxTpFretes: (state) => {
    return state.tp_fretes
  },
  listaAuxTpPresenca: (state) => {
    return state.tp_presenca
  },
  listaAuxCest: (state) => {
    state.cest.forEach(function (cest) {
      cest.descricao = cest.cest_id + ' - ' + cest.descricao
    });
    return state.cest
  },
  listaAuxTp_Item: (state) => {
    state.tp_item.forEach(function (tipo) {
      tipo.descricao = tipo.tp_item_id + ' - ' + tipo.descricao
    });
    return state.tp_item
  },
  listaAuxNcms: (state) => {
    state.ncm.forEach(function (ncm) {
      ncm.descricao = ncm.ncm_id + ' - ' + ncm.descricao
    });
    return state.ncm
  },
  listaAuxCstIcms: (state) => {
    state.cst_icms.forEach(function (cst) {
      cst.descricao = cst.cst_icms_id + ' - ' + cst.descricao
    });
    return state.cst_icms
  },
  listaAuxCstIcmsReferente: (state) => {
    return state.cst_icms
  },

  listaAuxCstEntrada: (state) => {
    state.cst_entrada.forEach(function (cst) {
      cst.descricao = cst.cst_piscofins_id + ' - ' + cst.descricao
    });
    return state.cst_entrada
  },

  listaAuxCstSaida: (state) => {
    state.cst_saida.forEach(function (cst) {
      cst.descricao = cst.cst_piscofins_id + ' - ' + cst.descricao
    });
    return state.cst_saida
  },
  buscarAxiliar: (state) => (id, tipo) => {
    let busca = [];

    if (tipo === 1) {
      busca = state.cest.find(lista => lista.cest_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 2) {
      busca = state.tp_item.find(lista => lista.tp_item_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 3) {
      busca = state.ncm.find(lista => lista.ncm_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 4) {
      busca = state.cst_icms.find(lista => lista.cst_icms_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 5) {
      busca = state.cst_saida.find(lista => lista.cst_piscofins_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 6) {
      busca = state.cst_entrada.find(lista => lista.cst_piscofins_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 7) {
      busca = state.bancos.find(lista => lista.banco_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    } else if (tipo === 8) {
      busca = state.tp_fretes.find(lista => lista.tp_frete_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    }else if (tipo === 9) {
      busca = state.tp_presenca.find(lista => lista.tp_presenca_id === id);
      return busca !== null && busca !== undefined ? busca : ''
    }
  }
};

const actions = {
  async setAllAuxiliar({commit}, {set, tabela}) {
    // console.log('setAllAuxiliar ' + set + ' - ' + tabela);
    const list = (await lista(url+'/'+tabela)).data;
    commit(set, list)
  },
  async setCestByNcm({commit}, {ncm, onSucesso = null}) {
    if (ncm) {
      const list = (await lista(url+'/cest_by_ncm/' + ncm)).data;
      commit('SET_AUX_CEST', list);
      if (onSucesso !== null) {
        onSucesso()
      }
    } else {
      commit('SET_AUX_CEST', [])
    }
  }
};

const mutations = {
  SET_AUX_CEST(state, aux_cest) {
    state.cest = aux_cest
  },
  SET_AUX_TP_ITEM(state, aux_tp_item) {
    state.tp_item = aux_tp_item
  },
  SET_AUX_NCMS(state, aux_ncms) {
    state.ncm = aux_ncms
  },
  SET_AUX_CST_ICMS(state, aux_cst_icms) {
    state.cst_icms = aux_cst_icms
  },
  SET_AUX_CST_SAIDA(state, cst_saida) {
    state.cst_saida = cst_saida
  },
  SET_AUX_CST_ENTRADA(state, cst_entrada) {
    state.cst_entrada = cst_entrada
  },
  SET_AUX_FRETES(state, banco) {
    state.tp_fretes = banco
  },
  SET_AUX_PRESENCA(state, banco) {
    state.tp_presenca = banco
  },
  SET_AUX_MODELOS(state, modelos) {
    state.modelos = modelos
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
