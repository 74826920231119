/* eslint-disable */
import {v4 as uuidV4} from 'uuid';

export class ProdutoEmpresa {
  constructor(produto_id) {
    this.produto_empresa_id = uuidV4();
    this.produto_id = produto_id;
    this.empresa_id = '';
    this.estoque = 0;
    this.est_minimo = '';
    this.pr_custo = '';
    this.pr_venda = '';
    this.pr_minimo = '';
    this.pr_vista = '';
    this.ncm_id = '';
    this.cest_id = '';
    this.cst_icms_id = '';  // cst_nfce_id   -  cst_nota_id
    this.aliq_icms = 0;    //aliq_nfce - aliq_nota
    this.aliq_pis = 0;
    this.aliq_cofins = 0;
    this.cst_pis_cofins_entrada_id = '';
    this.cst_pis_cofins_saida_id = '';
    this.inf_complementar_nfe = ''
  }
}
