/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const FuncionarioSCH = (perPage)=> {
    let filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.addOrder('funcionarios.nome', 'asc');
    filtro.fields = ['funcionarios.nome', 'funcionarios.logrado','funcionarios.bairro','funcionarios.entregador',
      'funcionarios.limite_desconto','funcionarios.bloqueado', 'aux_municipios.nome as mun_nome'];
    filtro.join.addLeft('aux_municipios','aux_municipios.municipio_id','funcionarios.municipio_id');
    return filtro
};
