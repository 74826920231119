/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Grupo} from "../../../models/cadastro/grupo"
import {GrupoSCH} from "../../../search/GrupoSCH"
import {Filtro} from "../../../models/filtro/filtro";

const url = '/grupo';

const state = {
  all: [],
  grupo: new Grupo(),
  filtro: new Filtro()
};

const getters = {
  listaGruposSemFiltro: (state) => {
    return state.all
  },
  pegaGrupo: (state) => (id) => {
    let item= state.all.find(grupo => grupo.grupo_id === id);
    return item!== null ? item: new Grupo()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  async setGrupoListaCompleta ({ commit }) {
    const list = (await lista(url + '/lista_completa')).data;
    commit('SET_GRUPOS', list);
    EventBus.$emit('GRUPOS_PROCESSO_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', GrupoSCH(perPage))
  },
  searchGrupo({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao','perc_comissao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async setAllGrupos ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_GRUPOS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }

  },
  async processarGrupo ({ commit }, { grupo, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, grupo, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('grupo/setAllGrupos');
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_GRUPOS (state, grupos) {
    state.all = grupos
  },
  SET_GRUPO (state, grupos) {
    state.grupo = grupos
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
