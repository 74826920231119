/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class ParticipantePj {
  constructor (participanteId) {
    this.participante_pj_id = uuidV4();
    this.participante_id = participanteId;
    this.razao = '';
    this.ie = ''
  }
}
