/* eslint-disable */

import {Filtro} from "../models/filtro/filtro";

export const ContaReceberSCH = (perPage, emresa)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.filters.addFilter('tp_emitente','0','=');
  if (emresa) {
    filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  }

  filtro.addOrder('dt_emissao', 'desc');
  filtro.distinct = 'nota_id';
  return filtro
};

export const ContaPagarSCH = (perPage,emresa)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.filters.addFilter('tp_emitente','1','=');
  if (emresa) {
    filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  }
  filtro.addOrder('dt_emissao', 'desc');
  return filtro
};

export const DuplicatasSCH = ()=> {
  let filtro = new Filtro();
  filtro.perPage = '*';
  filtro.fields = ['dt_emissao','dt_vencimento', 'vl_a_pagar','dt_pagamento','participante_id', 'nome','ispago', 'isvencido','isdevolvido','tp_emitente','empresa'];
  filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=');
  return filtro
};

export const DuplicataSCH = (perPage,emresa)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['dt_emissao','dt_vencimento', 'vl_a_pagar','dt_pagamento','participante_id', 'nome','ispago', 'isvencido','isdevolvido','tp_emitente','empresa'];
  if (emresa) {
    filtro.filters.addFilter('empresa_id',sessionStorage.getItem('empresa_id'),'=')
  }  return filtro
};

