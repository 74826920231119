/* eslint-disable */
import {ParticipantePj} from './participante_pj'
import {ParticipantePf} from './participante_pf'
import { v4 as uuidV4 } from 'uuid';

export class Participante {
  constructor() {
    this.participante_id = uuidV4();
    this.fone2 = '';
    this.observacao = '';
    this.participantes_pf = new ParticipantePf(this.participante_id);
    this.participantes_pj = new ParticipantePj(this.participante_id);
    this.participantes_enderecos = [];
    this.participantes_autorizacao = [];

    this.nome = '';
    this.fone1 = '';
    this.dt_cadastro = '';
    this.cnpj_cpf = '';
    this.tp_contrib = '';
    this.bloqueado = false;
    this.vl_limite_credito_total = 0;
    this.vl_limite_credito_mes = 0;
    this.vl_maximo_parcela = 0;
    this.perc_maximo_desc_lucro = 0;
    this.email = '';
    this.site = '';
    this.empresa_id = '';

    this.tp_participante = false;
    this.cliente = false;
    this.crediario = false;
    this.fornecedor = false;
    this.transportador = false;
    this.restaurante = false
  }
}
