/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const  getEmpresaSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.fields = [
    'empresas.nome',
    'empresas.cnpj',
    'empresas.ie',
    'empresas.cep',
    'empresas.logrado',
    'empresas.bairro',
    'empresas.numero',
    'empresas.fone1',
    'aux_municipios.uf',
    'aux_municipios.nome as municipio'
  ];
  filtro.join.addLeft('aux_municipios','aux_municipios.municipio_id','empresas.municipio_id');
  return filtro
};
export const EmpresaSCH = (perPage)=> {
    let filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['empresas.nome',  'empresas.fone1', 'aux_municipios.nome as mun_nome'];
    filtro.addOrder('empresas.nome', 'asc');
    filtro.join.addLeft('aux_municipios','aux_municipios.municipio_id','empresas.municipio_id');
    return filtro
};
