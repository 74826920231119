<template>
  <div id="modal_padrao">
    <v-dialog v-model="Mostrar"  :width="width" :persistent="persistent"
              :fullscreen="fullscreen" :hide-overlay="overlay">
      <v-card id="drg" :dark="isDarkTheme">
        <v-toolbar class="headline popup-header" primary-title id="modalhead" color="primary" dark dense
                   style="position: sticky;top: 0; z-index: 2">
          {{ getTitulo() }}
          <slot name="ptoobar"></slot>
        </v-toolbar>
        <!--<v-card-title></v-card-title>-->
        <v-card-text>
          <slot></slot>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script async >
/* eslint-disable */
  import {mapState} from "vuex";

export default {
    props: {
      titulo: {
        default: ''
      },
      modal: {
        default: false
      },
      persistent: {
        default: true
      },
      fullscreen: {
        default: false
      },
      dragable: {
        default: false
      },
      width: {
        default: '50%'
      }
    },
    name: "ModalCadastro",

    data() {
      return {
        overlay: false,
        classe: '',
        margem_left: '25',
      }
    },
    methods: {
      validandoCampo(campo) {
        return (campo !== undefined && campo !== null && campo !== '')
      },
      getTitulo() {
        if (this.validandoCampo(this.titulo)) {
          return this.titulo
        } else {
          return this.$router.currentRoute.meta
        }
      },
    },
    computed: {
      ...mapState('geral', {isDarkTheme: 'isDarkTheme'}),

      Mostrar: {
        get() {
          return this.modal
        },
        set() {
          return this.Mostrar
        }
      },
    },
    watch: {
      modal: function () {
        if (this.modal) {
          let _this = this;
          let inter = setInterval(function () {
            let mywidth = document.getElementById('drg');
            if (mywidth !== null) {
              let width = window.screen.width;
              let w = mywidth.clientWidth;
              let res = width - w;
              res = res / 2;
              res = (res * 100) / width;
              _this.margem_left = parseInt(res)
            }
            clearInterval(inter)
          }, 500)
        }
      }
    }
  }
</script>

<style lang="scss">
  .v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
    font-size: 16px !important;
  }
</style>
