/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  MovEventoAdministrativo{
  constructor() {
    this.mov_eventos_adm_id= uuidV4();
    this.evento_adm_id = '';
    this.dt_evento = '';
    this.periodo = '';
    this.vl_evento = '';
    this.observacao = ''
  }
}
