import axios from 'axios'

export const API = axios.create({
  // withCredentials: false,
  headers: {
    Authorization: `Bearer ${sessionStorage.getItem('token')}`,
    UserId: sessionStorage.getItem('funcionario_id'),
    RotinaId: ''
  },
  baseURL: process.env.VUE_APP_API_URL,
  BASE_URL: '/'
});

export function setToken(token, userID) {
  sessionStorage.setItem('token', token);
  API.defaults.headers.Authorization = `Bearer ${token}`;
  API.defaults.headers.UserId = userID
}
