<template>
  <v-form :id="form_id" v-on:submit.prevent>
    <v-container fluid>
      <v-row>
        <slot></slot>
      </v-row>
    </v-container>
  </v-form>
</template>
<script async >
/* eslint-disable */
  export default {
    name:'ProContainerForm',
    props: {
      form_id: {
        default: 'form'+parseInt(Math.random()*1000)+3
      }
    }
  }
</script>
