<template>
  <div>
    <span> {{montaTexto()}} </span>
    <span v-if="pegaTimeCount <= 300000" class="renovar" @click="renovarLogin()">
      <v-icon>mdi-refresh</v-icon>
    </span>
    <span style="margin-right: 14px;"></span>
  </div>
</template>

<script async >
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'TokenExpirationTimer',
  methods: {
    ...mapActions('geral', ['setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
    montaTexto() {
      if (this.pegaTimeCount === 0) {
        return ''
      }
      let ss = Math.floor((this.pegaTimeCount / 1000) % 60);
      let mm = Math.floor((this.pegaTimeCount / (1000 * 60)) % 60);
      let hh = Math.floor((this.pegaTimeCount / (1000 * 60 * 60)) % 24);

      hh = (hh < 10) ? '0' + hh : hh;
      mm = (mm < 10) ? '0' + mm : mm;
      ss = (ss < 10) ? '0' + ss : ss;

      return hh + ':' + mm + ':' + ss
    },

    renovarLogin() {
      if (this.pegaTimeCount > 0) {
        this.setShowRenovarLoginMsg(false);
        this.setReloadPageRenovarLogin(false)
      }
      this.setRenovarLogin(true)
    }
  },
  computed: {
    ...mapGetters('token', ['pegaTimeCount'])
  }
}
</script>

<style scoped>
  span.renovar{
    margin-left: 6px;
  }
  span.renovar:hover{
    cursor: pointer;
  }
</style>
