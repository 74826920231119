/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Participante} from "../../../models/cadastro/participante/participante"
import {
  getParticipanteAvulsoSCH,
  getParticipanteFornecedorSCH,
  getParticipanteTransportadorSCH
} from "../../../search/ParticpanteSCH"

import {Filtro} from "../../../models/filtro/filtro";
import {BaseSCH, getBaseSCH} from "../../../search/BaseSCH";

const url = '/participante';

const state = {
  all: [],
  listaAllPart: [],
  allParticipante: [],
  allTransportador: [],
  participante: new Participante(),
  filtro: new Filtro()

};

const getters = {
  listaParticipante: (state) => {
    return state.all
  },
  listaAllParticipante: (state) => {
    return state.allParticipante
  },
  listaPartComp: (state) => {
    return state.listaAllPart
  },
  listaAllTransportador: (state) => {
    return state.allTransportador
  },
  pegaParticipanteDescricao: (state) => (id) => {
    let item = state.allParticipante.find(participante => participante.participante_id === id);
    return item !== null && item !== undefined ? item.nome : ''
  },
  pegaParticEndereco: (state, getters, rootState) => (item, tipo) => {
    if (item.length > 0) {
      let achou = item.filter(m => m.principal === true);
      if (tipo === 1) { // Endereco
        return achou[0].logrado + ', n° ' + achou[0].numero + ', ' + achou[0].bairro
      } else { // Municipio
        let end = rootState.municipio.all.find(mu => mu.municipio_id === achou[0].municipio_id);
        return end !== null && end !== undefined ? end.nome : 'Municipio Não encontrador'
      }
    } else {
      return 'Não encontrado'
    }
  },
  pegaParticipanteById: (state) => (id) => {
    let mar = state.allParticipante.find(participante => participante.participante_id === id);
    return mar !== null && mar !== undefined ? mar : new Participante()
  },
  pegaParticipante: (state) => {
    return state.participante
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  searchParticipante({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
        'cnpj_cpf',
        'participante_nome',
        'endereco',
        'municipio_nome'
      ],
      '%' + search + '%',
      'ilike'
    )
  },

  async setListParticipante({commit}) {
    const list = (await lista(url + '/lista_completa')).data;
    commit('SET_ALL_PARTICIPANTE', list);
    EventBus.$emit('PARTICIPANTE_PROCESSO_CONCLUIDO', true)
  },

  async setListCliCrediario({commit}, {dt_emissao, onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/lista_crediario', {dt_emissao: dt_emissao})).data;
      commit('SET_ALL_PARTICIPANTE', list)
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }


  },

  async setListAllParticipante({commit}, {onSucesso,onErro}) {
    try {
      const list = (await lista(url + '/lista_completa')).data;
      commit('SET_ALL_PARTICIPANTE', list);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async setListAllClientes({commit}, {onSucesso,onErro}) {
    try {
      const list = (await lista(url + '/lista_cliente')).data;
      commit('SET_LIST_CLIENTES', list);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setListTransportador({commit}, {onSucesso}) {
    try {
    let filtro = getParticipanteTransportadorSCH();
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_ALL_TRANSPORTADOR', list.data);
    onSucesso()
    } catch (error) {
      console.log(error)
    }
  },
  async setListFornecedor({commit}, {onSucesso, onErro}) {
    try {
      let filtro = getParticipanteFornecedorSCH();
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_ALL_TRANSPORTADOR', list.data);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async getParticipante({commit}, {id, onSucesso, onErro}) {
    try {
      const filtro = getBaseSCH('participante_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_PARTICIPANTE', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async getParticipanteCnpj_Cpf({commit}, {cnpj_cpf, onSucesso, onErro}) {
    try {
      const filtro = getBaseSCH('cnpj_cpf', cnpj_cpf, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_PARTICIPANTE', list.data[0]);
      onSucesso(list.data[0]);
      EventBus.$emit('GET_CONCLUIDO', true)
    } catch (error) {
      console.log(error);
      onErro();
      EventBus.$emit('GET_CONCLUIDO', false)
    }
  },
  async getParticipanteAvulso({commit},  {cnpj_cpf, onSucesso, onErro}) {
    try {
      let filtro = getParticipanteAvulsoSCH(cnpj_cpf);

      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_PARTICIPANTE', list.data[0]);
      onSucesso(list.data[0])
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setAllParticipante({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get-view', state.filtro)).data;
      commit('SET_PARTICIPANTES', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BaseSCH(perPage))
  },

  setParticipante({commit}, participante) {
    commit('SET_PARTICIPANTE', participante)
  },

  async processarParticipante({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.participante, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      // this.dispatch('participante/setAllParticipante');
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async processar({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.participante, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  // AUTORIZACAO
  removeParticipanteAutorizacao({commit}, endereco) {
    commit('REMOVE_AUTORIZACAO', endereco)
  },
  alteraParticipanteAutorizacao({commit}, endereco) {
    commit('ALTERA_AUTORIZACAO', endereco)
  },
  adicionaParticipanteAutorizacao({commit}, endereco) {
    commit('ADICIONA_AUTORIZACAO', endereco)
  },

  // ENDERECO
  removeEndereco({commit}, endereco) {
    commit('REMOVE_ENDERECO', endereco)
  },
  alteraEndereco({commit}, endereco) {
    commit('ALTERA_ENDERECO', endereco)
  },
  adicionaEndereco({commit}, endereco) {
    commit('ADICIONA_ENDERECO', endereco)
  }

};

const mutations = {
  SET_PARTICIPANTES(state, participante) {
    state.all = participante
  },
  SET_LIST_CLIENTES(state, participante) {
    state.listaAllPart = participante
  },
  SET_ALL_PARTICIPANTE(state, participante) {
    state.allParticipante = participante
  },
  SET_ALL_TRANSPORTADOR(state, allTransportador) {
    state.allTransportador = allTransportador
  },

  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  SET_PARTICIPANTE(state, participante) {
    state.participante = participante
  },

  // AUTORIZACAO
  REMOVE_AUTORIZACAO(state, participantes_autorizacao) {
    let pos = state.participante.participantes_autorizacao.indexOf(participantes_autorizacao);
    state.participante.participantes_autorizacao.splice(pos, 1)
  },
  ALTERA_AUTORIZACAO(state, participantes_autorizacao) {

    let array = state.participante.participantes_autorizacao.filter(lista => lista.participante_autorizacao_id === participantes_autorizacao.participante_autorizacao_id);
    let pos = state.participante.participantes_autorizacao.indexOf(array[0]);
    state.participante.participantes_autorizacao.splice(pos, 1, participantes_autorizacao)
  },
  ADICIONA_AUTORIZACAO(state, participantes_autorizacao) {
    state.participante.participantes_autorizacao.splice(state.participante.participantes_autorizacao.length, 1, participantes_autorizacao)
  },

  // ENDERECO
  REMOVE_ENDERECO(state, endereco) {
    let pos = state.participante.participantes_enderecos.indexOf(endereco);
    state.participante.participantes_enderecos.splice(pos, 1)
  },
  ALTERA_ENDERECO(state, endereco) {
    let array = state.participante.participantes_enderecos.filter(lista => lista.participante_endereco_id === endereco.participante_endereco_id);
    let pos = state.participante.participantes_enderecos.indexOf(array[0]);
    state.participante.participantes_enderecos.splice(pos, 1, endereco)
  },
  ADICIONA_ENDERECO(state, endereco) {
    state.participante.participantes_enderecos.splice(state.participante.participantes_enderecos.length, 1, endereco)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
