/* eslint-disable */
import {pegaDados} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {AuditoriaSCH} from "../../../search/AuditoriaSCH";
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = 'log';

const state = {
  all: [],
  filtro: new Filtro()
};

const getters = {
  listaAuditoria: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};
const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['registro'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getItem({commit}, id) {
    let filtro = getBaseSCH('log_audit_id', id, '=');
    const list = (await pegaDados(url + '/get', filtro)).data;

    EventBus.$emit('GET_LOG_CONCLUIDO', true, list.data[0])

  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', AuditoriaSCH(perPage))
  },
  async setAllAuditoria({commit}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      list.data.forEach(function (logs) {
        let dark = JSON.parse(sessionStorage.getItem('isDarkTheme'));
        let cor = dark ? 'white' : 'black';
        if (logs.acao === 'Inclusão') {
          cor = dark ? 'white' : 'black';
        } else  if (logs.acao === 'Alteração') {
          cor = '#11983e'
        } else  if (logs.acao === 'Consulta') {
          cor = '#2863f3'
        } else  if (logs.acao === 'Exclusão') {
          cor = '#EF5350'
        } else  if (logs.acao === 'Cancelamento') {
          cor = '#D50000'
        } else  if (logs.acao === 'Estorno') {
          cor ='#e28923'
        } else  if (logs.acao === 'Isentar') {
          cor = '#ccc'
        } else  if (logs.acao === 'Baixa') {
          cor = '#7354cc'
        }
        logs.rotina_cor = cor
      });

      commit('SET_ALL', list);

      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
};

const mutations = {
  SET_ALL(state, quadras) {
    state.all= quadras
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
