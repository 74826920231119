/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class PlanoConta {
  constructor () {
    this.plano_conta_id = uuidV4();
    this.conta_contabil = '';
    this.descricao = '';
    this.natureza_conta = '';
    this.conta_rfb = '';
    this.dt_conta = ''
  }
}
