/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  NotaEntregas{
  constructor(notaId) {
    this.nota_entrega_id= uuidV4();
    this.nota_id = notaId;
    this.logrado = '';
    this.numero = '';
    this.bairro = '';
    this.municipio_id = '';
    this.cep = '';
    this.complemento = '';
    this.referencia = '';

    // this.sit_pagamento = '' // não exite mais na versao 53 ou superior
    // this.forma_pagamento_id = '' // não exite mais na versao 53 ou superior

     this.observacao = ''; // não exite na versão 52
     this.fone = ''; // é fone1 na versão 52
     this.tipo = 'X';// não exite na versão 52
     this.dt_baixa = '';// não exite na versão 52
     this.notas_entregas_registros = []  // não na versão 52
  }
}
