/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {GrupoAcesso} from "../../../models/cadastro/grupo_acesso";
import {removerAcentos} from '../../../helpers/search'
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/grupo_acesso';

const state = {
  all: [],
  rotinas: [],
  allList: [],
  allListRotina: [],
  grupo: [],
};

const getters = {
  listaAllRotinas: (state) => {
    return state.allListRotina
  },
  listaGrupoAcessos: (state, getters, rootState) => {
    return state.all.filter(m => removerAcentos(m.descricao.toLowerCase()).includes(removerAcentos(rootState.geral.search.toLowerCase())))
  },
  listaGrupoAcessosSemFiltro: (state) => {
    return state.allList
  },
  listaRotinas: (state) => {
    return state.rotinas
  },
  pegaGrupoAcesso: (state) => (id) => {
    let mun = state.allList.find(grupo_acesso => grupo_acesso.grp_acesso_id === id);
    return mun !== null ? mun : new GrupoAcesso()
  },
  pegaRotina: (state) => (id) => {
    let mun = state.allListRotina.find(rotina => rotina.rotina_id === id);
    return mun !== null || mun !== undefined ? mun.rotina_descricao : 'Não encontrado'
  },
};

const actions = {
  async setAllRotinaList ({ commit }) {
    const list = (await lista(url+'/rotina_list')).data;
    commit('SET_ROTINAS_LIST', list)

  },
  async getGrupoAcesso({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('grupo_acesso.grp_acesso_id', id, '=');
      const list = (await pegaDados(url+'/item', filtro)).data;
      onSucesso( list.data[0])
    } catch (error) {
      onErro()
    }

  },
  // async getGrupoAcesso({commit}, id) {
  //   let filtro = getBaseSCH('grupo_acesso.grp_acesso_id', id, '=');
  //   const list = (await pegaDados(url+'/item', filtro)).data;
  //   EventBus.$emit('GET_GRUPO_ACESSO_CONCLUIDO', true,  list.data[0])
  // },

  async setAllRotina ({ commit }) {
    const list = (await lista(url+'/rotinas')).data;
    commit('SET_ROTINAS', list)
  },

  async setAllGrupoAcessosCompleto ({ commit }) {
    const list = (await lista(url+'/lista_completa')).data;
    commit('SET_ALL_GRUPO_ACESSOS', list)
  },

  async setAllGrupoAcessos ({ commit }) {
    const list = (await pegaDados(url+'/get')).data;
    commit('SET_GRUPO_ACESSOS', list.data)
  },

  async processarGrupoAcesso ({ commit }, { grupo_acesso, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, grupo_acesso, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('grupo_acesso/setAllGrupoAcessos');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_ALL_GRUPO_ACESSOS (state, grupo_acessos) {
    state.allList = grupo_acessos
  },
  SET_GRUPO_ACESSOS (state, grupo_acessos) {
    state.all = grupo_acessos
  },
  SET_ROTINAS (state, rotina) {
    state.rotinas = rotina
  },
  SET_ROTINAS_LIST (state, rotina) {
    state.allListRotina = rotina
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
