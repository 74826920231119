/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Grupo {
  constructor () {
    this.grupo_id = uuidV4();
    this.descricao = '';
    this.perc_comissao = 0

  }
}
