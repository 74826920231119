<template>
  <div>
    <v-app-bar id="toobarDash" app dark style="top: 30px" :clipped-left="clipped">
      <div style="width: 50%" v-show="showSearch" class="toolbarSearch">
        <v-text-field name="search" dense id="toolbarSearch" flat v-model="model" :search-input.sync="pesquisa"
                      @keyup="pesquisar" solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Pesquisar"
                      :append-icon="showFilter || showFilterSearch ? 'mdi-filter' : ''" @click:append="toggleModalFiltro()" rounded>
        </v-text-field>

      </div>
      <v-spacer></v-spacer>
      <slot name="more_content"></slot>

      <div style="margin-right: 5%">
        <v-btn v-if="showBtnFilter" @click="toggleModalFiltro()" text>
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </div>

      <slot name="p_toolbar"></slot>

    </v-app-bar>
     <v-form >
          <slot name="content"></slot>
      </v-form>
  </div>
</template>

<script async >
/*eslint-disable*/
  import {mapActions, mapGetters, mapState} from "vuex";
  import miscMixin from '../helpers/misc'

  export default {
    name: "PForm",
    mixins: [miscMixin],
    props: {
      showSearch: {
        type: Boolean,
        default: false
      },
      showBtnFilter: {
        type: Boolean,
        default: false
      },
      showFilterSearch: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        model: null,
        pesquisa: null,
      }
    },
    methods: {
      ...mapActions('geral', [
        'setShowFilter',
        'setModalFiltro',
        'toggleModalFiltro',
        'setIsNotPform',
        'setSearch']),
      pesquisar(event) {
        if (event.keyCode === 13) { // ENTER
          this.setSearch(this.model)
        }
      },
    },
    created() {
      this.model = this.validarCampo(this.search) ? this.search : '';
      this.setSearch(this.model);
      this.setIsNotPform(false)
    },
    computed: {
      ...mapGetters('geral', {search: 'getSearch'}),

      ...mapState('geral', {
        modalFiltro: 'modalFiltro',
        clipped: 'clipped',
        showFilter: 'showFilter',
      }),
      ...mapGetters('configuracao', {tema: 'pegarTema'}),

    },
    watch: {
      'model': function () {
        if (!this.validarCampo(this.model)) {
          this.setSearch("")
        }
      },
      pesquisa: function (val) {
        if (this.validarCampo(val)) {
          this.setSearch(val)
        }
      },
    },
    mounted() {
      if (this.validarCampo(this.tema)) {
        this.nova_configuracao(this.tema)
      }
    }
  }
</script>
<style lang="scss">
  .toolbarSearch {
    .v-input--is-focused .v-icon {
      color: #386cff !important;
    }
  }

</style>
