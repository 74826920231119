/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const getProduto2SCH = (campo, valor, condicao) => {
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.filters.addFilter(campo, valor, condicao);
  filtro.fields = ['*'];
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id', 'produtos.produto_id');
  filtro.filters.addFilter('produtos_empresas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('estoque', 0, '>');
  return filtro
};

export const ProdutoSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = [
    'produtos.codigo',
    'produtos.descricao',
    'produtos.bloqueado',
    'produtos_empresas.pr_minimo',
    'produtos_empresas.est_minimo',
    'produtos_empresas.pr_venda',
    'produtos_empresas.pr_custo',
    'produtos_empresas.pr_vista',
    'produtos.unid',
    'marcas.descricao as marca_desc'];
  filtro.addOrder('produtos.descricao', 'asc');
  filtro.join.addLeft('marcas', 'marcas.marca_id', 'produtos.marca_id');
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id', 'produtos.produto_id');
  return filtro
};

export const ProdutoVendaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;

  filtro.fields = [
    'produtos.codigo',
    'produtos.descricao',
    'produtos.bloqueado',
    'produtos_empresas.est_minimo',
    'produtos_empresas.pr_venda',
    'produtos_empresas.pr_vista',
  ];
  filtro.addOrder('produtos.descricao', 'asc');
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id', 'produtos.produto_id');
  return filtro
};

export const ProdutoVendaSimplesSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['codigo', 'descricao', 'pr_venda','estoque','pr_vista'];
  filtro.addOrder('produtos.descricao', 'asc');
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id', 'produtos.produto_id');
  filtro.filters.addFilter('produtos_empresas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('estoque', 0, '>');
  return filtro
};

export const ProdutoVendaNormalSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['codigo', 'descricao', 'pr_venda','estoque','pr_vista'];
  filtro.addOrder('produtos.descricao', 'asc');
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id', 'produtos.produto_id');
  filtro.filters.addFilter('produtos_empresas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('estoque', 0, '>');
  filtro.filters.addFilter('bloqueado', false, '=');
  return filtro
};

