/* eslint-disable */
export const removerAcentos = (text) => {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text
};

export const pegaCpfCnpj = (item) => {
  if (item.tp_pessoa === 'J') {
    if (item.cliente_pj !== null) {
      return item.cliente_pj.cnpj
    }
  } else {
    if (item.cliente_pf !== null) {
      return item.cliente_pf.cpf
    }
  }
};
