/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const DFeSCH = (perPage)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['notas.modelo_id', 'notas.serie', 'notas.numero', 'notas_dfe.chave', 'notas_valores.vl_nota', 'notas_dfe.status', 'notas.dt_emissao', 'notas.nota_id', 'notas_dfe.created_at'];
  filtro.join.addLeft('notas','notas.nota_id','notas_dfe.nota_id');
  filtro.join.addLeft('notas_valores','notas_valores.nota_id','notas_dfe.nota_id');
  filtro.addOrder('notas_dfe.created_at', 'desc');
  filtro.filters.addFilter('notas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  return filtro
};
