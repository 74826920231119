/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const EventoAdministrativoSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['eventos_administrativos.descricao', 'eventos_administrativos.plano_conta_id', 'plano_contas.descricao as plano_conta'];
  filtro.addOrder('eventos_administrativos.descricao', 'asc');
  filtro.join.addLeft('plano_contas', 'plano_contas.plano_conta_id', 'eventos_administrativos.plano_conta_id');
  return filtro
};
