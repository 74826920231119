/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Rascunho {
  constructor () {
    this.rascunho_id = uuidV4();
    this.rascunho = '';
    this.rotina_id = '';
    this.funcionario_id = sessionStorage.getItem('funcionario_id');
    this.empresa_id = sessionStorage.getItem('empresa_id')
  }
}
