/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EmpresaContador {
  constructor (empresaId) {
    this.empresa_contador_id = uuidV4();
    this.empresa_id = empresaId;
    this.nome = '';
    this.cnpj = '';
    this.cpf = '';
    this.crc =  '';
    this.logrado = '';
    this.numero =  '';
    this.bairro = '';
    this.municipio_id = '';
    this.complemento = '';
    this.cep = '';
    this.fone1 = '';
    this.fone2 = '';
    this.email = ''
  }
}
