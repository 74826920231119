/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const MovEventoAdministrativoSCH = (perPage)=> {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['mov_eventos_administrativos.dt_evento','mov_eventos_administrativos.periodo', 'mov_eventos_administrativos.vl_evento','eventos_administrativos.descricao as evento'];
  filtro.join.addLeft('eventos_administrativos','eventos_administrativos.evento_adm_id','mov_eventos_administrativos.evento_adm_id');
  filtro.addOrder('eventos_administrativos.descricao', 'asc');
  return filtro
};
