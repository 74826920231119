/* eslint-disable */
import {pegaDados} from '../../services/GenericAPI'

const url = '/analise/get';

const state = {
  treinamento: false,
  all: []
};

const getters = {
  listaAll: (state) => {
    return state.all
  },
  getIsTreinameto: (state) => {
    return state.treinamento
  }
};

const actions = {
  async getCep({commit}, {cep, onSucesso,onErro}) {
    try{
      const list = (await pegaDados('/home/getCep', {cep: cep})).data;
      onSucesso(list)
    } catch (error) {
      console.log(error);
      onErro()
      // this.dispatch('geral/setMsgSuccess', 'Registro processado com sucesso')
      // this.dispatch('geral/setObjMsgError', error.response.data)
    }

  },
  async setAllList({commit},{onSucesso}) {
    try {
      const list = (await pegaDados(url)).data;
      commit('SET_ALL', list);
      onSucesso()
    } catch (error) {
    }

  },
  async getTreinameto({commit}, {onSucesso, onErro}) {
    try {

      const list = (await pegaDados('home/is_treinamento')).data;
      console.log('SET_TREINAMENTO:', list.istreinamento);
      commit('SET_TREINAMENTO', list.istreinamento);
      this.dispatch('geral/setTreinamento', list.istreinamento);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setIsTreinameto({commit}) {
    const list = (await pegaDados('home/is_treinamento')).data;
    commit('SET_TREINAMENTO', list.istreinamento);
    this.dispatch('geral/setTreinamento', list.istreinamento)
  }

};

const mutations = {
  SET_ALL(state, all) {
    state.all = all
  },
  SET_TREINAMENTO(state, all) {
    state.treinamento = all
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
