/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EmpresasConfigNfe {
  constructor (empresa_id) {
    this.empresa_config_nfe_id = uuidV4();
    this.empresa_id = empresa_id;

    this.dt_contingencia = '';
    this.hr_contingencia = '';
    this.motivo_contingencia = '';
    this.is_producao = true;
    this.is_contingencia = false
  }
}
