/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Romaneio {
  constructor () {
    this.romaneio_id = uuidV4();
    this.dt_romaneio = '';
    this.veiculo_id = '';
    this.funcionario_id = '';
    this.romaneio_itens = []

  }
}
