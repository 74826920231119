/* eslint-disable */
import {mapState, mapActions, mapGetters} from 'vuex'
import {EventBus} from '../helpers/event-bus'
import router from '../router'
import miscImageCropper from "../helpers/img-cropper";
import miscMixin from "../helpers/misc";
import misc_rascunho from "../helpers/misc_rascunho";
import {acaoApi} from "../helpers/enums";

export default {
  mixins: [miscMixin, miscImageCropper, misc_rascunho],
  components: {
    ListaMenu: () => import('../components/ListaMenu.vue'),
    ProdutoSearch: () => import('./ProdutoSearch.vue'),
    DialogLoading: () => import('../components/DialogLoading.vue')
  },
  data() {
    return {
      usuario: '',
      empresa_atual: '',
      showProgressCarregando: true,
      varias_empresas: false,
      empresa: [],
      show_movimentacoes: false,
      show_controles: false,
      show_relatorio: false,
      show_consultas: false,
      show_outro: false,
      show_cadastros: false,
      miniVariant2: false,
      show_progress: false,
      bntTop2: true,
      clipped: false,
      drawer: true,
      cadastros: [
        {path: '/produtos', title: 'Produtos', visibile: false, rotina: '01.01'},
        {path: '/grupos', title: 'Grupos', visibile: false, rotina: '01.02'},
        {path: '/marcas', title: 'Marcas', visibile: false, rotina: '01.03'},
        {path: '/participantes', title: 'Participantes', visibile: false, rotina: '01.04'},
        {path: '/operadora-cartao', title: 'Forma de Pagamento', visibile: false, rotina: '01.05'},
        {path: '/empresa', title: 'Empresas', visibile: false, rotina: '01.06'},
        {path: '/funcionario', title: 'Funcionários/Usuários', visibile: false, rotina: '01.07'},
        {path: '/veiculo', title: 'Veículos', visibile: false, rotina: '01.08'},
        {path: '/cfops', title: 'CFOPs', visibile: false, rotina: '01.09',},
        {path: '/cst-csosn', title: 'Referência CST/CSOSN', visibile: false, rotina: '01.10',},
        {path: '/plano-contas', title: 'Plano de Contas', visibile: false, rotina: '01.12',},
        {path: '/evento-caixa', title: 'Eventos de Caixa', visibile: false, rotina: '01.11',},
        {path: '/evento-administrativo', visibile: false, title: 'Eventos Administrativos', rotina: '01.13'},
      ],
      consultas: [
        {path: '/historico-cliente', title: 'Histórico do cliente', visibile: false, rotina: '04.01'},
        {path: '/historico-produto', title: 'Histórico do produto', visibile: false, rotina: '04.02'}
      ],
      movimentacoes: [
        {path: '/vendas', title: 'Cupom Fiscal', subtitle: "(NFC-e)", visibile: false, rotina: '02.02'},
        {path: '/notas', title: 'Notas Fiscais', subtitle: "(Próprias)", visibile: false, rotina: '02.01'},
        {path: '/notas-entrada', title: 'Notas Fiscais', visibile: false, subtitle: "(Terceiros)", rotina: '02.03'},
        {path: '/frete', title: 'Notas Fiscais de Frete', visibile: false, rotina: '02.04'},
        {path: '/requisicao', title: 'Requisição', visibile: false, rotina: '02.08'},
        {path: '/lancamento-caixa', title: 'Mov. Evento Caixa', visibile: false, rotina: '02.05'},
        {path: '/mov-evento-admin', title: 'Mov. Evento Administrativo', visibile: false, rotina: '02.07'},
      ],
      controles: [
        {path: '/conta-receber', title: 'Contas a Receber', visibile: false, rotina: '03.01', icon: 'assignment'},
        {path: '/conta-pagar', title: 'Contas a Pagar', visibile: false, rotina: '03.02', icon: 'mdi-currency-usd'},
        {path: '/controle-entrega', title: 'Controle de Entrega', visibile: false, rotina: '03.03', icon: 'fa-truck'},
        // {path: '/controle-devolucao', title: 'Controle de Devolução', visibile: false, rotina: '03.03', icon: 'fa-truck'},
        {path: '/romaneio', title: 'Romaneio', visibile: false, rotina: '03.04', icon: 'mdi-currency-usd'},
      ],
      configuracoes: [
        {path: '/grupo-acesso', title: 'Grupos de Acesso', visibile: false, rotina: '05.01'},
        {path: '/parametro', title: 'Parâmetros', visibile: false, rotina: '05.02'},
        // {path: '/auditoria', title: 'Auditoria', visibile: true, rotina: '',  },
        {path: '/tema', title: 'Tema', visibile: false, rotina: '05.03', icon: 'image'}
      ],
      outros: [
        {path: '/sintegra', title: 'Sintegra', visibile: false, rotina: '06.01',},
        {path: '/auditoria', title: 'Auditoria', visibile: false, rotina: '06.02',},
      ],
      relatorio_mov: [
        {path: '/resumo-gerencial', title: 'Resumo Gerencial', visibile: true, rotina: '07.01.01'},
        {path: '/registro-inventario', title: 'Registro de Inventário', visibile: true, rotina: '07.01.02'},
      ],

    }
  },
  name: 'Dash',
  methods: {
    ...mapActions('home', ['getTreinameto']),
    ...mapActions('empresa', ['setEmpresaByFuncionario', 'setAllEmpresas']),
    ...mapActions('configuracao', ['setAllTemaByUser']),
    ...mapActions('geral', ['setMiniVariant', 'toggleMiniVariant', 'setSidebarID', 'setModalFiltro',
      // 'setShowFilter', 'setModal', 'toggleModalFiltro', 'setDisableField', 'setShowArqMorto',
      'setSearch', 'toggleDialogError', 'setDialogError', 'toggleSnackSuccess',
      'setEditavel', 'setAdicionavel', 'setRemovivel', 'setConsultavel', 'setShowHome', 'setIsDark',
      'setBtnAdicionavel', 'toggleArquivoMorto',  'setIsNotPform', 'setClipped', 'setRenovarLogin']),
    ...mapActions('grupo_acesso', ['getGrupoAcesso']),
    // ...mapActions('auxiliar', ['setAllAuxiliar']),
    ...mapActions('token', ['start', 'cancelarToken']),

    setVisiblidade(mov) {
      if (this.treinamento) {
        return (mov.title === 'DFE') ? false : mov.visibile
      } else {
        return mov.visibile
      }
    },
    sair() {
      this.cancelarToken();
      sessionStorage.clear();
      window.location = '/'
    },
    irHome() {
      this.nova_configuracao(this.tema);
      router.push({path: '/home'}).catch()
    },
    executaArquivoMorto(event) {
      EventBus.$emit('ARQUIVO_MORTO')
    },
    irParaVenda() {
      localStorage.setItem('rotina_id', '02.02');
      if (this.validarCampo(sessionStorage.getItem('serie_nfce'))) {
        router.push({path: '/vendas'}).catch()
      } else {
        this.open_alerta('O campo SERIE NFCE não foi informado no cadastro de FUNCIONÁRIO', 'red')
      }

    },
    // irPara(id, rotina) {
    irPara(item) {
      let id = item.id;
      let rotina = item.rotina;

      localStorage.setItem('rotina_id', rotina);
      let _this = this;
      sessionStorage.setItem('tema', JSON.stringify(this.tema));
      this.nova_configuracao(this.tema);

      this.habilitarAdd(rotina);
      this.setSearch('');
      this.setSidebarID(id);
      this.setModalFiltro(false);

      if (id === '/vendas') {
        if (this.validarCampo(sessionStorage.getItem('serie_nfce'))) {
          let inter2 = setInterval(function () {
            router.push({path: id}).catch();
            clearInterval(inter2)
          }, 500)
        } else {
          this.open_alerta('O campo SERIE NFCE não foi informado no cadastro de FUNCIONÁRIO', 'red')
        }
      } else {
        router.push({path: id}).catch()
      }
    },
    setBacground() {
      let url = '';
      let cont = 0;
      if (this.validarCampo(this.tema)) {
        if (this.tema.urls_imagem.length > 0) {
          this.tema.urls_imagem.forEach(i => {
            if (i.capa) {
              cont++
            }
          });
          if (cont > 0) {
            url = this.selecionaImagem(this.tema.urls_imagem, '1600')
          }
        }
      } else {
        url = "https://picsum.photos/1600/900?random.jpg"
      }
      return url
    },

    getPermissao(achou, rotina) {
      let permissao = achou.filter(lista => lista.rotina_id.substring(6, 8) === rotina)[0];
      permissao = permissao === undefined ? false : permissao.acessivel;
      return permissao
    },

    habilitarAdd(rotina) {
      let list = [];
      if (rotina.substring(0, 2) === '01') {
        list = this.cadastros.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '02') {
        list = this.movimentacoes.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '03') {
        list = this.controles.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '04') {
        list = this.consultas.filter(lista => lista.rotina === rotina)[0]
      } else if (rotina.substring(0, 2) === '05') {
        list = this.configuracoes.filter(lista => lista.rotina === rotina)[0]
      }
      let achou = this.acessibilidade.grupo_acesso_permissoes.filter(lista => lista.rotina_id.substring(0, 5) === rotina);

      if (achou.length > 0) {
        this.setSearch('');

        this.setBtnAdicionavel(this.getPermissao(achou, '01'));

        this.setAdicionavel({valor: this.getPermissao(achou, '01')});

        this.setEditavel({valor: this.getPermissao(achou, '02')});

        this.setRemovivel({valor: this.getPermissao(achou, '03')});

        this.setConsultavel({valor: this.getPermissao(achou, '04')});

        this.desabilitarAdd = achou.acessivel
      } else {
        console.log('Não se aplica ')
      }
    },
    percorrerMenu(lista, rotina) {
      if (rotina.substring(0, 2) === '01') {
        this.show_cadastros = true
      } else if (rotina.substring(0, 2) === '02') {
        this.show_movimentacoes = true
      } else if (rotina.substring(0, 2) === '03') {
        this.show_controles = true
      } else if (rotina.substring(0, 2) === '04') {
        this.show_consultas = true
        // } else if (rotina.substring(0, 2) === '05') {
      } else if (rotina.substring(0, 2) === '06') {
        this.show_outro = true
      } else if (rotina.substring(0, 2) === '07') {
        this.show_relatorio = true
      }

      let achou = lista.filter(lista => lista.rotina === rotina)[0];

      if (achou) {
        let pos = lista.indexOf(achou);
        let change = achou;
        change.visibile = true;
        lista.splice(pos, 1, change)
      }

      this.showProgressCarregando = false
    },
    menu_acessivel() {
      let _this = this;

      this.getGrupoAcesso({
        id: sessionStorage.getItem('grp_acesso_id'),
        onSucesso:(lista)=>{
          this.acessibilidade = lista;
          if (lista !== null && lista.grupo_acesso_permissoes.length > 0) {
            let array = [];

            // Cria array com as rotinas
            lista.grupo_acesso_permissoes.forEach(function (gp) {
              array.push(gp.rotina_id.substring(0, 5))
            });

            // Apaga registros duplicados
            let novaArr = array.filter(function (este, i) {
              return array.indexOf(este) === i;
            });

            // Torna visivel as rotinas permitidas
            novaArr.forEach(function (gp) {
              if (gp === '00') {sessionStorage.setItem('setShowHome', true)}

              _this.percorrerMenu(_this.cadastros, gp);
              _this.percorrerMenu(_this.movimentacoes, gp);
              _this.percorrerMenu(_this.controles, gp);
              _this.percorrerMenu(_this.consultas, gp);
              _this.percorrerMenu(_this.configuracoes, gp);
              _this.percorrerMenu(_this.outros, gp);
              _this.percorrerMenu(_this.relatorio_mov, gp)
            });
            _this.setShowHome(JSON.parse(sessionStorage.getItem('setShowHome')) || false)
          }
        },
        onErro:()=>{
          this.showProgressCarregando = false
        },
      })
    },
    confirmarEmpresa() {
      this.varias_empresas = !this.varias_empresas;
      this.dadosSessao(this.empresa);
      this.empresa_atual = sessionStorage.getItem('empresa');
      window.location.reload()

    },
    fechaModal() {
      this.varias_empresas = !this.varias_empresas
    },
    alterarOpacidade(inspireInicio) {
      if (this.isDarkTheme) {
        return inspireInicio.style.backgroundImage = "linear-gradient(180deg,rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(" + this.setBacground() + ")"
      } else {
        return inspireInicio.style.backgroundImage = "linear-gradient(180deg,rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.3) 100%), url(" + this.setBacground() + ")"
      }
    }
  },
  created() {
    this.setIsNotPform(true);
    this.setAllEmpresas({onSucesso: () => {},onErro: () => {}});
    this.getTreinameto({
      onSucesso: () => {
        this.menu_acessivel()
      },
      onErro: () => {
      },
    })

  },

  mounted() {
    sessionStorage.setItem('aba','1')
    // this.setAllAuxiliar({set: 'SET_AUX_NCMS', tabela: 'aux_ncms'})
    // this.setAllAuxiliar({set: 'SET_AUX_CEST', tabela: 'aux_cest'})
    // this.setAllAuxiliar({set: 'SET_AUX_TP_ITEM', tabela: 'aux_tipos_item'})
    // this.setAllAuxiliar({set: 'SET_AUX_CST_ICMS', tabela: 'aux_cst_icms'})
    // this.setAllAuxiliar({set: 'SET_AUX_CST_SAIDA', tabela: 'aux_cst_piscofins_saida'})
    // this.setAllAuxiliar({set: 'SET_AUX_CST_ENTRADA', tabela: 'aux_cst_piscofins_entrada'})
    // this.setAllAuxiliar({set: 'SET_AUX_MODELOS', tabela: 'aux_modelos'})

    let _this = this;
    window.addEventListener('popstate', function () {
      if (_this.validarCampo(_this.rascunho)) {
        _this.ProcessandoRascunho(_this.rascunho, acaoApi.REMOVER, '', 1);
      }
    }, false);

    if (!this.iniciado) this.start();
    this.usuario = sessionStorage.getItem('funcionario_nome');
    this.empresa_atual = sessionStorage.getItem('empresa');
    this.setEmpresaByFuncionario({
      funcionario: sessionStorage.getItem('funcionario_id'),
      onSucesso: () => {
      }
    });

    // Plano de fundo
    const inspireInicio = document.getElementById('inspireInicio');

    inspireInicio.style.backgroundRepeat = 'no-repeat';
    inspireInicio.style.backgroundAttachment = 'fixed';
    inspireInicio.style.backgroundSize = '100% 100%';

    if (sessionStorage.getItem("funcionario_id") !== null) {
      if (!this.validarCampo(this.tema)) {
        this.setAllTemaByUser({id: sessionStorage.getItem("funcionario_id")});

        EventBus.$on('TEMA_CONCLUIDO', (sucesso) => {
          if (sucesso) {
            this.nova_configuracao(this.tema);
            inspireInicio.style.backgroundImage = this.alterarOpacidade(inspireInicio);
            sessionStorage.setItem('cor_tema', this.tema.cor)
          }
        })
      } else {
        inspireInicio.style.backgroundImage = this.alterarOpacidade(inspireInicio);
        this.nova_configuracao(this.tema)
      }
    } else {
      router.push({path: '/'}).catch()
    }

    function func() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(false)
      }
    }

    function func1() {
      if (_this.miniVariant2) {
        _this.setMiniVariant(true)
      }
    }

    let item = document.getElementById('sidebar');
    let icnClick2 = document.getElementById('icnClick2');
    icnClick2.addEventListener('click', function (event) {
      if (!_this.miniVariant2) {
        _this.miniVariant2 = true;
        item.addEventListener('mouseover', func, false);
        item.addEventListener('mouseout', func1, false)
      } else {
        _this.miniVariant2 = false;
        _this.setMiniVariant(false)
      }
    }, false);

    // EventBus.$on('ENCERRAR_PROCESSO', (sucesso) => {
    //   console.log('ENCERRAR_PROCESSO');
    //   this.show_progress = false
    // })

    EventBus.$on('PROCESSO_CONCLUIDO', (sucesso, fecha, path) => {
      this.show_progress = false;
      if (sucesso && fecha) {
        router.push({name: path}).catch()
      }
    });

    EventBus.$on('RENOVAR_LOGIN', (renovar) => {
      console.log('RENOVAR_LOGIN');
      if (this.renovarLoginModal) return;

      if (renovar) { // Só executa quando o token apenas expirou
        this.setRenovarLogin(true)
      } else { // Executa quando o token é inválido ou inexistente
        this.open_alerta('Sua sessão expirou. Faça o login novamente.', 'orange')
      }
    });

    document.addEventListener('keydown', function (e) {
      let code = e.which || e.keyCode;

      if (code === 27) {
        _this.varios_produtos = false;
        e.preventDefault()
      }

      if (e.ctrlKey && code === 226) {
        _this.varios_produtos = !_this.varios_produtos;
        e.preventDefault()
      }

    });

  },

  computed: {
    ...mapGetters('rascunho', {rascunho: 'pegaRascunho'}),
    ...mapGetters('empresa', ['listaAllEmpresaFunc']),
    ...mapGetters('configuracao', {tema: 'pegarTema'}),
    ...mapGetters('geral', {adicionavel: 'pegarAdicionavel', treinamento: 'getTreinamento'}),

    ...mapState('token', {iniciado: 'iniciado'}),
    ...mapState('geral', {
      disableField: 'disableField',
      search: 'search',
      toolbarsave: 'toolbarsave',
      btnDesdobramento: 'btnDesdobramento',
      toolbarsearch: 'toolbarsearch',
      btn_adicionavel: 'btn_adicionavel',
      btnAdd: 'btnAdd',
      miniVariant: 'miniVariant',
      modalFiltro: 'modalFiltro',
      toolbarDash: 'toolbarDash',
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      showFilter: 'showFilter',
      isNotPform: 'isNotPform',
      msgSuccess: 'msgSuccess',
      renovarLoginModal: 'renovarLoginModal',
    }),
    pesquisando: {
      get() {
        return this.search
      },
      set(val) {
        this.setSearch(val)
      }
    },
    showSnack: {
      get() {
        return this.snackSuccess
      },
      set() {
        this.toggleSnackSuccess()
      }
    },
    ArquivMorto: {
      get() {
        return this.arquivo_morto
      },
      set() {
        this.toggleArquivoMorto()
      }
    }
  },
  watch: {
    isDarkTheme: function () {
      var cor_tema = sessionStorage.getItem('cor_tema');
      if (this.isDarkTheme) {
        let tinycolor = require("tinycolor2");
        let c = tinycolor(this.setValues('cor', '#000000', this.tema));
        this.tema.cor = tinycolor(c).brighten(c.isDark() ? -5 :-30).toString();
      } else {
        this.tema.cor = cor_tema
      }
      this.nova_configuracao(this.tema)
    },
    ArquivMorto: function () {
      this.executaArquivoMorto()
    },
    clipped: function () {
      this.setClipped(this.clipped)
    }
  },
  beforeDestroy() {
    EventBus.$off('TEMA_CONCLUIDO');
    EventBus.$off('CLICK_BTN_SAVE');
    EventBus.$off('CLICK_BTN_CANCEL');
    EventBus.$off('PROCESSO_CONCLUIDO');
    EventBus.$off('LISTA_PROCESSADA')
    // EventBus.$off('ENCERRAR_PROCESSO')
  }
}
