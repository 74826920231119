/* eslint-disable */
import {v4 as uuidV4} from 'uuid';

export class Produto {
  constructor() {
    this.produto_id = uuidV4();
    this.grupo_id = '';
    this.marca_id = '';
    this.produtos_composicoes = [];
    this.produtos_variacoes = [];
    this.produtos_promocoes = [];
    this.produtos_empresas = [];
    this.bloqueado = false;
    this.codigo = '';
    this.descricao = '';
    this.perc_comissao = 0;
    this.peso = '';
    this.unid = '';
    this.link_site = '';
    this.tp_item_id = ''
  }
}
