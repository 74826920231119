/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  NotaEntregaRegistro{
  constructor(id='') {
    this.nota_entrega_registro_id= uuidV4();
    this.nota_entrega_id= id;
    this.dt_registro = '';
    this.receber_entrega = false;
    this.forma_pagamento_id = '';
    this.vl_receber = 0;
    this.status = 'P'; // Pendente
    this.observacao = '';
    this.notas_entregas_itens = []
  }
}
