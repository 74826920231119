/* eslint-disable */
import {pegaDados, processar, processaReport} from '../../../services/GenericAPI'
import {Romaneio} from "../../../models/controle/romaneio";
import {RomaneioSCH} from "../../../search/RomaneioSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/romaneio';

const state = {
  all: [],
  allList: [],
  listItensSemRomaneio: [],
  filtro: new Filtro(),
  romaneio: new Romaneio()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaRomaneio: (state) => {
    return state.romaneio
  },
  listaRomaneioSemFiltro: (state) => {
    return state.all
  },
  listAllItensSemRomaneio: (state) => {
    return state.listItensSemRomaneio
  },
  listaAllRomaneio: (state) => {
    return state.allList
  },
};

const actions = {
  searchRomaneio({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['placa'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getRomaneio({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('romaneio_id', id, '=');
      const list = (await pegaDados(url+'/getAll', filtro)).data;
      commit('SET_ROMANEIO', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', RomaneioSCH(perPage))
  },

  setarFiltro({commit}, filtro) {
    commit('SET_FILTRO',filtro)
  },

  setRomaneio({commit}, romaneio) {
    commit('SET_ROMANEIO', romaneio)
  },

  async setAllRomaneio ({ commit },{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_ROMANEIOS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setItensSemRomaneio ({ commit },{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get_itens_sem_somaneio', state.filtro)).data;
      // console.log('SET_ITENS_SEM_ROMANEIOS:', list);
      commit('SET_ITENS_SEM_ROMANEIOS', list);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async imprimiRomaneio({ commit }, { romaneio, acao, onSucesso, onErro }) {
    try {
      const retorno = await processaReport(url + '/imprimir_romaneio', romaneio);
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  async processarRomaneio ({ commit }, { acao, onSucesso, onErro }) {
    try {
      let res = await processar(url+  (acao === 'gravar' ? '/commit' : '/delete'), state.romaneio, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

};

const mutations = {
  SET_ROMANEIOS (state, romaneio) {
    state.all = romaneio
  },
  SET_ALL_ROMANEIOS (state, romaneio) {
    state.allList = romaneio
  },
  SET_ITENS_SEM_ROMANEIOS (state, itens) {
    state.listItensSemRomaneio = itens
  },
  SET_ROMANEIO (state, romaneio) {
    state.romaneio = romaneio
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
