/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Funcionario} from "../../../models/cadastro/funcionario/funcionario";
import {FuncionarioSCH} from "../../../search/FuncionarioSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/funcionario';

const state = {
  all: [],
  allList: [],
  funcionario: new Funcionario(),
  filtro: new Filtro()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaFuncionario: (state) => {
    return state.allList
  },
  listaFuncionarioSemFiltro: (state) => {
    return state.all
  },
  pegaFuncionario: (state) => {
    return state.funcionario
  },
};

const actions = {
  searchFuncionario({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['funcionarios.nome', 'funcionarios.logrado','funcionarios.bairro',
        'funcionarios.limite_desconto', 'aux_municipios.nome'],'%'+search+'%','ilike')
  },

  async getFuncionario({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('funcionario_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_FUNCIONARIO', list.data[0]);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', FuncionarioSCH(perPage))
  },
  async setAllListFuncionarios({commit}) {
    try {
      const list = (await lista(url + '/all')).data;
      commit('SET_ALL_LISTA', list);
      EventBus.$emit('ALL_LISTA', true)
    } catch (error) {
      EventBus.$emit('ALL_LISTA', false)
    }
  },
  async setAllFuncionariosByEmpresa({commit}) {
    try {
      const list = (await pegaDados(url+'/allByEmpresa', {empresa_id: sessionStorage.getItem('empresa_id')})).data;
      commit('SET_ALL_LISTA', list);
      EventBus.$emit('ALL_LISTA', true)
    } catch (error) {
      EventBus.$emit('ALL_LISTA', false)
    }
  },
  async setAllMotoristas({commit}, {onSucesso}) {
    try {
      const list = (await pegaDados(url+'/allMotoristas', {empresa_id: sessionStorage.getItem('empresa_id')})).data;
      commit('SET_ALL_LISTA', list);
      onSucesso()
    } catch (error) {
    }
  },
  async setAllFuncionario ({ commit }, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_FUNCIONARIOS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  setFuncionario ({ commit }, funcionario) {
    commit('SET_FUNCIONARIO', funcionario)
  },

  async processarFuncionario ({ commit },  {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.funcionario, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      // this.dispatch('funcionario/setAllFuncionario')
      state.funcionario = new Funcionario();
      onSucesso()
    } catch (error) {
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  // COMISSAO
  removeFuncionarioComissao ({ commit }, endereco) {
    commit('REMOVE_FUNC_COM', endereco)
  },
  alteraFuncionarioComissao ({ commit }, endereco) {
    commit('ALTERA_FUNC_COM', endereco)
  },
  adicionaFuncionarioComissao ({ commit }, endereco) {
    commit('ADICIONA_FUNC_COM', endereco)
  },

// ACESSIBILIDADE
  removeAcessibilidade ({ commit }, endereco) {
    commit('REMOVE_FUNC_ACESS', endereco)
  },
  alteraAcessibilidade ({ commit }, endereco) {
    commit('ALTERA_FUNC_ACESS', endereco)
  },
  adicionaAcessibilidade ({ commit }, endereco) {
    commit('ADICIONA_FUNC_ACESS', endereco)
  },



// EMPRESA
  removeEmpresa ({ commit }, endereco) {
    commit('REMOVE_FUNC_EMPRESA', endereco)
  },
  alteraEmpresa ({ commit }, endereco) {
    commit('ALTERA_FUNC_EMPRESA', endereco)
  },
  adicionaEmpresa ({ commit }, endereco) {
    commit('ADICIONA_FUNC_EMPRESA', endereco)
  },

};

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_ALL_LISTA (state, funcionario) {
    state.allList = funcionario
  },
  SET_FUNCIONARIOS (state, funcionario) {
    state.all = funcionario
  },

  SET_FUNCIONARIO (state, funcionario) {
    state.funcionario = funcionario
  },

  // COMISSAO
  REMOVE_FUNC_COM (state, funcionarios_comissao){
    let pos = state.funcionario.funcionarios_comissao.indexOf(funcionarios_comissao);
    state.funcionario.funcionarios_comissao.splice(pos, 1)
  },
  ALTERA_FUNC_COM (state, funcionarios_comissao){
    let array = state.funcionario.funcionarios_comissao.filter(lista => lista.funcionario_comissao_id === funcionarios_comissao.funcionario_comissao_id);
    let pos = state.funcionario.funcionarios_comissao.indexOf(array[0]);
    state.funcionario.funcionarios_comissao.splice(pos, 1, funcionarios_comissao)
  },
  ADICIONA_FUNC_COM (state, funcionarios_comissao){
    state.funcionario.funcionarios_comissao.splice(state.funcionario.funcionarios_comissao.length, 1, funcionarios_comissao)
  },

  // ACESSIBILIDADE
  REMOVE_FUNC_ACESS (state, acessibilidade){
    let pos = state.funcionario.funcionarios_acessibilidade.indexOf(acessibilidade);
    state.funcionario.funcionarios_acessibilidade.splice(pos, 1)
  },
  ALTERA_FUNC_ACESS (state, acessibilidade){

    let array = state.funcionario.funcionarios_acessibilidade.filter(lista => lista.funcionario_acessibilidade_id === acessibilidade.funcionario_acessibilidade_id);
    let pos = state.funcionario.funcionarios_acessibilidade.indexOf(array[0]);
    state.funcionario.funcionarios_acessibilidade.splice(pos, 1, acessibilidade)
  },
  ADICIONA_FUNC_ACESS (state, acessibilidade){
    state.funcionario.funcionarios_acessibilidade.splice(state.funcionario.funcionarios_acessibilidade.length, 1, acessibilidade)
  },

  // EMPRESA
  REMOVE_FUNC_EMPRESA (state, Empresa){
    let pos = state.funcionario.funcionarios_empresas.indexOf(Empresa);
    state.funcionario.funcionarios_empresas.splice(pos, 1)
  },
  ALTERA_FUNC_EMPRESA (state, Empresa){
    let array = state.funcionario.funcionarios_empresas.filter(lista => lista.funcionario_empresa_id === Empresa.funcionario_empresa_id);
    let pos = state.funcionario.funcionarios_empresas.indexOf(array[0]);
    state.funcionario.funcionarios_empresas.splice(pos, 1, Empresa)
  },
  ADICIONA_FUNC_EMPRESA (state, Empresa){
    state.funcionario.funcionarios_empresas.splice(state.funcionario.funcionarios_empresas.length, 1, Empresa)
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
