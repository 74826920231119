/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const SintegraSCH = (perPage)=> {
    let filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['descricao'];
    filtro.addOrder('descricao', 'asc');
  return filtro
};
