/* eslint-disable */

import {pegaDados} from "../../../services/GenericAPI";
import {HistoricoProdutoSCH} from "../../../search/HistoricoProdutoSCH";

const url = '/historico_produto';

const state = {
  all: [],
  allMelhores: [],
  filtro: []
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAllHistProd: (state) => {
    return state.all
  },
  listaallMelhores: (state) => {
    return state.allMelhores
  },
};
const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', HistoricoProdutoSCH(perPage))
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descricao' ],
      '%'+search+'%',
      'ilike'
    )
  },

  limpaListas({commit}) {
    commit('SET_HISTORICO_PRODUTO', []);
    commit('SET_MELHORES_FORNECEDORES', [])
  },

  async getMelhores({commit}, id) {
    try {
      const list = (await pegaDados(url + '/get_melhores',{produto_id: id})).data;
      commit('SET_MELHORES_FORNECEDORES', list)
    } catch (error) {
    }
  },
  async setAllHistProd({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get',state.filtro)).data;
      commit('SET_HISTORICO_PRODUTO', list);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
};
const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_HISTORICO_PRODUTO(state, historico) {
    state.all = historico
  },
  SET_MELHORES_FORNECEDORES(state, allMelhores) {
    state.allMelhores = allMelhores
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
