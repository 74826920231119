/* eslint-disable */
import {NotaValores} from "./nota_valores";
import {v4 as uuidV4} from 'uuid';
import {NotaNfeDadosComp} from "./nota_nfe_dados_comp";
import {DFe} from "../../models/movimentacao/dfe";

export class Notas {
  constructor() {
    this.nota_id = uuidV4();
    this.serie = '';
    this.numero = '';
    this.modelo_id = '';
    this.ch_acesso = '';
    this.dt_emissao = '';
    this.is_emitida = false;
    this.observacao = '';
    this.empresa_id = '';
    this.tp_emitente = '';
    this.is_entregar = false;
    this.finnfe = '';
    this.is_cancelada = false;
    this.funcionario_id = '';
    this.participante_id = '';
    this.dt_entrada_saida = '';
    this.notas_itens = [];
    this.notas_duplicatas = [];
    this.notas_formas_pagamento = [];
    this.notas_valores = new NotaValores(this.nota_id);
    this.notas_entregas = [];
    this.notas_doc_referente = [];
    this.notas_nfe_dados_comp = new NotaNfeDadosComp(this.nota_id);
    this.notas_dfe = new DFe();
    this.notas_cli_avulso = []
  }
}
