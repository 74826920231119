/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EmpresasConfigNfce {
  constructor (empresa_id) {
    this.empresa_config_nfce_id = uuidV4();
    this.empresa_id = empresa_id;

    this.dt_contingencia = '';
    this.hr_contingencia = '';
    this.token_csc = '';
    this.id_csc = '';
    this.motivo_contingencia = '';
    this.is_producao = false;
    this.is_contingencia = false
  }
}
