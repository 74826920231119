/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Cfop {
  constructor () {
    this.cfop_id = uuidV4();
    this.cfop_id_ref = '';
    this.cfop = '';
    this.plano_conta_id = '';
    this.descricao = '';
    this.tp_movto = '';
    this.descricao_completa = '';
    this.pis_cofins = false;
    this.venda = false;
    this.compra = false;
    this.difal = false;
    this.transporte = false
  }
}
