/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  MovEventoCaixa{
  constructor() {
    this.mov_eventos_caixa_id= uuidV4();
    this.funcionario_id = '';
    this.evento_caixa_id = '';
    this.descricao = '';
    this.dt_evento = '';
    this.vl_evento = '';
    this.observacao = ''
  }
}
