/* eslint-disable */
const uf = [
  {uf_id: 1, sigla: 'AC', nome: 'Acre', codigo:'12'},
  {uf_id: 2, sigla: 'AL', nome: 'Alagoas', codigo:'27'},
  {uf_id: 3, sigla: 'AM', nome: 'Amazonas', codigo:'13'},
  {uf_id: 4, sigla: 'AP', nome: 'Amapá', codigo:'16'},
  {uf_id: 5, sigla: 'BA', nome: 'Bahia', codigo:'29'},
  {uf_id: 6, sigla: 'CE', nome: 'Ceará', codigo:'23'},
  {uf_id: 7, sigla: 'DF', nome: 'Distrito Federal', codigo:'53'},
  {uf_id: 8, sigla: 'ES', nome: 'Espírito Santo', codigo:'32'},
  {uf_id: 9, sigla: 'GO', nome: 'Goiás', codigo:'52'},
  {uf_id: 10, sigla: 'MA', nome: 'Maranhão', codigo:'21'},
  {uf_id: 11, sigla: 'MG', nome: 'Minas Gerais', codigo:'31'},
  {uf_id: 12, sigla: 'MS', nome: 'Mato Grosso do Sul', codigo:'50'},
  {uf_id: 13, sigla: 'MT', nome: 'Mato Grosso', codigo:'51'},
  {uf_id: 14, sigla: 'PA', nome: 'Pará', codigo:'15'},
  {uf_id: 15, sigla: 'PB', nome: 'Paraíba', codigo:'25'},
  {uf_id: 16, sigla: 'PE', nome: 'Pernambuco', codigo:'26'},
  {uf_id: 17, sigla: 'PI', nome: 'Piauí', codigo:'22'},
  {uf_id: 18, sigla: 'PR', nome: 'Paraná', codigo:'41'},
  {uf_id: 19, sigla: 'RJ', nome: 'Rio de Janeiro', codigo:'33'},
  {uf_id: 20, sigla: 'RN', nome: 'Rio Grande do Norte', codigo:'24'},
  {uf_id: 21, sigla: 'RO', nome: 'Rondônia', codigo:'11'},
  {uf_id: 22, sigla: 'RR', nome: 'Roraima', codigo:'14'},
  {uf_id: 23, sigla: 'RS', nome: 'Rio Grande do Sul', codigo:'43'},
  {uf_id: 24, sigla: 'SC', nome: 'Santa Catarina', codigo:'42'},
  {uf_id: 25, sigla: 'SE', nome: 'Sergipe', codigo:'28'},
  {uf_id: 26, sigla: 'SP', nome: 'São Paulo', codigo:'35'},
  {uf_id: 27, sigla: 'TO', nome: 'Tocantins', codigo:'17'}
];
export default {
  getUf() {
    return uf
  },
  buscarUfCodigo(sigla) {
    return uf.find(list => list.sigla === sigla)
  }
}
