/* eslint-disable */
export default {
  data: () => ({
    fixed: true,
    user: [],
    spinner2: false,
    valid: false,
    name: '',
    usuarioAutenticado: null,
    varias_empresas: false,
    nameRules: [
      v => !!v || 'Digite o usuario',
      v => v.length <= 3 || 'O nome deve ter mais de 3 letra'
    ],
    senha: '',
    senhaRules: [
      v => !!v || 'Digite a senha',
      v => v.length <= 3 || 'A senha deve ter mais de 3 letra'
    ]
  }),
  methods: {
    logar() {

    },
    setarSemana(usuario) {
      let d = new Date();
      let hora = this.zeroEsquerda(d.getHours(), 2) + '' + this.zeroEsquerda(d.getMinutes(), 2);
      let semana = [
        {dia_semana: 1, descricao: 'Domingo'},
        {dia_semana: 2, descricao: 'Segunda-Feira'},
        {dia_semana: 3, descricao: 'Terça-Feira'},
        {dia_semana: 4, descricao: 'Quarta-Feira'},
        {dia_semana: 5, descricao: 'Quinta-Feira'},
        {dia_semana: 6, descricao: 'Sexta-Feira'},
        {dia_semana: 7, descricao: 'Sábado'},
      ];

      let dia_semana = semana[d.getDay()].dia_semana;

      return {
        username: this.retorno(this.validarCampo(usuario.username), true, usuario.username, ""),
        senha: this.retorno(this.validarCampo(usuario.senha), true, usuario.senha, ""),
        acessibilidade: {dia: dia_semana, hora: hora}
      };
    },
    efetuaLogin(usuario, tipo = 1) {
      this.spinner2 = true;

      let array = this.setarSemana(usuario);
      // this.autenticarUsuario(array)
      this.autenticarUsuario({
        usuario: array,
        onSucesso: (usuario, existe) => {
          this.spinner2 = false;
          if (tipo === 1) {
            if (this.reloadPage) {
              document.location.reload(true)
            } else {
              this.setarToken(sessionStorage.getItem('token'), sessionStorage.getItem('funcionario_id'))
            }
            this.user.username = '';
            this.user.senha = '';
            this.fechaModal()

          } else {
            this.setEmpresaByFuncionario({
              funcionario: sessionStorage.getItem('funcionario_id'),
              onSucesso: () => {
                if (usuario.empresas.length >= 2) {
                  this.varias_empresas = true
                } else {
                  let empresa = this.listaAllEmpresaFunc.filter(list => list.empresa_id === usuario.empresas[0].empresa_id);
                  if (empresa.length > 0) {
                    this.dadosSessao(empresa[0])
                  }
                  this.confirmar()
                }
              }
            });
            if (!existe) {
              let tema = new Tema(usuario.funcionario_id);
              tema.cor = '#000000';
              tema.opacidade = 0.8;
              this.setTema(tema)
            } else {
              this.setTema(usuario.original[0])
            }

          }
          },
        onErro: () => {
          this.spinner2 = false;
        },
      })
    },
    PressEnter(senha, usuario,tipo=1) {
      let _this = this;
      let user = document.getElementById('usuario');
      let senhaId = document.getElementById('senha');

      user.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(usuario)) {
            user.focus()
          } else if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            _this.efetuaLogin(_this.user,tipo)
          }
        }
      }, false);

      senhaId.addEventListener('keypress', function (e) {
        if (e.keyCode === 13 || e.which === 13) {
          if (!_this.validarCampo(senha)) {
            senhaId.focus()
          } else {
            if (!_this.validarCampo(usuario)) {
              user.focus()
            } else {
              _this.efetuaLogin(_this.user,tipo)
            }
          }
        }
      }, false)
    },

  },
  mounted(){
    this.user = {
      username: "",
      senha: "",
      acessibilidade: {dia: '', hora: ''}
    }
  }
}
