/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";
export const CfopSCH = (perPage)=> {
    let filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['cfops.descricao', 'cfop', 'cfops.plano_conta_id', 'tp_movto', 'pis_cofins', 'venda',
      'compra', 'difal', 'transporte', 'plano_contas.descricao as plano_desc'];
    filtro.join.addLeft('plano_contas','plano_contas.plano_conta_id','cfops.plano_conta_id');

  return filtro
};
