/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  NotaValores{
  constructor(notaId) {
    this.nota_valor_id= uuidV4();
    this.nota_id = notaId;
    this.vl_bc_icms = 0;
    this.vl_icms = 0;
    this.vl_bc_ipi = 0;
    this.vl_ipi = 0;
    this.vl_bc_st = 0;
    this.vl_st = 0;
    this.vl_nota = 0;
    this.vl_desconto = 0;
    this.vl_icms_frete = 0;
    this.vl_ipi_frete = 0;
    this.vl_frete = 0;
    this.vl_seguro = 0;
    this.vl_icms_outras = 0;
    this.vl_outras = 0;
    this.vl_ipi_outras = 0;
    this.vl_fcp = 0;
    this.vl_uf_destino = 0;
    this.vl_uf_origem = 0;
    this.vl_ipi_devolvido = 0
  }
}
