<template>
    <pro-modal-cadastro :modal="renovarLoginModal" width="30%" titulo="Renovar usuário">
        <pro-alerta :texto="info" :cor="cor" :show="mostrar" @abriAlerta="fecharAlerta"></pro-alerta>
        <pro-container-form form_id="renovar_login">
            <v-col cols="12">
                <v-text-field name="usuario" dense outlined class="inp" v-model="user.username" label="Usuário"
                                id="usuario" prepend-inner-icon="mdi-account"
                                v-on:keyup="PressEnter(user.senha, user.username)" required></v-text-field>

                <v-text-field name="senha" dense outlined class="inp" v-model="user.senha" label="Senha"
                                prepend-inner-icon="mdi-lock" type="password" id="senha"
                                v-on:keyup="PressEnter(user.senha, user.username)" required></v-text-field>
                <div style="text-align: right;">
                    <v-btn text @click="fechaModal()" color="gray" style="margin-right: 14px;" :disabled="pegaTimeCount<=0"> Cancelar</v-btn>
                    <v-btn @click="efetuaLogin(user)" color="primary" :loading="spinner2">Entrar</v-btn>
                </div>
            </v-col>
        </pro-container-form>
    </pro-modal-cadastro>
</template>

<script>
  /* eslint-disable */
  import {EventBus} from '../../helpers/event-bus'
  import miscMixin from "../../helpers/misc"
  import miscLogin from "./misc_login";
  import {mapActions, mapGetters, mapState} from "vuex"

  export default {
  mixins: [miscMixin,miscLogin],
  data: () => ({
    info: '',
    cor: '',
    mostrar: false,
    intervalo: null,
  }),

  methods: {
    ...mapActions('usuario', ['autenticarUsuario']),
    ...mapActions('geral', ['setModal', 'toggleDialogError', 'toggleSnackSuccess', 'setRenovarLogin', 'setShowRenovarLoginMsg', 'setReloadPageRenovarLogin']),
    ...mapActions('token', ['setarToken']),

    fechaModal() {
        this.setShowRenovarLoginMsg(true);
        this.setReloadPageRenovarLogin(true);
        this.setRenovarLogin(false)
    },

    alerta(text, cor, show) {
      let _this = this;
      this.info = text;
      this.cor = cor;
      this.mostrar = show;
      this.intervalo = setTimeout(function() {
        _this.mostrar = false
      }, 3500)
    },
    fecharAlerta(fechar) {
      clearTimeout(this.intervalo);
      this.mostrar = fechar
    },
  },
  computed: {
    ...mapGetters('token', ['pegaTimeCount']),
    ...mapState('geral', {
      dialogError: 'dialogError',
      objMsgError: 'objMsgError',
      snackSuccess: 'snackSuccess',
      msgSuccess: 'msgSuccess',
      renovarLoginModal: 'renovarLoginModal',
      showMsg: 'showRenovarLoginMsg',
      reloadPage: 'reloadPageRenovarLogin'
    }),
  },
  mounted() {
  },
  watch: {
    'renovarLoginModal': function() {
      if(this.showMsg && this.renovarLoginModal === true){
        this.fecharAlerta(false);
        this.alerta('Sua sessão expirou. Faça o login novamente.', 'orange', true)
      }
    }
  }
}
</script>
