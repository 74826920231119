import {Rascunho} from "../models/rascunho";
import {acaoApi} from "./enums";
import {mapActions} from "vuex";
import {EventBus} from '../helpers/event-bus'

export default {
  data: () => ({
    interval_rasc: '',
    showProgressRasc: false,
  }),
  methods: {
    ...mapActions('rascunho', ['processarRascunho2','setRascunho','getRascunho']),
    ...mapActions('produto', ['setProduto']),
    ...mapActions('nota', ['setNota']),

    novoRascunho(rotina= ''){
      let  rascunho = new Rascunho();
      rascunho.rotina_id =rotina;
      rascunho.rascunho =  JSON.stringify([]);
      return rascunho
    },
    gerarNovoRascunho2(rotina,item){
      let novo = this.novoRascunho(rotina);
      novo.rascunho = JSON.stringify(item );
      this.setRascunho(novo);
      this.ProcessandoRascunho(novo,acaoApi.GRAVAR, novo,3)
    },

    finalizarInverval(){
      if (this.validarCampo(sessionStorage.getItem('interval_rasc'))) {
        clearInterval(JSON.parse(sessionStorage.getItem('interval_rasc')))
      }
    },

    gravarRascunho(rascunho, item){
      this.finalizarInverval();
      let _this = this;
      let rasc = this.cloneObj(rascunho);
      let interval = setInterval(function () {
        if (_this.validarCampo(rasc)) {
          rasc.rascunho = JSON.stringify(item)
        }

        if(_this.verificarHash(JSON.stringify(rasc))) {
          _this.processarRascunho2({
            rascunho: rasc,
            acao: acaoApi.GRAVAR
          });
          let hash = _this.criarHash(JSON.stringify(rasc));
          sessionStorage.setItem('hash_rasc', hash)
        }else {
        }
      }, 15*1000);
      sessionStorage.setItem('interval_rasc', JSON.stringify(interval))

    },

    ProcessandoRascunho(rascunho, acao, novo, tipo=1){
      let hash = '';

      if (tipo === 1 ) {
        if (this.validarCampo(rascunho)) {
          hash = this.criarHash(JSON.stringify(rascunho));
          sessionStorage.setItem('hash_rasc', hash);
          this.processarRascunho2({
            rascunho: rascunho,
            acao: acao
          })
        }
        this.setRascunho(novo);
        this.finalizarInverval()
      } else if(tipo === 2){
        hash = this.criarHash(JSON.stringify(rascunho));
        sessionStorage.setItem('hash_rasc', hash);
        this.processarRascunho2({
          rascunho: rascunho,
          acao: acao
        });
        this.setRascunho(novo)
      } else {
        hash = this.criarHash(JSON.stringify(novo));
        sessionStorage.setItem('hash_rasc', hash);
        this.setRascunho(novo);
        this.processarRascunho2({
          rascunho: novo,
          acao: acao
        })
      }

    },

    criarHash(str=''){
      let md5 = require('md5');
      return md5(str);
    },

    verificarHash( item){
      let md5 = require('md5');
      let hash = sessionStorage.getItem('hash_rasc');
      let novo = md5(item);
      return hash !== novo
    },
    gerarNovoRascunho(rotina,item,old,rascunho,evento='', gravar=false){
      let novo = this.novoRascunho(rotina);
      novo.rascunho = JSON.stringify(item );
      this.setRascunho(novo);
      this.ProcessandoRascunho(old,acaoApi.GRAVAR, novo,3);
      if (gravar){
        this.gravarRascunho(novo ,item )
      }
      if (this.validarCampo(evento)) {
        this.showProgressRasc = false;
        EventBus.$emit(evento,true)
      }
    },
  }
}
