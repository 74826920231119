/* eslint-disable */
const state = {
  tab: '',
  modal: false,
  isDarkTheme: JSON.parse(sessionStorage.getItem('isDarkTheme')) || false,
  search: '',
  btnAdd: false,
  chktema: true,
  clipped: false,
  swatches: '#000000',
  sidebarId: 'principal',
  show_home: false,
  isNotPform: true,
  msgSuccess: '',
  background: '',
  showFilter: false,
  checkboxImg: 1,
  modalfiltro: false,
  disabledTab: false,
  toolbarsave: false,
  miniVariant: false,
  toolbarDash: null,
  dialogError: false,
  treinamento: false,
  objMsgError: null,
  btnDownload: false,
  snackSuccess: false,
  sidebarColor: 'rgba(0, 2, 20, 0.8)',
  toolbarsearch: false,
  arquivo_morto: false,
  showOrcamento: false,
  show_arq_morto: false,
  btnDesdobramento: false,
  renovarLoginModal: false,
  showRenovarLoginMsg: true,
  reloadPageRenovarLogin: true,

  disableField: JSON.parse(sessionStorage.getItem('disableField')) || false,
  editavel: JSON.parse(sessionStorage.getItem('editavel')) || false,
  adicionavel: JSON.parse( sessionStorage.getItem('adicionavel')) || false,
  removivel: JSON.parse(sessionStorage.getItem('removivel')) || false,
  consultavel:  JSON.parse(sessionStorage.getItem('consultavel')) || false,
  btn_adicionavel: JSON.parse(sessionStorage.getItem('btn_adicionavel')) || false,
};

const getters = {
  getShowHome: state => {
    return state.show_home
  },
  getDisableField: state => {
    return state.disableField
  },
  pegarEditavel: state => {

    return state.editavel
  },
  pegarAdicionavel: state => {
    return state.adicionavel
  },
  pegarRemovivel: state => {
    return state.removivel
  },
  pegarConsultavel: state => {
    return state.consultavel
  },
  btnAdd: state => {
    return state.btnAdd
  },
  getTreinamento: state => {
    return state.treinamento
  },
  pegarTab: state => {
    return state.tab
  },

  pegarDisabledTab: state => {
    return state.disabledTab
  },

  getSearch: state => {
    return state.search
  },
  arquivo_morto: state => {
    return state.arquivo_morto
  },
  show_arq_morto: state => {
    return state.show_arq_morto
  },
  getIsDark: state => {
    return state.isDarkTheme
  },
};

const actions = {
  setIsDark({ commit }) {
    sessionStorage.setItem('isDarkTheme', !state.isDarkTheme);
    commit('SET_ISDARK', !state.isDarkTheme)
  },
  setBtnAdicionavel({ commit }, valor) {
    sessionStorage.setItem('btn_adicionavel', !valor);
    commit('SET_BTN_ADICIONAVEL', !valor)
  },
  setAdicionavel({commit}, {valor}) {
    commit('SET_ADICIONAVEL', !valor);
    sessionStorage.setItem('adicionavel', !valor)
  },
  setRemovivel({commit}, {valor}) {
    commit('SET_REMOVIVEL', !valor);
    sessionStorage.setItem('removivel', !valor)
  },
  setConsultavel({commit}, {valor}) {
    commit('SET_CONSULTAVEL', !valor);
    sessionStorage.setItem('consultavel', !valor)
  },
  setEditavel({commit}, {valor}) {
    commit('SET_EDITAVEL', !valor);
    sessionStorage.setItem('editavel', !valor)
  },
  setRenovarLogin({commit}, valor) {
    commit('SET_RENOVAR_LOGIN', valor)
  },
  setShowRenovarLoginMsg({commit}, valor) {
    commit('SET_SHOW_RENOVAR_LOGIN_MSG', valor)
  },
  setReloadPageRenovarLogin({commit}, valor) {
    commit('SET_RELOADPAGE_RENOVAR_LOGIN', valor)
  },
  setClipped({commit}, valor) {
    commit('SET_Cliped', valor)
  },
  setShowHome({commit}, valor) {
    commit('SET_SHOW_HOME', valor)
  },
  setDisableField({commit}, valor) {
    sessionStorage.setItem('disableField', valor);
    commit('SET_DESABILITAR_CAMPO', valor)
  },
  setIsNotPform({commit}, valor) {
    commit('SET_IS_NOT_PFORM', valor)
  },
  setAdd({commit}, valor) {
    commit('SET_ADD', valor)
  },
  setShowOrcamento({commit}, valor) {
    commit('SET_SHOW_ORCAMENTO', valor)
  },
  setShowFilter({commit}, valor) {
    commit('SET_SHOWFILTER', valor)
  },
  setModal({commit}, valor) {
    commit('SET_MODAL', valor)
  },
  setDisabledTab({commit}, valor) {
    commit('SET_DISABLED_TAB', valor)
  },
  setToolbarSearch({commit}, valor) {
    commit('SET_TOOLBARSEARCH', valor)
  },
  toggleArquivoMorto ({ commit }) {
    commit('SET_ARQUIVO_MORTO', !state.arquivo_morto)
  },
  setShowArqMorto ({ commit }, valor) {
    commit('SET_SHOW_ARQ_MORTO', valor)
  },
  setToolbarSave({commit}, valor) {
    commit('SET_TOOLBARSAVE', valor)
  },
  setSidebarID({commit}, valor) {
    commit('SET_SIDEBARID', valor)
  },
  setModalFiltro({commit}, valor) {
    commit('SET_MODALFILTRO', valor)
  },
  toggleModalFiltro({commit}) {
    commit('SET_MODALFILTRO', !state.modalfiltro)
  },
  setMiniVariant({commit}, valor) {
    commit('SET_MINIVARIANT', valor)
  },
  toggleMiniVariant({commit}) {
    commit('SET_MINIVARIANT', !state.miniVariant)
  },
  setSidebarColor({commit}, valor) {
    commit('SET_SIDEBARCOLOR', valor)
  },
  setSearch({commit}, valor) {
      commit('SET_SEARCH', valor)
  },
  setToolbarDash({commit}, valor) {
    commit('SET_TOOLBARDASH', valor)
  },
  toggleDialogError({commit}) {
    commit('SET_DIALOGERROR', !state.dialogError)
  },
  setTreinamento({commit}, valor) {
    commit('SET_TREINAMENTO', valor)
  },
  setDialogError({commit}, valor) {
    commit('SET_DIALOGERROR', valor)
  },
  setObjMsgError({commit}, valor) {
    commit('SET_OBJMSGERROR', valor);
    commit('SET_DIALOGERROR', true)
  },
  toggleSnackSuccess({commit}) {
    commit('SET_SNACKSUCCESS', !state.snackSuccess)
  },
  setMsgSuccess({commit}, valor) {
    commit('SET_MSGSUCCESS', valor);
    commit('SET_SNACKSUCCESS', true)
  },
  setBtnDownload({commit}, valor) {
    commit('SET_BTNDOWNLOAD', valor)
  },
  setTab({commit}, valor) {
    commit('SET_TAB', valor)
  },
  setBackground({commit}, valor) {
    commit('SET_BACKGROUND', valor)
  },
  setCheckBoxImg({commit}, valor) {
    commit('SET_CHECKBOXIMG', valor)
  },
  setChkTema({commit}, valor) {
    commit('SET_CHKTEMA', valor)
  },
  setSwatches({commit}, valor) {
    commit('SET_SWATCHES', valor)
  },
  setBtnDesdobramento({commit}, valor) {
    commit('SET_BTN_DESDOBRAMENTO', valor)
  }
};

const mutations = {
  SET_ISDARK (state, payload) {
    state.isDarkTheme = payload
  },
  SET_RENOVAR_LOGIN (state, payload) {
    state.renovarLoginModal = payload
  },
  SET_SHOW_RENOVAR_LOGIN_MSG (state, payload) {
    state.showRenovarLoginMsg = payload
  },
  SET_RELOADPAGE_RENOVAR_LOGIN (state, payload) {
    state.reloadPageRenovarLogin = payload
  },
  SET_IS_NOT_PFORM (state, payload) {
    state.isNotPform = payload
  },
  SET_ARQUIVO_MORTO (state, payload) {
    state.arquivo_morto = payload
  },
  SET_SHOW_ARQ_MORTO (state, payload) {
    state.show_arq_morto = payload
  },
  SET_BTN_ADICIONAVEL(state, payload) {
    state.btn_adicionavel = payload
  },
  SET_ADICIONAVEL(state, payload) {
    state.adicionavel = payload
  },
  SET_SHOW_HOME(state, payload) {
    state.show_home = payload
  },
  SET_REMOVIVEL(state, payload) {
    state.removivel = payload
  },
  SET_CONSULTAVEL(state, payload) {
    state.consultavel = payload
  },
  SET_EDITAVEL(state, payload) {

    state.editavel = payload
  },
  SET_DESABILITAR_CAMPO(state, payload) {
    state.disableField = payload
  },

  SET_ADD(state, payload) {
    state.btnAdd = payload
  },
  SET_SHOW_ORCAMENTO(state, payload) {
    state.showOrcamento = payload
  },
  SET_SHOWFILTER(state, payload) {
    state.showFilter = payload
  },
  SET_MODAL(state, payload) {
    state.modal = payload
  },
  SET_DISABLED_TAB(state, payload) {
    state.disabledTab = payload
  },
  SET_TOOLBARSEARCH(state, payload) {
    state.toolbarsearch = payload
  },
  SET_TOOLBARSAVE(state, payload) {
    state.toolbarsave = payload
  },
  SET_SIDEBARID(state, payload) {
    state.sidebarId = payload
  },
  SET_MODALFILTRO(state, payload) {
    state.modalfiltro = payload
  },
  SET_MINIVARIANT(state, payload) {
    state.miniVariant = payload
  },
  SET_SIDEBARCOLOR(state, payload) {
    state.sidebarColor = payload
  },
  SET_SEARCH(state, payload) {
    state.search = payload
  },
  SET_TOOLBARDASH(state, payload) {
    state.toolbarDash = payload
  },
  SET_DIALOGERROR(state, payload) {
    state.dialogError = payload
  },
  SET_TREINAMENTO(state, payload) {
    state.treinamento = payload
  },
  SET_OBJMSGERROR(state, payload) {
    state.objMsgError = payload
  },
  SET_SNACKSUCCESS(state, payload) {
    state.snackSuccess = payload
  },
  SET_MSGSUCCESS(state, payload) {
    state.msgSuccess = payload
  },
  SET_BTNDOWNLOAD(state, payload) {
    state.btnDownload = payload
  },
  SET_TAB(state, payload) {
    state.tab = payload
  },
  SET_BACKGROUND(state, payload) {
    state.background = payload
  },
  SET_CHECKBOXIMG(state, payload) {
    state.checkboxImg = payload
  },
  SET_CHKTEMA(state, payload) {
    state.chktema = payload
  },
  SET_SWATCHES(state, payload) {
    state.swatches = payload
  },
  SET_BTN_DESDOBRAMENTO(state, payload) {
    state.btnDesdobramento = payload
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
