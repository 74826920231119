export class DFe {
  constructor() {
    this.dfe_id = '';
    this.nota_id = '';
    this.is_producao = false;
    this.chave = '';
    this.status = '';
    this.dt_doc = '';
    this.email = '';
    this.qrcode = ''
  }
}
