/* eslint-disable */
import {pegaDados, pegaItem, pegaLista, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Cfop} from "../../../models/cadastro/cfop";
import {CfopSCH} from "../../../search/CfopSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/cfop';

const state = {
  cfop: new Cfop(),
  all: [],
  allList: [],
  filtro: new Filtro()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaCfop: (state) => {
    let lista = state.allList;
    for (let i = 0; i < lista.length; i++) {
      lista[i].descricao = lista[i].cfop + ' - ' + lista[i].descricao
    }
    return lista
  },
  listaCfopItens: (state) => {
    let lista = state.allList;
    for (let i = 0; i < lista.length; i++) {
      lista[i].descricao = lista[i].cfop + ' - ' + lista[i].descricao
    }
    return lista
  },
  listaCfopSemFiltro: (state) => {
    return state.all
  },
  listaCfopReferencia: (state) => {
    const lista = state.all.data;
    for (let i = 0; i < lista.length; i++) {
      lista[i].descricao = lista[i].cfop + ' - ' + lista[i].descricao
    }
    return lista
  },
  pegaCfop: (state) => {
    return state.cfop
  },
  pegaCfopRef: (state) => (id) => {
    let item = state.allList.find(cfop => cfop.cfop_id === id);
    return item !== null ? item : new Cfop()
  },
};

const actions = {
  searchCfop({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(
      ['cfops.descricao', 'cfops.cfop', 'cfops.plano_conta_id', 'tp_movto', 'pis_cofins', 'venda',
        'compra', 'difal', 'transporte', 'plano_contas.descricao'], '%' + search + '%', 'ilike')
  },
  async getCfop({commit}, {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('cfop_id', id, '=');
      const list = (await pegaDados(url+'/get', filtro)).data;
      commit('SET_CFOP', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CfopSCH(perPage))
  },

  async getById({commit}, {cfop, onSucesso}) {
    const list = (await pegaItem(url + '/getById', {cfop: cfop})).data;
    onSucesso(list[0])
  },

  async setAllListCfop({commit}, {filtro, onSucesso, onErro}) {
    try {
      let list = [];
      list = (await pegaLista(url + '/lista', filtro)).data;
      commit('SET_ALL_CFOPS', list);
      EventBus.$emit('CFOPS_PROCESSADO', true)
      onSucesso();

    } catch (error) {
      console.log(error);
      EventBus.$emit('CFOPS_PROCESSADO', false)
      onErro();
    }
  },

  async setAllCfop({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CFOPS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },
  async processarCfop({commit}, {cfop, acao,onSucesso,onErro}) {
    try {
      let res = await processar(url, cfop, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_CFOPS(state, cfop) {
    state.all = cfop
  },
  SET_CFOP(state, cfop) {
    state.cfop = cfop
  },
  SET_ALL_CFOPS(state, cfop) {
    state.allList = cfop
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
