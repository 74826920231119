/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  Frete{
  constructor() {
    this.frete_id= uuidV4();
    this.participante_id = '';
    this.modelo_id = '';
    this.cfop_id = '';
    this.numero = '';
    this.fretes_notas_referentes = [];
    this.modal = '';
    this.serie = '';
    this.dt_emissao = '';
    this.vl_frete = 0;
    this.vl_base_icms = 0;
    this.vl_icms = 0;
    this.aliq_icms = 0;
    this.chave_cte = 0;
    this.empresa_id = 0
  }
}
