import { render, staticRenderFns } from "./ModalCadastro.vue?vue&type=template&id=66b3054c&"
import script from "./ModalCadastro.vue?vue&type=script&async=true&lang=js&"
export * from "./ModalCadastro.vue?vue&type=script&async=true&lang=js&"
import style0 from "./ModalCadastro.vue?vue&type=style&index=0&id=66b3054c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports