import {hasOnlyDigits, isValidEmail, isValidURL, removeMascaraCampoNumerico, validaCnpjCpf, removeMascaraCampoAlfaNumerico} from './misc'

export default {
  methods: {
    /* eslint-disable no-console */
    buildRules(obj) {
      const regras = obj.validation.split('|');
      for (let i = 0; i < regras.length; i++) {
        const regra = regras[i].split(':');
        switch (regra[0]) {
          case 'counter':
            obj.rulesValidation.push(v => v.length <= regra[1] || 'Max ' + regra[1] + ' characters');
            break;
          case 'required':
            obj.classes += ' requerido2';
            obj.rulesValidation.push(v => !!v || 'Obrigatório.');
            break;
          case 'min':
            obj.rulesValidation.push(v => (v === '' || (v || '').length >= regra[1]) || 'Mínimo ' + regra[1] + ' caracteres');
            break;
          case 'max':
            obj.rulesValidation.push(v => (v === '' || (v || '').length <= regra[1]) || 'Máximo ' + regra[1] + ' caracteres');
            break;
          case 'size':
            obj.rulesValidation.push(v => (v === '' || (v || '').length !== Number(regra[1])) || 'O campo precisa ter ' + regra[1] + ' caracteres');
            break;
          case 'cep':
            obj.rulesValidation.push(v => (v === '' || (removeMascaraCampoNumerico(v) || '').length === 8) || 'CEP precisa ter 8 caracteres');
            obj.rulesValidation.push(v => (v === '' || hasOnlyDigits(removeMascaraCampoNumerico(v) || '')) || 'CEP com conteudo inválido');
            break;
          case 'fone':
            obj.rulesValidation.push(v => (v === '' || ((removeMascaraCampoNumerico(v) || '').length >= 10 && (removeMascaraCampoNumerico(v) || '').length <= 11)) || 'FONE deve ter 10 ou 11 dígitos');
            obj.rulesValidation.push(v => (v === '' || hasOnlyDigits(removeMascaraCampoNumerico(v) || '')) || 'FONE com conteudo inválido');
            break;
          case 'email':
            obj.convertTo = 'L'; // lowerCase
            obj.rulesValidation.push(v => (v === '' || isValidEmail(v || '')) || 'EMAIL inválido');
            break;
          case 'site':
            obj.convertTo = 'L'; // lowerCase
            obj.rulesValidation.push(v => (v === '' || isValidURL(v || '')) || 'SITE inválido');
            break;
          case 'cnpj_cpf':
            obj.rulesValidation.push(v => (v === '' || ((removeMascaraCampoNumerico(v) || '').length === 11 || (removeMascaraCampoNumerico(v) || '').length === 14)) || 'Total de dígitos inválido');
            obj.rulesValidation.push(v => (v === '' || validaCnpjCpf(v) || '') || 'CNPJ/CPF inválido');
            break;
          case 'cpf':
            obj.rulesValidation.push(v => (v === '' || (removeMascaraCampoNumerico(v) || '').length === 11) || 'Total de dígitos inválido');
            obj.rulesValidation.push(v => (v === '' || validaCnpjCpf(v) || '') || 'CPF inválido');
            break;
          case 'cnpj':
            obj.rulesValidation.push(v => (v === '' || (removeMascaraCampoNumerico(v) || '').length === 14) || 'Total de dígitos inválido');
            obj.rulesValidation.push(v => (v === '' || validaCnpjCpf(v) || '') || 'CNPJ inválido');
            break;
          case 'date':
            obj.pInputType = 'date';
            // setei o tipo aqui para me dar opção de data. Favor conferir e testar pra ver se é isso msm.
            break;
          case 'number':
            obj.pInputType = 'number';
            obj.rulesValidation.push(v => (v === '' || hasOnlyDigits(removeMascaraCampoNumerico(v) || '')) || 'Campo número com conteudo inválido');
            // setei o tipo aqui para me dar opção de somente numeros. Favor conferir e testar pra ver se é isso msm.
            break;
          case 'percent':
            // Aqui já vamos tratar o percentual, colocar o sufixo "%" e tralet pra ser maior que 0 e menor ou igual a 100.
            break;
          case 'password':
            obj.convertTo = 'N'; // nada
            obj.pInputType = 'password';
            break;
          case 'time':
            obj.pInputType = 'time';
            break;
          case 'cfop':
            break;
          case 'placa':
            obj.rulesValidation.push(v => (v === '' || (removeMascaraCampoAlfaNumerico(v) || '').length === 7) || 'PLACA precisa ter 7 caracteres');
            break
        }
      }
    }
    // OK - Sites tem que passar tudo minusculo
    // OK - CEP tem que descontar a mascara na hora de validar
    // OK - Criar opção pra aceitar somente números no campo CEP, CPF, CNPJ, FONE, entre outros.
    // OK - Embutido o textarea aqui pra facilitar.
    // OK - Tratar as mascaras de acordo comcada campo.
    // OK - No p-currency tem que arrumar um jeito de passar sufix, prefix (caso não passar o defalt é "R$") e tbem tem que poder definir o numero de casas decimais.
    // OK - A classe padrão quando n é "required" é a "padrao" tem que corrigir por que quando não tem "required" tá ficando cinza o opjeto.

    /* eslint-enable no-console */
  }
}
