/* eslint-disable */
import {Notas} from '../../../models/movimentacao/notas'
import {lista, pegaDados, pegaItem, processar, processaReport, processarPost} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
// import {NotasRascunho} from '../../../models/movimentacao/notas_rascunho'
import {NotaNfeDadosComp} from '../../../models/movimentacao/nota_nfe_dados_comp'
import {Filtro} from '../../../models/filtro/filtro'
import {CancelarNotaSCH, NotaEntradaSCH, NotaRequisicaoSCH, VendaSCH} from "../../../search/VendaSCH";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/nota';

const state = {
  all: [],
  vl_nota: 0,
  vl_pago: 0,
  proximo: '',
  vl_total: 0,
  disabled_tabs: true,
  vl_troco: 0,
  allNotas: [],
  vl_entrada: 0,
  vl_subtotal: 0,
  vl_restante: 0,
  // allRascunho: [],
  vl_total_itens: 0,
  doc_participante_id: '',
  editando_nota: false,
  nota: new Notas(),
  filtro: new Filtro(),
  nota_cancelar: new Notas(),
  // rascunho: new NotasRascunho(),

};

const getters = {
  getDisabledTabs: (state) => {
    return state.disabled_tabs
  },
  getEditando: (state) => {
    return state.editando_nota
  },
  getVl_nota: (state) => {
    return state.vl_nota
  },
  getVl_Pago: (state) => {
    return state.vl_pago
  },
  getVl_Total: (state) => {
    return state.vl_total
  },
  getSubTotal: (state) => {
    return state.vl_subtotal
  },
  getVl_Troco: (state) => {
    return state.vl_troco
  },
  getVl_Restante: (state) => {
    return state.vl_restante
  },
  getvl_total_itens: (state) => {
    return state.vl_total_itens
  },
  listaVendas: (state) => {
    return state.all
  },
  pegarNota: (state) => {
    return state.nota
  },
  // pegaRascunho: (state) => {
  //   return state.rascunho
  // },
  // listRascunho: (state) => {
  //   return state.allRascunho
  // },
  listAllNotas: (state) => {
    return state.allNotas
  },
  getProximo: (state) => {
    return state.proximo
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};
const actions = {
  setdoc_participante_id({commit}, doc_participante_id) {
    commit('SET_DOC_PART', doc_participante_id)
  },
  setDisabledTabs({commit}, disabled_tabs) {
    commit('SET_DISABLED', disabled_tabs)
  },
  setNota({commit}, nota) {
    commit('SET_NOTA', nota)
  },
  // setRascunho({commit}, rascunho) {
  //   commit('SET_RASCUNHO', rascunho)
  // },
  setEditando({commit}, editando) {
    commit('SET_EDITANDO', editando)
  },
  setFiltroCancelar({commit}, perPage) {
    commit('SET_FILTRO', CancelarNotaSCH(perPage))
  },
  setFiltroEntrada({commit}, perPage) {
    commit('SET_FILTRO', NotaEntradaSCH(perPage))
  },
  setFiltroRequisicao({commit}, perPage) {
    commit('SET_FILTRO', NotaRequisicaoSCH(perPage))
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', VendaSCH(perPage))
  },
  setVl_Entrada({commit}, vl_entrada) {
    commit('SET_VL_ENTRADA', vl_entrada)
  },
  setVl_total_itens({commit}, vl_total_itens) {
    commit('SET_VL_TOTAL_ITENS', vl_total_itens)
  },
  setVl_nota({commit}, nota) {
    commit('SET_VL_NOTA', nota)
  },
  setVl_Restante({commit}, valor) {
    commit('SET_VL_RESTANTE', valor)
  },
  setVl_Pago({commit}, valor) {
    commit('SET_VL_PAGO', valor)
  },
  setVl_Total({commit}, valor) {
    commit('SET_VL_TOTAL', valor)
  },
  setSubTotal({commit}, valor) {
    commit('SET_SUBTOTAL', valor)
  },
  setVl_Troco({commit}, valor) {
    commit('SET_VL_TROCO', valor)
  },

  searchNota({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['serie', 'numero', 'vl_nota'], '%' + search + '%', 'ilike')
  },


  searchVenda({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['numero', 'nome', 'vl_nota','funcionario','serie', 'modelo_id'], '%' + search + '%', 'ilike')
  },

  searchRequisicao({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['notas.numero', 'participantes.nome', 'vl_nota','funcionarios.nome'], '%' + search + '%', 'ilike')
  },

  async setAllListNotas({commit}) {
    const list = (await pegaDados(url + '/lista_notas',{empresa_id: sessionStorage.getItem('empresa_id')})).data;
    commit('SET_ALL_LISTA', list)
  },

  async setAllListNotasByEmpresa({commit}) {
    const list = (await pegaDados(url + '/lista_all_notas_by_empresa',{empresa_id: sessionStorage.getItem('empresa_id')})).data;
    commit('SET_ALL_LISTA', list)
  },

  async setListNotasConpleto({commit}) {
    const list = (await lista(url + '/lista_all_notas')).data;
    commit('SET_ALL_LISTA', list)
  },

  // async setAllRascunho({commit}) {
  //   const list = (await lista(url + '/get_all_rascunho')).data;
  //   commit('SET_ALL_RASCUNHO', list);
  //   EventBus.$emit('GET_ALL_RASCUNHO', true)
  // },

  async setAllNotaReqquisicao({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      commit('SET_VENDAS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },

  async setAllNota({commit},path) {
    try {
      const list = (await pegaDados(url + '/' + path, state.filtro)).data;
      commit('SET_VENDAS', list);
      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async setAllNotaP({commit},{path, onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/' + path, state.filtro)).data;
      commit('SET_VENDAS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },

  LimparCampos({commit}) {
    commit('SET_VL_TOTAL_ITENS', 0);
    commit('SET_VL_NOTA', 0);
    commit('SET_VL_RESTANTE', 0);
    commit('SET_VL_PAGO', 0);
    commit('SET_VL_TOTAL', 0);
    commit('SET_VL_TROCO', 0)
  },
  async importarXml({commit}, {file, onSucesso, onError}) {
    try {
      const list = (await pegaDados(url + '/importar_xml', file)).data;
      onSucesso(list[0])
    } catch (error) {
      onError()
    }
  },

  async validarEstoque({commit}, {produto, onSucesso}) {
    try {
      produto.modelo_id = state.nota.modelo_id;
      const res = (await pegaDados(url + '/validar_estoque', produto)).data;
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async verificaEstoque({commit}, {produto, onSucesso}) {
    try {
      const res = (await pegaDados(url + '/verifica_estoque', produto)).data;
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async getNota({commit}, id) {
    const filtro = getBaseSCH('nota_id', id, '=');
    const res = (await pegaDados(url + '/get', filtro)).data;
    commit('SET_NOTA', res.data[0]);
    EventBus.$emit('GET_NOTA_IMPRIMIR', true)
  },

  async getNotaRef({commit}, {id,onSucesso,onErro}) {
    try {
    const filtro = getBaseSCH('nota_id', id, '=');
    const res = (await pegaDados(url + '/get', filtro)).data;
      onSucesso(res.data[0])
    } catch (error) {
      onErro()
    }
  },

  async getUltimaNota({commit}, {filtro, onSucesso, onErro}) {
    try {
      const res = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_NOTA', res.data[0]);
      onSucesso(res.data[0])
    } catch (error) {
      onErro()
    }
  },

  async getNotaCancelada({commit}, id) {
    let filtro = getBaseSCH('nota_id', id, '=');
    const res = (await pegaDados(url + '/get', filtro)).data;
    commit('SET_NOTA', res.data[0]);
    EventBus.$emit('GET_NOTA_CANCELAR', true)
  },

  async getNextId({commit}) {
    let res = await pegaItem(url + '/get_next_id', {nota_id: state.nota.nota_id});
    commit('GET_NEXT_ID', res.data)
  },

  async enviarContingencia({commit}, id) {
    try{
      const res = (await pegaDados(url + '/enviar_contingencia', {nota_id: id, empresa_id: sessionStorage.getItem('empresa_id')})).data;
      this.dispatch('geral/setMsgSuccess', 'Registro processado com sucesso');
      this.dispatch('notas/setAllNota','get_view')
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async emitirOrcamento({commit, rootState}, {dados, onSucesso, onErro}) {
    try {
      let retorno;
      retorno = await processaReport(url + '/emite_pedido', dados);
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      onErro()
    }
  },
  async emitirPedido({commit, rootState}, {dados, onSucesso, onErro}) {
    try {
      let retorno;
      if (rootState.geral.treinamento) {
        retorno = await processaReport(url + '/emite_pedido', dados)
      } else {
        retorno = await processaReport(url + '/emite_dfe', dados)
      }
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async emitirDFe({commit}, {dados, onSucesso, onErro}) {
    try {
      const retorno = await processaReport(url + '/emite_dfe', dados);
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  processarExtras({commit}, {item, acao, tipo}) {
    if (acao === 'gravar') {
      commit('ADICIONAR_' + tipo, item)
    } else if (acao === 'alterar') {
      commit('ALTERAR_' + tipo, item)
    } else if (acao === 'remover') {
      commit('REMOVER_' + tipo, item)
    }
  },
  async processarVendaSimples({commit}, {acao, onSucesso, onErro}) {
    try {
      state.nota.finnfe = 1;
      state.nota.tp_emitente = '0';
      if (state.nota.modelo_id === 'PE') {
        state.nota.serie = sessionStorage.getItem('serie_pedido')
      } else if (state.nota.modelo_id === '65') {
        state.nota.serie = sessionStorage.getItem('serie_nfce')
      }
      if (state.nota.notas_nfe_dados_comp === null) {
        state.nota.notas_nfe_dados_comp = new NotaNfeDadosComp(state.nota.nota_id)
      }
      state.nota.notas_nfe_dados_comp.tp_presenca_id = 1;
      state.nota.notas_nfe_dados_comp.tp_frete_id = 9;
      state.nota.notas_nfe_dados_comp.is_consumidor_final = true;
      state.nota.funcionario_id = sessionStorage.getItem('funcionario_id');
      console.log(state.nota);
      let res = await processar(url, state.nota, acao);
      let tudoCerto = true;
      if (state.nota.modelo_id === '65' && !res.data.success) {
        // aqui quando for dfe e o success for false, significa q deu problema na validação
        tudoCerto = false;
        this.dispatch('geral/setObjMsgError', res.data)
      } else {
        this.dispatch('token/renovarToken', res.data);
        this.dispatch('geral/setMsgSuccess', res.data.message)
      }

      onSucesso(tudoCerto)
    } catch (error) {
      console.log(error);
      state.nota.notas_formas_pagamento = [];

      this.dispatch('geral/setObjMsgError', error.response.data);

      onErro()
    }
  },

  async processar({commit}, {dados, acao, onSucesso, onErro, msgSucesso = true}) {
    await processarPost(this, dados, url + acao.url, onSucesso, onErro, msgSucesso)
  },

  async processarVenda({commit, rootState}, {acao, onSucesso, onErro}) {
    try {
      state.nota.tp_emitente = '0';
      state.nota.empresa_id = sessionStorage.getItem('empresa_id');
      state.nota.funcionario_id = sessionStorage.getItem('funcionario_id');

      state.nota.serie = sessionStorage.getItem(rootState.geral.treinamento ? 'serie_pedido' : 'serie_nfe');

      // console.log('NOTAS PROPRIAS: ',state.nota);

      let res = await processar(url, state.nota, acao);
      let tudoCerto = true;

      if (!res.data.success) {
        // aqui quando for dfe e o success for false, significa q deu problema na validação
        tudoCerto = false;
        this.dispatch('geral/setObjMsgError', res.data)
      } else {
        if (acao!== 'remover') {
          this.dispatch('token/renovarToken', res.data)
        }
        this.dispatch('geral/setMsgSuccess', res.data.message)
      }

      onSucesso(tudoCerto);
      if (state.nota.is_entregar) {
        EventBus.$emit('NOTA_ENTREGA_CONCLUIDO', true)
      }
    } catch (error) {
      onErro();
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      if (state.nota.is_entregar) {
        EventBus.$emit('NOTA_ENTREGA_CONCLUIDO', false)
      }
    }
  },
  async processarRequisicao({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.nota, acao);
      this.dispatch('token/renovarToken', res.data);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  async processarNotaEntrada({commit}, {acao, onSucesso, onErro}) {
    try {
      state.nota.tp_emitente = '1';
      state.nota.finnfe = 1;
      state.nota.empresa_id = sessionStorage.getItem('empresa_id');
      state.nota.funcionario_id = sessionStorage.getItem('funcionario_id');
      // console.log('NotaEntrada:', state.nota);

      let res = await processar(url, state.nota, acao);
      this.dispatch('token/renovarToken', res.data);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso();
      this.dispatch('notas/setAllNota','get_view')
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  async processarNota({commit}, acao) {
    try {
      let res = await processar(url, state.nota, acao);

      // console.log('PRCESSSAR NOTA: ',state.nota);

      let tudoCerto = true;

      if (!res.data.success) {
        // aqui quando for dfe e o success for false, significa q deu problema na validação
        tudoCerto = false;
        this.dispatch('geral/setObjMsgError', res.data);
        EventBus.$emit('LISTA_PROCESSADA', false)
      } else {
        this.dispatch('geral/setMsgSuccess', res.data.message);
        this.dispatch('token/renovarToken', res.data);
        EventBus.$emit('LISTA_PROCESSADA', true)
      }
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async processarBaixa({commit}, {item, acao, path}) {
    try {
      let res = await processar(path, item, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('conta_receber/setAllContaReceber');
      EventBus.$emit('LISTA_PROCESSADA', true,)
    } catch (error) {
      console.log('error: ', error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
};
const mutations = {
  SET_DISABLED(state, valor) {
    state.disabled_tabs = valor
  },
  SET_DOC_PART(state, valor) {
    state.doc_participante_id = valor
  },
  SET_FILTRO(state, valor) {
    state.filtro = valor
  },
  SET_EDITANDO(state, editando) {
    state.editando_nota = editando
  },
  SET_VL_ENTRADA(state, valor) {
    state.vl_entrada = valor
  },
  SET_VL_RESTANTE(state, valor) {
    state.vl_restante = valor
  },
  SET_VL_PAGO(state, valor) {
    state.vl_pago = valor
  },
  SET_VL_TOTAL(state, valor) {
    state.vl_total = valor
  },
  SET_VL_TROCO(state, valor) {
    state.vl_troco = valor
  },
  SET_VL_TOTAL_ITENS(state, vl_total_itens) {
    state.vl_total_itens = vl_total_itens
  },
  SET_VL_NOTA(state, vl_nota) {
    state.vl_nota = vl_nota
  },
  SET_SUBTOTAL(state, vl_subtotal) {
    state.vl_subtotal = vl_subtotal
  },

  SET_VENDAS(state, nota) {
    state.all = nota
  },
  GET_NEXT_ID(state, proximo) {
    state.proximo = proximo
  },
  SET_ALL_LISTA(state, rascunho) {
    state.allNotas = rascunho
  },
  // SET_RASCUNHO(state, rascunho) {
  //   state.rascunho = rascunho
  // },
  // SET_ALL_RASCUNHO(state, rascunho) {
  //   state.allRascunho = rascunho
  // },
  SET_NOTA(state, item) {
    state.nota = item
  },

// NOTAS DUPLICATAS

  ALTERAR_NOTAS_DUPLICATAS(state, item) {
    let c = state.nota.notas_duplicatas.find(lista => lista.nota_duplicata_id === item.nota_duplicata_id);
    let pos = state.nota.notas_duplicatas.indexOf(c);
    state.nota.notas_duplicatas.splice(pos, 1, item)
  },
  REMOVER_NOTAS_DUPLICATAS(state, item) {
    let pos = state.nota.notas_duplicatas.indexOf(item);
    state.nota.notas_duplicatas.splice(pos, 1)
  },
  ADICIONAR_NOTAS_DUPLICATAS(state, item) {
    state.nota.notas_duplicatas.splice(state.nota.notas_duplicatas.length, 1, item)
  },

// NOTAS FORMA_PAGAMENTO
  ALTERAR_NOTAS_FORMA_PAGAMENTO(state, item) {
    let c = state.nota.notas_formas_pagamento.find(lista => lista.nota_cartao_id === item.nota_cartao_id);
    let pos = state.nota.notas_formas_pagamento.indexOf(c);
    state.nota.notas_formas_pagamento.splice(pos, 1, item)
  },
  REMOVER_NOTAS_FORMA_PAGAMENTO(state, item) {
    let pos = state.nota.notas_formas_pagamento.indexOf(item);
    state.nota.notas_formas_pagamento.splice(pos, 1)
  },
  ADICIONAR_NOTAS_FORMA_PAGAMENTO(state, item) {
    state.nota.notas_formas_pagamento.push(item)
  },
  LIMPAR_FORMAS_PAGAMENTO(state) {
    state.nota.notas_formas_pagamento = []
  },

// NOTAS NFE DADOS COMP
  ALTERAR_NOTAS_DOC_REFERENTE(state, item) {
    let c = state.nota.notas_doc_referente.find(lista => lista.notas_doc_referente_id === item.notas_doc_referente_id);
    let pos = state.nota.notas_doc_referente.indexOf(c);
    state.nota.notas_doc_referente.splice(pos, 1, item)
  },
  REMOVER_NOTAS_DOC_REFERENTE(state, item) {
    let pos = state.nota.notas_doc_referente.indexOf(item);
    state.nota.notas_doc_referente.splice(pos, 1)
  },
  ADICIONAR_NOTAS_DOC_REFERENTE(state, item) {
    state.nota.notas_doc_referente.splice(state.nota.notas_doc_referente.length, 1, item)
  },

// NOTAS ITENS
  ALTERAR_NOTAS_ITENS(state, item) {
    let c = state.nota.notas_itens.find(lista => lista.nota_item_id === item.nota_item_id);
    let pos = state.nota.notas_itens.indexOf(c);
    state.nota.notas_itens.splice(pos, 1, item);
    EventBus.$emit('PROCESSA_NOTA_ITEM', true,)

  },
  REMOVER_NOTAS_ITENS(state, item) {
    let pos = state.nota.notas_itens.indexOf(item);
    state.nota.notas_itens.splice(pos, 1);
    EventBus.$emit('PROCESSA_NOTA_ITEM', true,)

  },
  ADICIONAR_NOTAS_ITENS(state, item) {
    state.nota.notas_itens.splice(state.nota.notas_itens.length, 1, item);
    EventBus.$emit('PROCESSA_NOTA_ITEM', true,)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
