/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const VendaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.filters.addFilter('empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('tp_emitente', 0, '=');
  filtro.addOrder('dt_emissao', 'desc');
  return filtro
};

export const NotaEntradaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.filters.addFilter('tp_emitente', 1, '=');
  filtro.filters.addFilter('empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.addOrder('dt_emissao', 'desc');
  return filtro
};

export const NotaRequisicaoSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['notas.numero','dt_emissao','notas.empresa_id','modelo_id','tp_emitente','tp_movimento', 'vl_nota',
    'participantes.nome', 'funcionarios.nome as funcionario'];
  filtro.join.addLeft('notas_itens', 'notas_itens.nota_id', 'notas.nota_id');
  filtro.join.addLeft('notas_valores', 'notas_valores.nota_id', 'notas.nota_id');
  filtro.join.addLeft('participantes', 'participantes.participante_id', 'notas.participante_id');
  filtro.join.addLeft('funcionarios', 'funcionarios.funcionario_id', 'notas.funcionario_id');

  filtro.filters.addFilter('tp_emitente', 0, '=');
  filtro.filters.addFilter('notas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('modelo_id', 'RQ', '=');
  filtro.addOrder('dt_emissao', 'desc');
  return filtro
};

export const UltimaNotaSCH = () => {
  let filtro = new Filtro();
  const modelo_id = sessionStorage.getItem('modelo_id');
  const serie = sessionStorage.getItem(modelo_id === "65" ? 'serie_nfce' : 'serie_pedido');
  const empresa_id = sessionStorage.getItem("empresa_id");
  filtro.filters.addFilter('notas.empresa_id', empresa_id, '=');
  filtro.filters.addFilter('notas.serie', serie, '=');
  filtro.filters.addFilter('notas.modelo_id', modelo_id, '=');
  filtro.addOrder('notas.numero', 'desc');
  filtro.perPage = 1;
  filtro.page = 1;
  return filtro
};

export const CancelarNotaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['notas_valores.vl_nota', 'notas.modelo_id', 'notas.serie', 'notas.numero', 'notas.dt_emissao',
    'notas_nfce.status',];
  filtro.filters.addFilter('notas.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.filters.addFilter('tp_movimento', 'S', '=');
  filtro.filters.addFilter('notas_nfce.status', 'A', '=');
  filtro.filters.addFilter('notas.modelo_id', '65', '=');
  filtro.join.addLeft('notas_nfce', 'notas_nfce.nota_id', 'notas.nota_id');
  filtro.join.addLeft('notas_valores', 'notas_valores.nota_id', 'notas.nota_id');
  return filtro
};
