/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const MelhoresFornecedoresSCH = ()=> {
  let filtro = new Filtro();
  filtro.perPage = 1;
  filtro.fields = ['produtos.produto_id','produtos.codigo', 'produtos_empresas.pr_venda'];
  filtro.join.addLeft('produtos_empresas', 'produtos_empresas.produto_id','produtos.produto_id');
  filtro.addOrder('pr_venda','desc');
  return filtro
};

export const HistoricoProdutoSCH = (perPage)=> {
  let filtro = {
    perPage: perPage,
    empresa_id: sessionStorage.getItem('empresa_id'),
    produto_id: '',
    dt_ini: '',
    dt_fim: '',
    page: 1
  };

  return filtro
};

