/* eslint-disable */
import {pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Configuracao} from "../../../models/configuracao/configuracao"
import {Filtro} from "../../../models/filtro/filtro";
import {BaseSCH} from "../../../search/BaseSCH";

const url = '/configuracao';

const state = {
  configuracao: new Configuracao(),
  filtro: new Filtro()
};

const getters = {
  pegaConfiguracao: (state) => {
      return state.configuracao !== null && state.configuracao !== undefined ? state.configuracao : new Configuracao()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BaseSCH(perPage))
  },
  setConfiguracao({commit}, novo) {
    commit('SET_CONFIGURACAO', novo)
  },
  async setAllConfiguracoes ({ commit }) {
    try {
      const list = (await pegaDados(url+ '/get', state.filtro)).data;
      if (list.data[0] !== null && list.data[0] !== undefined) {
        commit('SET_CONFIGURACAO', list.data[0])
      } else {
        commit('SET_CONFIGURACAO', new Configuracao())
      }

      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }

  },
  async processarParametro ({ commit }, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.configuracao, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('paramentros/setAllConfiguracoes');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_CONFIGURACAO (state, configuracoes) {
    state.configuracao = configuracoes
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
