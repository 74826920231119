/* eslint-disable */
export const acaoApi = Object.freeze({
  GRAVAR: 'gravar',
  REMOVER: 'remover'
});

export const acaoApiNotas = Object.freeze({
  STATUS_DFE: {url: '/status_dfe', name: 'status'},
  CANCELAR: {url: '/cancela', name: 'cancelar'},
  INUTILIZAR_DFE: {url: '/inutiliza_dfe', name: 'inutilizar'},
  DOWNLOAD_XML: {url: '/download_xml', name: 'download'},
  ERRO_DFE: {url: '/erro_dfe', name: 'erro'}
});

