/* eslint-disable */

import {pegaDados} from "../../../services/GenericAPI";
import {HistoricoClienteSCH} from "../../../search/HistoricoClienteSCH";

const url = '/historico_cliente';

const state = {
  all: [],
  filtro: []
};

const getters = {

  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAllHistCliente: (state) => {
    return state.all
  },
};
const actions = {
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', HistoricoClienteSCH(perPage))
  },
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['numero','vl_duplicata' ],
      '%'+search+'%',
      'ilike'
    )
  },

  setAllHistoricoCliente({commit}) {
    commit('SET_HISTORICO_CLIENTE', [])
  },

  async setAllHistCliente({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get',state.filtro)).data;
      commit('SET_HISTORICO_CLIENTE', list);
      onSucesso()
    } catch (error) {
      onErro();
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async setTitulosAnalise({commit}, {participante_id,onSucesso}) {
    try {
      const list = (await pegaDados(url + '/get_analises',{participante_id: participante_id})).data;
      onSucesso(list)
    } catch (error) {
      console.log(error);
    }
  },
};
const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_HISTORICO_CLIENTE(state, historico) {
    state.all = historico
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
