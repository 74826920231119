/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class FuncionarioGrupoAcesso {
  constructor (funcionarioId) {
    this.funcionario_grupo_acesso_id = uuidV4();
    this.funcionario_id = funcionarioId;
    this.username = '';
    this.grp_acesso_id = ''
  }
}
