/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const FreteSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['numero', 'serie', 'dt_emissao', 'nome', 'fretes.participante_id'];
  filtro.addOrder('numero', 'asc');
  filtro.filters.addFilter('fretes.empresa_id', sessionStorage.getItem('empresa_id'), '=');
  filtro.join.addLeft('participantes', 'participantes.participante_id', 'fretes.participante_id');
  return filtro
};

