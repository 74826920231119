/* eslint-disable */

import {Filtro} from "../models/filtro/filtro";

export const HistoricoClienteSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  return filtro
};
