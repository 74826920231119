/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class  NotaNfeDadosComp{
  constructor(notaId) {
    this.nota_id = notaId;
    this.nota_nfe_dados_comp_id = uuidV4();
    this.nota_id = notaId;
    this.participante_id = '';
    this.veiculo_id = '';
    this.tp_frete_id = '';
    this.rntrc = '';
    this.volume = '';
    this.peso_lq = '';
    this.peso_bt = '';
    this.especie = '';
    this.marca = '';
    this.hr_saida = '';
    this.tp_presenca_id = '';
    this.is_consumidor_final = false;
    this.observacoes = ''
  }
}
