/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const CstSCH = (perPage)=> {
    let filtro = new Filtro();
    filtro.perPage = perPage;
    filtro.fields = ['csts_referencia.cst_icms_id', 'csts_referencia.cst_icms_id_referente',
      'aux_cst_icms.descricao', 'aux.descricao as desc_referente'];
    filtro.addOrder('aux_cst_icms.descricao', 'asc');
    filtro.join.addLeft('aux_cst_icms','aux_cst_icms.cst_icms_id','csts_referencia.cst_icms_id');
    filtro.join.addLeft('aux_cst_icms as aux','aux.cst_icms_id','csts_referencia.cst_icms_id_referente');
  return filtro
};
