/* eslint-disable */
const state = {
  // vl_itens:  '',
  vl_st: '',
  vl_icms: '',
  vl_frete: '',
  vl_seguro: '',
  vl_outras: '',
  vl_bc_icms: '',
  total_nota: '',
  vl_desconto: '',
  base_icms_st: '',
  vl_icms_frete: '',
  vl_icms_outras: '',
};

const getters = {
  getVl_St: (state) => {return state.vl_st},
  getVl_Icms: (state) => {return state.vl_icms},
  getVl_Frete: (state) => {return state.vl_frete},
  getVl_Seguro: (state) => {return state.vl_seguro},
  getVl_Outras: (state) => {return state.vl_outras},
  getVl_Bc_Icms: (state) => {return state.vl_bc_icms},
  getTotal_Nota: (state) => {return state.total_nota},
  getVl_Desconto: (state) => {return state.vl_desconto},
  getBase_Icms_St: (state) => {return state.base_icms_st},
  getVl_Icms_Frete: (state) => {return state.vl_icms_frete},
  getVl_Icms_Outras: (state) => {return state.vl_icms_outras},

};

const actions = {
  setVl_St({commit}, valor) { commit('SET_VL_ST', valor)},
  setVl_Icms({commit}, valor) { commit('SET_VL_ICMS', valor)},
  setVl_Frete({commit}, valor) { commit('SET_VL_FRETE', valor)},
  setVl_Seguro({commit}, valor) { commit('SET_VL_SEGURO', valor)},
  setVl_Outras({commit}, valor) { commit('SET_VL_OUTRAS', valor)},
  setVl_Bc_Icms({commit}, valor) { commit('SET_VL_BC_ST', valor)},
  setTotal_Nota({commit}, valor) { commit('SET_TOTAL_NOTA', valor)},
  setVl_Desconto({commit}, valor) { commit('SET_VL_DESCONTO', valor)},
  setBase_Icms_St({commit}, valor) { commit('SET_VL_ICMS_ST', valor)},
  setVl_Icms_Frete({commit}, valor) { commit('SET_VL_ICMS_FRETE', valor)},
  setVl_Icms_Outras({commit}, valor) { commit('SET_VL_ICMS_OUTRAS', valor)},

  ValorTotalNota() {
    let icms = parse.float(state.vl_icms) + parse.float(state.vl_st) + parse.float(state.base_icms_st) +
      parse.float(state.vl_icms_frete) + parse.float(state.vl_icms_outras) + parse.float(state.vl_bc_icms);
    let somatorio = parse.float(state.vl_frete) + parse.float(state.vl_seguro) + parse.float(state.vl_outras);
    let desconto = parse.float(state.vl_desconto);
    let total_nota = (parse.float(state.vl_itens) + icms + somatorio) - desconto;
    state.total_nota = parse.float(total_nota)
  }

};
const mutations = {
  SET_VL_ST(state, valor) {state.vl_st = valor},
  SET_VL_ICMS(state, valor) {state.vl_icms = valor},
  SET_VL_BC_ST(state, valor) {state.vl_bc_st = valor},
  SET_VL_FRETE(state, valor) {state.vl_frete = valor},
  SET_VL_SEGURO(state, valor) {state.vl_seguro = valor},
  SET_VL_OUTRAS(state, valor) {state.vl_outras = valor},
  SET_VL_ICMS_ST(state, valor) {state.vl_icms_st = valor},
  SET_TOTAL_NOTA(state, valor) {state.total_nota = valor},
  SET_VL_DESCONTO(state, valor) {state.vl_desconto = valor},
  SET_VL_ICMS_FRETE(state, valor) {state.vl_icms_frete = valor},
  SET_VL_ICMS_OUTRAS(state, valor) {state.vl_icms_outras = valor},
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
