/* eslint-disable */
import {pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Cst} from "../../../models/cadastro/cst";
import {CstSCH} from "../../../search/CstSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/cst_referencia';

const state = {
  all: [],
  filtro: new Filtro(),
  cst: new Cst()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaCst: (state) => {
    return state.all
  },
  pegaCst: (state) => (id) => {
    let item = state.all.find(cst => cst.cst_icms_id === id);
    return item !== null ? item : new Cst()
  },
};

const actions = {
  searchCst({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['csts_referencia.cst_icms_id', 'csts_referencia.cst_icms_id_referente',
        'aux_cst_icms.descricao', 'aux.descricao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getCst({commit}, id) {
    let filtro = getBaseSCH('cst_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_CST', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', CstSCH(perPage))
  },
  async setAllCst ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_CSTS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }
  },
  async processarCst ({ commit }, { cst, acao,onSucesso,onErro }) {
    try {
      let res = await processar(url, cst, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('cst/setAllCst');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_CSTS (state, cst) {
    state.all = cst
  },
  SET_CST (state, cst) {
    state.cst = cst
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
