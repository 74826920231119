/* eslint-disable */
import {LogAudit} from "../../models/log_audit";
import {processar} from "../../services/GenericAPI";
import {acaoApi} from "../../helpers/enums";

const url = '/log';
const state = {
  logs_audit: new LogAudit()
};
const getters = {
  pegaLog: (state) => {
    return state.logs_audit
  }
};
const actions = {
  setLog({commit}, log_audit) {
    commit('SET_LOG', log_audit)
  },
  async processarLog({commit}, {item, key}) {
    try {
      let logs = JSON.parse(JSON.stringify(state.logs_audit )); // cloneObj
      logs.chave_registro = item[key];
      logs.dados_registro = item;
      let res = await processar(url, logs, acaoApi.GRAVAR)
    } catch (error) {
      console.log(error)
    }
  }
};
const mutations = {
  SET_LOG(state, log) {
    state.logs_audit = log
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
