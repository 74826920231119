/* eslint-disable */
import {pegaDados, processar, processaReport} from '../../../services/GenericAPI'
import {NotaEntregas} from "../../../models/movimentacao/nota_entregas"
import {NotaEntrega2SCH, NotaEntregaItensSCH, NotaEntregaSCH, NotaRegistroItensSCH} from "../../../search/NotaEntregaSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";
import {NotaEntregaRegistro} from "../../../models/movimentacao/nota_entrega_registro";

const url = '/controle_entrega';

const state = {
  all: [],
  allListaItens: [],
  nota_entrega_registro: new NotaEntregaRegistro(),
  nota_entrega: new NotaEntregas(),
  filtro: new Filtro()
};

const getters = {
  listaNotaEntregas: (state) => {
    return state.all
  },
  listaNotaEntregasItens: (state) => {
    return state.allListaItens
  },
  pegaEntregaRegistro: (state) => {
    return state.nota_entrega_registro
  },
  pegaNotaEntregas: (state) => {
   return state.nota_entrega
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  async getItemEntrega({commit}, {id,onSucesso, onErro}) {
    try {
      // console.log('getItemEntrega');
      const filtro = NotaEntregaItensSCH('nota_id', id, '=');
      const res = (await pegaDados(url + '/get_registro_entrega_itens', filtro)).data;
      // console.log(res.data);
      onSucesso(res.data)
    } catch (error) {
      onErro()
    }
  },
  async getNotaEntregaItens({commit}, {id,onSucesso, onErro}) {
    try {
      const filtro = NotaRegistroItensSCH('notas_entregas_itens.nota_entrega_registro_id', id, '=');
      const res = (await pegaDados(url + '/get_registro_entrega_lista', filtro)).data;

      onSucesso(res.data)
    } catch (error) {
      onErro()
    }
  },
  async getRegistroEntrega({commit}, {id,onSucesso, onErro}) {
    try {
      const filtro = getBaseSCH('nota_entrega_registro_id', id, '=');
      const res = (await pegaDados(url + '/get_registro_entrega', filtro)).data;
      onSucesso(res.data[0])
    } catch (error) {
      onErro()
    }
  },
  async getEntrega({commit}, {id,onSucesso, onErro}) {
    try {
      const filtro = getBaseSCH('nota_entrega_id', id, '=');
      const res = (await pegaDados(url + '/get', filtro)).data;
      onSucesso(res.data[0])
    } catch (error) {
      onErro()
    }
  },
  async getHistorico({commit}, {id,onSucesso, onErro}) {
    try {
      const res = (await pegaDados(url + '/get_historico', {nota_entrega_id: id})).data;
      onSucesso(res)
    } catch (error) {
      onErro()
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', NotaEntrega2SCH(perPage))
  },
  setNotaEntrega({commit}, nota_entrega) {
    commit('SET_NOTAENTREGA', nota_entrega)
  },

  setNotaEntregaItens({commit}, nota_entrega) {
    commit('SET_NOTAENTREGA_ITENS', nota_entrega)
  },
  setNotaEntregaRegistro({commit}, nota_entrega_registro) {
    commit('SET_NOTA_ENTREGA_REGISTRO', nota_entrega_registro)
  },
  searchNotaEntregas({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['notas_entregas.nome','notas_entregas.numero'],
      '%'+search+'%',
      'ilike'
    )
  },
  async setAllNotaEntregas ({ commit },{onSucesso,onErro}) {
    try {
      // console.log(state.filtro);
      const list = (await pegaDados(url+'/get_view', state.filtro)).data;
      // console.log('SET_NOTASENTREGAS:', list);
      commit('SET_NOTASENTREGAS', list);
      onSucesso()
    } catch (error) {
      onErro()
    }

  },
  async gerarRomaneio ({ commit }, { registro_entrega, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url+'/gerar_romaneio', registro_entrega, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  async processarNotaEntregas ({ commit }, { registro_entrega, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url, registro_entrega, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  async processarRegistroEntrega ({ commit }, { entrega, acao, onSucesso, onErro }) {
    try {
      console.log('processarRegistroEntrega: ', entrega);
      let res = await processar(url + '/commit_registro', entrega, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },

  async imprimiNotaEntrega ({ commit }, { entrega, acao, onSucesso, onErro }) {
    try {
      const retorno = await processaReport(url + '/imprimir_recibo', entrega);
      onSucesso(retorno)
    } catch (error) {
      console.log(error);
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  async processarRomaneioBaixa ({ commit }, {item, acao, onSucesso, onErro }) {
    try {
      let res = await processar(url+  '/baixa_by_romaneio', item, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_NOTASENTREGAS (state, controles_entregas) {
    state.all = controles_entregas
  },
  SET_NOTAENTREGA (state, controles_entregas) {
    state.nota_entrega = controles_entregas
  },
  SET_NOTA_ENTREGA_REGISTRO (state, nota_entrega_registro) {
    state.nota_entrega_registro = nota_entrega_registro
  },
  SET_NOTAENTREGA_ITENS (state, allListaItens) {
    state.allListaItens = allListaItens
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
