/* eslint-disable */
import {Filtro} from "../models/filtro/filtro";

export const NotaEntregaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['notas_entregas.nome','notas_entregas.nota_id', 'notas_entregas.numero','notas_entregas.tipo',
    'notas_entregas.dt_baixa','notas.dt_emissao','notas.numero as num_nota',
    'notas_entregas.created_at'
  ];
  filtro.join.addLeft('notas', 'notas.nota_id', 'notas_entregas.nota_id');
  filtro.addOrder('notas_entregas.dt_baixa', 'desc');
  filtro.addOrder('notas.dt_emissao', 'desc');
  filtro.addOrder('notas_entregas.created_at', 'desc');
  return filtro
};

export const NotaEntrega2SCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.addOrder('tem_romaneio', 'asc');
  filtro.addOrder('dt_baixa', 'desc');
  filtro.addOrder('dt_emissao', 'desc');
  filtro.addOrder('hora', 'desc');
  return filtro
};

export const NotaEntregaItensSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = '*';
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.fields = ["*"];
  return filtro
};


export const NotaRegistroItensSCH = (campo, valor, condicao)=>{
  let filtro = new Filtro();
  filtro.perPage = '*';
  filtro.filters.addFilter(campo,valor,condicao);
  filtro.fields = [
    'notas_entregas.nota_id',
    'notas_itens.nota_item_id',
    'notas_itens.produto_id',
    'notas_itens.vl_item',
    'notas_entregas.nota_entrega_id',
    'notas_entregas.numero',
    'produtos.descricao',
    'notas_entregas_itens.dt_movimento',
    'notas_entregas_itens.qtd_movimento',
    'notas_entregas_itens.tp_movimento',
    'notas_entregas_itens.nota_entrega_registro_id',
    'notas_itens.quantidade'];

  filtro.join.addLeft('notas_entregas', 'notas_entregas.nota_id', 'notas_itens.nota_id');
  filtro.join.addLeft('produtos', 'produtos.produto_id', 'notas_itens.produto_id');
  filtro.join.addLeft('notas_entregas_itens', 'notas_entregas_itens.nota_item_id', 'notas_itens.nota_item_id');
  return filtro
};
