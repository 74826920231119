/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Veiculo} from "../../../models/cadastro/veiculo";
import {VeiculoSCH} from "../../../search/VeiculoSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/veiculo';

const state = {
  all: [],
  allList: [],
  filtro: new Filtro(),
  veiculo: new Veiculo()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaVeiculoSemFiltro: (state) => {
    return state.all
  },
  listaAllVeiculo: (state) => {
    return state.allList
  },
};

const actions = {
  searchVeiculo({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['placa', 'descricao', 'fabricante', 'cor', 'carga_maxima'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getVeiculo({commit}, id) {
    let filtro = getBaseSCH('veiculo_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_VEICULO', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', VeiculoSCH(perPage))
  },

  async setAllVeiculo ({ commit }, {onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_VEICULOS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },

  async setAllListVeiculo ({ commit },{onSucesso}) {
    try {
      const list = (await lista(url+'/lista')).data;
      commit('SET_ALL_VEICULOS', list);
      onSucesso()
    }   catch (error) {
      console.log(error);
    }

  },

  async processarVeiculo ({ commit }, { veiculo, acao }) {
    try {
      let res = await processar(url, veiculo, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('veiculo/setAllVeiculo');
      EventBus.$emit('VEICULO_PROCESSO_CONCLUIDO', true)
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      EventBus.$emit('VEICULO_PROCESSO_CONCLUIDO', false)
    }
  }
};

const mutations = {
  SET_VEICULOS (state, veiculo) {
    state.all = veiculo
  },
  SET_ALL_VEICULOS (state, veiculo) {
    state.allList = veiculo
  },
  SET_VEICULO (state, veiculo) {
    state.veiculo = veiculo
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
