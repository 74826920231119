/* eslint-disable */
import Vue from 'vue'
import cst from './modules/cadastro/cst'
import dfe from './modules/movimentacoes/dfes'
import Vuex from 'vuex'
import home from './modules/home'
import cfop from './modules/cadastro/cfop'
import notas from './modules/movimentacoes/Notas'
import frete from './modules/movimentacoes/fretes'
import geral from './modules/geral'
import token from './modules/token'
import marca from './modules/cadastro/marcas'
import grupo from './modules/cadastro/grupos'
import veiculo from './modules/cadastro/veiculos'
import usuario from './modules/usuario'
import produto from './modules/cadastro/produtos'
import empresa from './modules/cadastro/empresas'
import sintegra from './modules/outros/sintegra'
import auxiliar from './modules/aux_table/auxiliar'
import rascunho from './modules/rascunho'
import municipio from './modules/aux_table/municipios'
import duplicata from './modules/controles/duplicata'
import auditoria from './modules/outros/auditoria'
import logs_audit from './modules/LogsAudit'
import paramentros from './modules/configuracoes/parametro'
import plano_conta from './modules/cadastro/plano_conta'
import funcionario from './modules/cadastro/funcionarios'
import configuracao from './modules/configuracoes/configuracoes'
import mov_evento_caixa from './modules/movimentacoes/mov_evento_caixa'
import grupo_acesso from './modules/configuracoes/grupo_acesso'
import participante from './modules/cadastro/participantes'
import fechamento_notas from './modules/movimentacoes/fechamento_notas'
import resumo_gerencial from './modules/relatorios/movimentacao/resumo_gerencial'
import registro_inventario from './modules/relatorios/movimentacao/registro_inventario'
import eventos_caixa from './modules/cadastro/evento_caixa'
import operadora_cartao from './modules/cadastro/operadoras_cartoes'
import historico_cliente from './modules/consulta/historico_cliente'
import historico_produto from './modules/consulta/historico_produto'
import eventos_administrativo from './modules/cadastro/evento_administrativo'
import mov_evento_administrativo from './modules/movimentacoes/mov_evento_administrativo'
import romaneio from "./modules/controles/romaneio";
import notas_entregas from "./modules/controles/notas_entregas";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    cst,
    dfe,
    cfop,
    home,
    geral,
    frete,
    grupo,
    marca,
    token,
    notas,
    veiculo,
    produto,
    empresa,
    usuario,
    romaneio,
    sintegra,
    auxiliar,
    rascunho,
    duplicata,
    municipio,
    auditoria,
    logs_audit,
    paramentros,
    plano_conta,
    funcionario,
    configuracao,
    grupo_acesso,
    participante,
    eventos_caixa,
    mov_evento_caixa,
    operadora_cartao,
    resumo_gerencial,
    registro_inventario,
    fechamento_notas,
    historico_cliente,
    historico_produto,
    notas_entregas,
    eventos_administrativo,
    mov_evento_administrativo,
  }
})
