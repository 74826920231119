/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {OperadoraCartao} from "../../../models/cadastro/operadora_cartao"
import {Filtro} from "../../../models/filtro/filtro";
import {OperadoraCartaoSCH} from "../../../search/OperadoraCartaoSCH"
import {getBaseSCH} from "../../../search/BaseSCH";
import {getParticipanteAvulsoSCH} from "../../../search/ParticpanteSCH";

const url = '/operadora_cartao';

const state = {
  all: [],
  allListaCartao: [],
  allLista: [],
  operadora: new OperadoraCartao(),
  filtro: new Filtro(),

  tipo_operadora: [
    {tp_operadora_id: "D", descricao: "Débito", id: 'D'},
    {tp_operadora_id: "C", descricao: "Crédito", id: 'C'},
  ],
  tipo_desdobramento: [
    {tp_desdobramento_id: "01", descricao: "Nenhum(Dinheiro)", id: '01'},
    {tp_desdobramento_id: "03", descricao: "Cartão de Crédito", id: '03'},
    {tp_desdobramento_id: "04", descricao: "Cartão de Débito", id: '04'},
    {tp_desdobramento_id: "05", descricao: "Crediario", id: '05'},
    {tp_desdobramento_id: "10", descricao: "Vale Alimentação", id: '10'},
    {tp_desdobramento_id: "11", descricao: "Vale Refeição", id: '11'},
    {tp_desdobramento_id: "12", descricao: "Vale Presente", id: '12'},
    {tp_desdobramento_id: "13", descricao: "Vale Combustível", id: '13'},
    {tp_desdobramento_id: "15", descricao: "Boleto Bancário", id: '15'},
    {tp_desdobramento_id: "16", descricao: "Depósito Bancário", id: '16'},
    {tp_desdobramento_id: "17", descricao: "Pagamento Instantâneo (PIX)", id: '17'},
    {tp_desdobramento_id: "18", descricao: "Transferência bancária, Carteira Digital", id: '18'},
    {tp_desdobramento_id: "19", descricao: "Programa de fidelidade, Cashback, Crédito Virtual", id: '19'},
    {tp_desdobramento_id: "90", descricao: "Sem pagamento", id: '90'},
  ],
  tipo_integracao: [
    {tp_integracao_id: "1", descricao: "Pagamento integrado (TEF, Site)", id: '1'},
    {tp_integracao_id: "2", descricao: "Pagamento não integrado (POS)", id: '2'},
  ],
  tipo_bandeira: [
    {tp_bandeira_id: "01", descricao: "Visa", id: '01'},
    {tp_bandeira_id: "02", descricao: "Mastercard", id: '02'},
    {tp_bandeira_id: "03", descricao: "American Express", id: '03'},
    {tp_bandeira_id: "04", descricao: "Sorocred", id: '04'},
    {tp_bandeira_id: "05", descricao: "Diners Club", id: '05'},
    {tp_bandeira_id: "06", descricao: "Elo", id: '06'},
    {tp_bandeira_id: "07", descricao: "Hipercard", id: '07'},
    {tp_bandeira_id: "08", descricao: "Aura", id: '08'},
    {tp_bandeira_id: "09", descricao: "Cabal", id: '09'},
    {tp_bandeira_id: "99", descricao: "Outros", id: '99'}
  ]
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaOperadorasAll: (state) => {
    return state.allLista
  },
  listaOperadorasCartoesAll: (state) => {
    return state.allListaCartao
  },
  listaTipos: (state) => (tipo) => {
    if (tipo === 1) {
      return state.tipo_operadora
    } else if (tipo === 2) {
      return state.tipo_desdobramento
    } else if (tipo === 3) {
      return state.tipo_integracao
    } else if (tipo === 4) {
      return state.tipo_bandeira
    }
  },
  pesquisarTipo: (state) => (tipo, id) => {
    let lista;
    if (tipo === 1) {
      lista = state.tipo_operadora
    } else if (tipo === 2) {
      lista = state.tipo_desdobramento
    } else if (tipo === 3) {
      lista = state.tipo_integracao
    } else if (tipo === 4) {
      lista = state.tipo_bandeira
    }
    let item = lista.filter(lista => lista.id === id);
    return item.length > 0 ? item[0] : ''
  },
  listaOperadorasCartoes: (state) => {
    return state.all
  },
  pegaOperadora: (state) => {
    return state.operadora
  },


};

const actions = {
  searchOperadora({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['formas_pagamento.nome'], '%' + search + '%', 'ilike')
  },

  // async getOperadora({commit}, id) {
  //   let filtro = getBaseSCH('forma_pagamento_id', id, '=');
  //   const list = (await pegaDados(url + '/get', filtro)).data;
  //   EventBus.$emit('GET_OPERADORA_CONCLUIDO', true, list.data[0])
  // },


  async getOperadora({commit},  {id, onSucesso, onErro}) {
    try {
      let filtro = getBaseSCH('forma_pagamento_id', id, '=');
      const list = (await pegaDados(url + '/get', filtro)).data;
      commit('SET_OPERADORA', list.data[0]);
      onSucesso()
    } catch (error) {
      console.log(error);
      onErro()
    }
  },

  async setOeradoraListaCompleta({commit}, {onSucesso}) {
    try {
      const list = (await lista(url + '/lista_completa')).data;
      commit('SET_OPERADORAS_ALL', list);
      onSucesso()
    } catch (e) {
      console.log(e)
    }
  },
  async setOperadoraListaCartao({commit}, {onSucesso}) {
    try {
      const list = (await lista(url + '/lista_cartao')).data;
      commit('SET_OPERADORAS_CARTAO', list);
      onSucesso()
    } catch (e) {
      console.log(e)
    }
  },
  async setAllOperadorasCartoes({commit},{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data;
      commit('SET_OPERADORAS_CARTOES', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },
  async processarOperadoraCartao({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.operadora, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      // this.dispatch('operadora_cartao/setAllOperadorasCartoes');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  setOperadora({commit}, operadora) {
    commit('SET_OPERADORA', operadora)
  },
  processarDesdobramento({commit}, {item, acao}) {
    if (acao === 'gravar') {
      commit('ADICIONA_OPERADORA_DESDOBRAMENTO', item)
    } else if (acao === 'alterar') {
      commit('ALTERA_OPERADORA_DESDOBRAMENTO', item)
    } else if (acao === 'remover') {
      commit('REMOVE_OPERADORA_DESDOBRAMENTO', item)
    }
  },
  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', OperadoraCartaoSCH(perPage))
  },
};

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_OPERADORAS_ALL(state, lista) {
    state.allLista = lista
  },
  SET_OPERADORAS_CARTAO(state, lista) {
    state.allListaCartao = lista
  },

  SET_OPERADORAS_CARTOES(state, operadorasCartoes) {
    state.all = operadorasCartoes
  },
  SET_OPERADORA(state, operadora) {
    state.operadora = operadora
  },

  // COMPOSICAO
  REMOVE_OPERADORA_DESDOBRAMENTO(state, desdobramento) {
    let pos = state.operadora.forma_pagamento_config_operadora_cartao.indexOf(desdobramento);
    state.operadora.forma_pagamento_config_operadora_cartao.splice(pos, 1)
  },
  ALTERA_OPERADORA_DESDOBRAMENTO(state, desdobramento) {
    let c = state.operadora.forma_pagamento_config_operadora_cartao.find(lista => lista.forma_pagamento_operadora_cartao_id === desdobramento.forma_pagamento_operadora_cartao_id);
    let pos = state.operadora.forma_pagamento_config_operadora_cartao.indexOf(c);
    state.operadora.forma_pagamento_config_operadora_cartao.splice(pos, 1, desdobramento)
  },
  ADICIONA_OPERADORA_DESDOBRAMENTO(state, desdobramento) {
    state.operadora.forma_pagamento_config_operadora_cartao.splice(state.operadora.forma_pagamento_config_operadora_cartao.length, 1, desdobramento)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
