/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {PlanoConta} from "../../../models/cadastro/plano_conta";
import {PlanoContaSCH} from "../../../search/PlanoContaSCH"

import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/plano_conta';

const state = {
  all: [],
  listaCompleta: [],
  filtro: new Filtro(),
  plano_conta: new PlanoConta()
};

const getters = {
  listaPlanoContaSemFiltro: (state) => {
    return state.all
  },
  listaPlanoContaLista: (state) => {
    return state.listaCompleta
  },
  pegaPlanoContaDescricao: (state) => (id) => {
    if (id !== null) {
      let item= state.listaCompleta.find(plano_conta => plano_conta.plano_conta_id === id);
      return item!== null || item !== undefined ? item.descricao : '---'
    } else {
      return '---'
    }
  },
  pegaPlanoConta: (state) => (id) => {
    let item= state.listaCompleta.find(plano_conta => plano_conta.plano_conta_id === id);
    return item!== null  || item !== undefined ? item: new PlanoConta()
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
};

const actions = {
  searchPlanoConta({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['conta_contabil', 'descricao', 'dt_conta', 'natureza_conta', 'conta_rfb'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getPlanoConta({commit}, id) {
    let filtro = getBaseSCH('plano_conta_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_PLANO_CONTA', list.data[0]);
    EventBus.$emit('GET_CONCLUIDO', true)

  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', PlanoContaSCH(perPage))
  },

  async setListPlanoConta ({ commit }) {
      const list = (await lista(url + '/lista')).data;
      commit('SET_PLANO_CONTAS_LISTA', list)
  },

  async setListaPlanoConta ({ commit }) {
      const list = (await lista(url + '/lista_planos')).data;
      commit('SET_PLANO_CONTAS_LISTA', list)
  },

  async setAllPlanoConta ({ commit },{onSucesso,onErro}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_PLANO_CONTAS', list);
      // EventBus.$emit('LISTA_PROCESSADA', true)
      onSucesso()
    } catch (error) {
      // EventBus.$emit('LISTA_PROCESSADA', false)
      onErro()
    }
  },
  async processarPlanoConta ({ commit }, { plano_conta, acao,onSucesso,onErro }) {
    try {
      let res = await processar(url, plano_conta, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('plano_conta/setAllPlanoConta');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_PLANO_CONTAS (state, plano_conta) {
    state.all = plano_conta
  },
  SET_PLANO_CONTAS_LISTA (state, plano_conta) {
    state.listaCompleta = plano_conta
  },
  SET_PLANO_CONTA (state, plano_conta) {
    state.plano_conta = plano_conta
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
