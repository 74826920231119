/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Veiculo {
  constructor () {
    this.veiculo_id = uuidV4();
    this.descricao = '';
    this.placa = '';
    this.fabricante = '';
    this.cor = '';
    this.uf_placa = '';
    this.carga_maxima = ''

  }
}
