/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {MovEventoCaixa} from "../../../models/movimentacao/mov_evento_caixa";
import {MovEventoCaixaSCH} from "../../../search/MovEventoCaixaSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/mov_evento_caixa';

const state = {
  allList: [],
  all: [],
  filtro: new Filtro(),
  mov_evento_caixa: new MovEventoCaixa()
};

const getters = {
  pegaFiltro: (state) => {
    return state.filtro
  },
  listaAllMovEventoCaixa: (state) => {
    return state.allList
  },
  listaMovEventoCaixa: (state) => {
    return state.all
  },
  pegaMovEventoCaixa: (state) => {
    return state.mov_evento_caixa
  },
};

const actions = {
  searchMovEventoCaixa({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch([
      'mov_eventos_caixa.descricao',
        'mov_eventos_caixa.tp_evento',
        'mov_eventos_caixa.dt_evento',
        'mov_eventos_caixa.vl_evento',
        'funcionarios.nome',
        'eventos_caixa.descricao'],
      '%'+search+'%',
      'ilike'
    )
  },
  async getMovEventoCaixa({commit}, {id, onSucesso}) {
    let filtro = getBaseSCH('mov_eventos_caixa_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_MOV_EVENTO_CAIXA', list.data[0]);
    onSucesso(list.data[0])
  },

  async getSomatorio({commit}, {id, onSucesso}) {
    const list = (await lista(url + '/somatorio')).data;
    onSucesso(list[0])
  },

  setMovEventoCaixa({commit}, evento) {
    commit('SET_MOV_EVENTO_CAIXA',evento)
  },
  setFiltro2({commit}, perPage) {
      commit('SET_FILTRO', MovEventoCaixaSCH(perPage))
  },
  setFiltro({commit}, perPage) {
    if (sessionStorage.getItem('filtro_lanc') === 'vazio' || sessionStorage.getItem('filtro_lanc') === null ) {
      commit('SET_FILTRO', MovEventoCaixaSCH(perPage))
    } else {
      let filtro = MovEventoCaixaSCH(perPage);
      let aux = JSON.parse(sessionStorage.getItem('filtro_lanc'));
      filtro.filters.filter = aux.filters.filter;
      filtro.filters.search= aux.filters.search;
      commit('SET_FILTRO', filtro)
    }
  },
  async setListaMovEventoCaixa ({ commit }, {onSucesso}) {
    let filtro = MovEventoCaixaSCH('*');
    const list = (await pegaDados(url+'/get', filtro)).data;
    commit('SET_ALL_MOV_EVENTO_CAIXAS', list.data);
    onSucesso()
  },
  async setAllMovEventoCaixa ({ commit }) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_MOV_EVENTO_CAIXAS', list);

      EventBus.$emit('LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('LISTA_PROCESSADA', false)
    }
  },
  async processarMovEventoCaixa ({ commit }, {acao, onSucesso,onErro}) {
    try {
      let res = await processar(url, state.mov_evento_caixa, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('mov_evento_caixa/setAllMovEventoCaixa');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  }
};

const mutations = {
  SET_MOV_EVENTO_CAIXAS (state, mov_evento_caixa) {
    state.all = mov_evento_caixa
  },
  SET_ALL_MOV_EVENTO_CAIXAS (state, mov_evento_caixa) {
    state.allList = mov_evento_caixa
  },
  SET_MOV_EVENTO_CAIXA (state, mov_evento_caixa) {
    state.mov_evento_caixa = mov_evento_caixa
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
