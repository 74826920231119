/* eslint-disable */
import {lista, pegaDados, processar} from '../../../services/GenericAPI'
import {EventBus} from '../../../helpers/event-bus'
import {Frete} from "../../../models/movimentacao/frete"
import {FreteSCH} from "../../../search/FretesSCH"
import {Filtro} from "../../../models/filtro/filtro";
import {getBaseSCH} from "../../../search/BaseSCH";

const url = '/frete';

const state = {
  all: [],
  frete: new Frete(),
  filtro: new Filtro()
};

const getters = {
  listaFreteNotasReferente: (state) => {
    return state.frete.fretes_notas_referentes
  },
  listaFretes: (state) => {
    return state.all
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaFrete: (state) => {
    return state.frete
  },
};

const actions = {
  searchFrete({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['numero', 'serie', 'dt_emissao', 'participantes.nome'], '%' + search + '%', 'ilike')
  },

  async getFrete({commit}, id) {
    let filtro = getBaseSCH('frete_id', id, '=');
    const list = (await pegaDados(url+'/get', filtro)).data;
    EventBus.$emit('GET_FRETE_CONCLUIDO', true, list.data[0])
  },

  async setFreteListaCompleta({commit}) {
    const list = (await lista(url + '/lista_completa')).data;
    commit('SET_FRETES', list);
    EventBus.$emit('FRETE_PROCESSO_CONCLUIDO', true)
  },

  async setAllFretes({commit}) {
    try {
      const list = (await pegaDados(url+'/get', state.filtro)).data;
      commit('SET_FRETES', list);
      EventBus.$emit('FRETE_LISTA_PROCESSADA', true)
    } catch (error) {
      EventBus.$emit('FRETE_LISTA_PROCESSADA', false)
    }
  },
  async processarFrete({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url, state.frete, acao);
      this.dispatch('geral/setMsgSuccess', res.data.message);
      this.dispatch('frete/setAllFretes');
      onSucesso()
    } catch (error) {
      this.dispatch('geral/setObjMsgError', error.response.data);
      onErro()
    }
  },
  setFiltro({commit}, {perPage}) {
    commit('SET_FILTRO', FreteSCH(perPage))
  },

  setFrete({commit, rootState}, frete) {
    commit('SET_FRETE', frete)
  },
  processarExtras({commit}, {item, acao}) {
    if (acao === 'gravar') {
      commit('ADICIONAR_FRETE_NOTAS', item)
    } else if (acao === 'alterar') {
      commit('ALTERAR_FRETE_NOTAS', item)
    } else if (acao === 'remover') {
      commit('REMOVER_FRETE_NOTAS', item)
    }
  },
};

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },

  SET_FRETES(state, fretes) {
    state.all = fretes
  },
  SET_FRETE(state, frete) {
    state.frete = frete
  },

  // NOTAS ITENS
  ALTERAR_FRETE_NOTAS(state, item) {
    let c = state.frete.fretes_notas_referentes.find(lista => lista.frete_id === item.frete_id);
    let pos = state.frete.fretes_notas_referentes.indexOf(c);
    // Remove o anterior
    state.frete.fretes_notas_referentes.splice(pos, 1);
    // Adiciona o novo
    state.frete.fretes_notas_referentes.push(item)
  },
  REMOVER_FRETE_NOTAS(state, item) {
    let pos = state.frete.fretes_notas_referentes.indexOf(item);
    state.frete.fretes_notas_referentes.splice(pos, 1)
  },
  ADICIONAR_FRETE_NOTAS(state, item) {
    state.frete.fretes_notas_referentes.splice(state.frete.fretes_notas_referentes.length, 1, item)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
